import { useEffect, useMemo } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import { useTable, usePagination, useSortBy } from "react-table";
import { isToday } from "../../../utils/Common";
import './NotificationsTable.scss'


export default function NotificationsTable({ columns, data, fetchData, loading, pageCount: controlledPageCount, dataCount, controlledPageIndex: pageIndex, setPageIndex, disableSortBy, manualSortBy, initialSortBy, onChangeSort, disableSortRemove, handleNotificationClick }) {
    const pageSize = 15

    const initialState = initialSortBy ? { sortBy: initialSortBy } : {}

    const {
        prepareRow,
        page,
        pageCount,
        state: { sortBy },
    } = useTable({ columns, data, manualPagination: true, pageCount: controlledPageCount, useControlledState: state => useMemo(() => ({ ...state, pageIndex: pageIndex }), [state]), disableSortBy: disableSortBy, manualSortBy: manualSortBy, disableSortRemove: disableSortRemove, initialState: initialState, }, useSortBy, usePagination)

    const canPreviousPage = (pageCount > 0) && (pageIndex > 0)
    const canNextPage = (pageCount > 0) && (pageIndex < (pageCount - 1))

    const gotoPage = (targetIndex) => {
        if ((targetIndex >= 0) && (targetIndex < pageCount)) { setPageIndex(targetIndex) }
    }
    const nextPage = () => {
        if (canNextPage) { setPageIndex(pageIndex + 1) }
    }
    const previousPage = () => {
        if (canPreviousPage) { setPageIndex(pageIndex - 1) }
    }

    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        onChangeSort && onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);

    const todays_included = page.some(row => isToday(row.original.created_at))
    const older_included = page.some(row => !isToday(row.original.created_at))
    const both_categories_included = todays_included && older_included

    return (
        <>
            <div className="NotificationsTable">
                <div className="table-container mh-100 neutral-600 mx-1">
                    {loading
                        ? <div><Spinner animation="border" variant="secondary" size="sm" className="me-2" />Loading...</div>
                        : <>
                            {todays_included && <div className="notification-category-title figma-p2-semibold neutral-400 py-2 mb-4">Today</div>}
                            {
                                page.filter(row => isToday(row.original.created_at)).map((row, i) => {
                                    prepareRow(row)
                                    return <NotificationRow key={row.original.id} row={row} handleNotificationClick={handleNotificationClick} />
                                })
                            }
                            {both_categories_included && <div className="notification-category-title figma-p2-semibold neutral-400 py-2 my-4">Other notifications</div>}
                            {
                                page.filter(row => !isToday(row.original.created_at)).map((row, i) => {
                                    prepareRow(row)
                                    return <NotificationRow key={row.original.id} row={row} handleNotificationClick={handleNotificationClick} />
                                })
                            }
                        </>
                    }
                </div>
            </div>
            {page.length > 0 &&
                <div className="d-flex justify-content-between align-items-center pt-3 mt-auto">
                    <p className="figma-p1 neutral-900 mb-0">Showing {page.length === 0 ? 0 : ((pageIndex * 15) + 1)}-{(pageIndex * 15) + page.length} of {dataCount} results</p>
                    <Pagination className="mb-0">
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        {(pageIndex > 2) &&
                            <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
                        }
                        {(pageIndex > 3) &&
                            <Pagination.Ellipsis disabled />
                        }
                        {(pageIndex > 1) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item>
                        }
                        {(pageIndex > 0) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item>
                        }
                        <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
                        {(pageIndex < pageCount - 1) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item>
                        }
                        {(pageIndex < pageCount - 2) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item>
                        }
                        {(pageIndex < pageCount - 4) &&
                            <Pagination.Ellipsis disabled />
                        }
                        {(pageIndex < pageCount - 3) &&
                            <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageCount}</Pagination.Item>
                        }
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                    </Pagination>
                </div>
            }
        </>
    );
}

function NotificationRow({ row, handleNotificationClick }) {
    const icon = row.cells[0].render('Cell')
    const title = row.cells[1].render('Cell')
    const text = row.cells[2].render('Cell')
    const opened = row.original.opened
    const date = row.cells[3].render('Cell')
    const deletion = row.cells[4].render('Cell')
    return (
        <div className="d-flex w-100 mb-3 notification-row justify-content-between">
            <div className="d-flex w-100 justify-content-start align-items-center me-2" onClick={() => handleNotificationClick(row.original)}>
                {icon}
                <div className="d-flex flex-column position-relative">
                    {!opened && <div className="unread-dot"></div>}
                    <span className={"mb-1 " + (opened ? "figma-p2" : "figma-p2-semibold")}>{title}</span>
                    <span className={opened ? "figma-p1" : "figma-p1-semibold"}>{text}</span>
                </div>
                <div className={"ms-auto " + (opened ? "figma-caption" : " figma-caption-semibold")}>{date}</div>
            </div>
            {deletion}
        </div>
    )
}
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Button, Form, Image, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, useOutletContext } from 'react-router-dom';
import config from '../../config';
import logo from '../../static/images/logo_animato.gif';
import { getPlayerId, getRandomPWD, setUserSession } from "../../utils/Common";
import { CREDENTIAL_RESET_STEP_THREE_URL } from '../../utils/Endpoints';
import Loader from "../../utils/widgets/Loader/Loader";
import SideTextureTemplate from "../../utils/widgets/SideTextureTemplate/SideTextureTemplate";

export default function PasswordUpdate() {
  let navigate = useNavigate();
  const outletContext = useOutletContext()

  const token1 = outletContext.token1;
  const token2 = outletContext.token2;
  const username = outletContext.username;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const [verErrors, setVerErrors] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const missingUsername = !username || username.length === 0
    const missingToken1 = !token1 || token1.length === 0
    const missingToken2 = !token2 || token2.length === 0
    if (missingUsername || missingToken1 || missingToken2) {
      navigate('/login')
    }
  }, [])

  function cleanGenericErrors() {
    setNonFieldErrors([]);
    setVerErrors([]);
  }

  function cleanAllErrors() {
    setPasswordError(null)
    setConfirmPasswordError(null)
    cleanGenericErrors()
  }

  const handlePasswordBlur = (e) => {
      const errors = []
      if (e.target.value) {
          if (!isNaN(e.target.value)) {
              errors.push('This password is entirely numeric.')
          }
          if (e.target.value.length < 8) {
              errors.push('This password is too short. It must contain at least 8 characters.')
          }
      }
      if (errors.length > 0) {
          setPasswordError(errors)
      }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true)
    cleanAllErrors();
    try {
      var formData = new FormData();
      formData.append("username", username);
      formData.append("password1", password);
      formData.append("password2", confirmPassword);
      formData.append("token1", token1);
      formData.append("token2", token2);
      formData.append("player_id", getPlayerId());
      const response = await axios({
          method: 'post',
          url: CREDENTIAL_RESET_STEP_THREE_URL,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
      })
      navigate("/password-update/completed");
      setUserSession(response.data.token, response.data.expiry, response.data.user)
      if (config.SHOULD_LOG) {console.log('response >>> ', JSON.stringify(response));}
    }
    catch (error) {
      const responseData = error.response?.data
      if (responseData && responseData.error && responseData.error === 'step_3_failed') {
        navigate("/password-update/failed");
      }
      else {
        if (responseData) {
          setPasswordError(responseData.password1);
          setConfirmPasswordError(responseData.password2);
          setNonFieldErrors(responseData.non_field_errors || []);
          setVerErrors(responseData.ver_error || []);
        }
        setLoading(false)
      }
      if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
    }
  }

  function buttonEnabled() {
    const errors = passwordError || confirmPasswordError
    const noEmptyRequiredFields = password.length > 0 && confirmPassword.length > 0
    return noEmptyRequiredFields && nonFieldErrors.length === 0 && verErrors.length === 0 && !errors;
  }

  function randomEnabled() {
    return password.length === 0 && confirmPassword.length === 0
  }

  function createRandomPassword() {
    const rpwd = getRandomPWD()
    setPassword(rpwd)
    setConfirmPassword(rpwd)
    setVisiblePassword(true)
    setVisibleConfirmPassword(true)
    setPasswordError(null)
    setConfirmPasswordError(null)
    cleanGenericErrors()
  }

  return (
    <SideTextureTemplate>
      {loading
        ? <Loader />
        : <><Form onSubmit={handleSubmit} className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
            <div className="d-flex justify-content-center mb-5">
              <Image key={Date.now()} fluid alt="Verima Logo" src={logo} className="verima-logo m-auto" />
            </div>
            <div className="d-block figma-h4-semibold text-center primary-600 mb-4">
              Update your password
            </div>
            <div className="d-block figma-p1 text-center neutral-700 mb-4">
              For security reasons, your password expires after 6 months.<br/>
              Please enter a new password.
            </div>
            <Form.Group className="mb-4">
              <div className="d-flex">
                <Form.Label>New password</Form.Label>
                <>
                  {randomEnabled()
                  ? <div className='ms-auto figma-p1-semibold not-underlined primary-600 cursor-pointer' onClick={() => createRandomPassword()}>Suggest strong password</div>
                  : <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                            <Popover className="">
                                <PopoverBody className="py-1">
                                    Clear password fields<br/>to enable this feature
                                </PopoverBody>
                            </Popover>
                        }
                    >
                      <div className='ms-auto figma-p1-semibold not-underlined neutral-400'>Suggest strong password</div>
                    </OverlayTrigger>
                  }
                </>
              </div>
              <Form.Control
                  size='lg'
                  type={visiblePassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Insert password"
                  value={password}
                  className="d-inline password-input"
                  onChange={(e) => { setPassword(e.target.value); setPasswordError(null); cleanGenericErrors() }}
                  onBlur={(e) => { handlePasswordBlur(e) }}
                  isInvalid={(passwordError) ? true : false}
              />
              {visiblePassword ? <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(true) }><FiEye size="20px" className='password-icon' /></div>}
              <Form.Control.Feedback type="invalid">{passwordError && passwordError.length && passwordError.map(error => <div>{error}</div>)}</Form.Control.Feedback>
              <ul className="ps-3 mt-2 figma-caption neutral-600">
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can't be entirely numeric.</li>
                  <li>Your password can't be too similar to other data entered.</li>
                  <li>Your password can't be a commonly used password.</li>
              </ul>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                  size='lg'
                  type={visibleConfirmPassword ? 'text' : 'password'}
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  className="d-inline password-input"
                  onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError(null); cleanGenericErrors() }}
                  isInvalid={(confirmPasswordError) ? true : false}
              />
              {visibleConfirmPassword ? <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(true) }><FiEye size="20px" className='password-icon' /></div>}
              <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
            </Form.Group>
            <div className="nonfield-errors mt-1 w-100">
              {nonFieldErrors.map((error) => {
                return (error === 'password_mismatch')
                  ? <p key={error}>The two password fields didn't match.</p>
                  : <p key={error}>{error}</p>
                }
              )}
            </div>
            <div className="nonfield-errors mt-1 w-100 mt-3 primary-600">
              {verErrors.map((error) =>
                <p key={error.code}>{error.msg}</p>
              )}
            </div>
            <div className="d-grid my-4">
              <Button variant="primary" size="lg" type="submit" disabled={!buttonEnabled()}>
                  <span className="figma-p2-semibold">Update password</span>
              </Button>
            </div>
            <div className="d-flex justify-content-center align-items-center figma-p1 text-center neutral-700 my-5">
              <Button variant='link' className='p-0 figma-p1 primary-600' onClick={(e) => {navigate('/login')}}>BACK TO LOGIN</Button>
            </div>
          </Form>
          <div></div>
        </>
      }
    </SideTextureTemplate>
  );
}
import React from 'react';
import { Button, Image } from "react-bootstrap";
import { useNavigate, useOutletContext } from 'react-router-dom';
import logo from '../../../static/images/logo_animato.gif';
import witapp_logo from '../../../static/images/powered_by_witapp.svg';
import SideTextureTemplate from '../../../utils/widgets/SideTextureTemplate/SideTextureTemplate';

export default function RegistrationFailed() {
  let navigate = useNavigate();
  const outletContext = useOutletContext()

  return (
    <SideTextureTemplate>
      <div className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
        <div className="d-flex justify-content-center mb-5 pb-4">
          <Image key={Date.now()} fluid alt="Verima Logo" src={logo} className="verima-logo m-auto" />
        </div>
        <div className="d-block figma-h4-semibold text-center primary-600 mb-4">
          Unable to complete the registration
        </div>
        <div className="d-block figma-p3-semibold text-center neutral-700 mb-5">
          Your account creation request is no longer valid.<br/>Please try again.
        </div>
        {!outletContext.skipStepOne &&
          <div className="d-block figma-p2 text-center neutral-700 my-4">
            To restart the registration process, <Button variant='link' className='p-0 primary-600' onClick={() => navigate('/registration/step-one')}>click here</Button>.
          </div>
        }
      </div>
      <div className="d-none d-lg-block col-12 mt-4 mt-lg-5 mb-3 mb-lg-5 text-center align-self-end">
        <a href="https://witapp.it" target="_blank" rel="noreferrer"><Image fluid alt="Powered by Witapp" src={witapp_logo} className="witapp-logo m-auto" /></a>
      </div>
    </SideTextureTemplate>
  );
}
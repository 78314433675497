import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "./components/Login/Login";
import Dashboard from './components/Dashboard/Dashboard'
import CreateCase from './components/CreateCase/CreateCase'
import CaseDetail from './components/CaseDetail/CaseDetail'
import Profile from './components/Profile/Profile'
import CaseList from "./components/CaseList/CaseList";
import Notifications from "./components/Notifications/Notifications";
import Registration from "./components/Registration/Registration";
import RegistrationStepOne from './components/Registration/RegistrationStepOne/RegistrationStepOne'
import RegistrationStepTwo from "./components/Registration/RegistrationStepTwo/RegistrationStepTwo";
import RegistrationStepThree from "./components/Registration/RegistrationStepThree/RegistrationStepThree";
import RegistrationCompleted from "./components/Registration/RegistrationCompleted/RegistrationCompleted";
import RegistrationFailed from './components/Registration/RegistrationFailed/RegistrationFailed'
import CredentialReset from "./components/CredentialReset/CredentialReset";
import CredentialResetStepOne from './components/CredentialReset/CredentialResetStepOne/CredentialResetStepOne'
import CredentialResetStepTwo from "./components/CredentialReset/CredentialResetStepTwo/CredentialResetStepTwo";
import CredentialResetStepThree from "./components/CredentialReset/CredentialResetStepThree/CredentialResetStepThree";
import CredentialResetCompleted from "./components/CredentialReset/CredentialResetCompleted/CredentialResetCompleted";
import CredentialResetFailed from './components/CredentialReset/CredentialResetFailed/CredentialResetFailed'

import OutletPassContext from './utils/routes/OutletPassContext'
import NotFound from "./utils/routes/NotFound";

import UnauthenticatedUsersNotAllowed from './utils/routes/UnauthenticatedUsersNotAllowed';
import UnauthenticatedUsersOnly from "./utils/routes/UnauthenticatedUsersOnly";
import LicenseExpiredNotAllowed from "./utils/routes/LicenseExpiredNotAllowed";
import LicenseExpiredOnly from './utils/routes/LicenseExpiredOnly';
import FreeUsersNotAllowed from './utils/routes/FreeUsersNotAllowed'
import EnterpriseUsersOnly from "./utils/routes/EnterpriseUsersOnly";
import MobileUsersNotAllowed from "./utils/routes/MobileUsersNotAllowed";
import MobileUsersOnly from "./utils/routes/MobileUsersOnly";

import LicenseExpired from "./components/LicenseExpired/LicenseExpired";
import Mobile from './components/Mobile/Mobile'
import PasswordUpdateWrapper from "./components/PasswordUpdate/PasswordUpdateWrapper";
import PasswordUpdate from "./components/PasswordUpdate/PasswordUpdate";
import PasswordUpdateCompleted from "./components/PasswordUpdate/PasswordUpdateCompleted";
import PasswordUpdateFailed from "./components/PasswordUpdate/PasswordUpdateFailed";
import EnterpriseWorkspace from "./components/EnterpriseWorkspace/EnterpriseWorkspace";

export default function AppRoutes() {
  return (
    <Routes>
      <Route exact path='' element={<UnauthenticatedUsersNotAllowed />}>

        <Route exact  path='' element={<MobileUsersNotAllowed />}>
          <Route exact path='' element={<LicenseExpiredNotAllowed />}>
            <Route path='' element={<Dashboard />}>

              <Route exact path='' element={<FreeUsersNotAllowed />}>
                <Route path='' element={<Navigate to="/my-cases" />} />
                <Route path='my-cases' element={<OutletPassContext />}>
                  <Route path='' element={<CaseList type='my_cases' key='my_cases' />} />
                  <Route path=":caseId" element={<CaseDetail type='my_cases' key='my_cases' />} />
                </Route>
                <Route path='create-case' element={<OutletPassContext />} >
                  <Route path='stl' element={<CreateCase case_type='STL' key='STL' />} />
                  <Route path='custom' element={<CreateCase case_type='custom' key='custom' />} />
                </Route>
              </Route>

              <Route path='shared-with-me' element={<OutletPassContext />}>
                <Route path='' element={<CaseList type='shared_with_me' key='shared_with_me' />} />
                <Route path=":caseShareId" element={<CaseDetail type='shared_with_me' key='shared_with_me' />} />
              </Route>
              <Route path='profile' element={<Profile />} />
              <Route path='notifications' element={<Notifications />} />
              <Route exact path='' element={<EnterpriseUsersOnly />}>
                <Route path='enterprise-workspace' element={<EnterpriseWorkspace />} />
              </Route>

            </Route>
          </Route>
        
          <Route exact path='' element={<LicenseExpiredOnly />}>
            <Route path='license-expired' element={<LicenseExpired />} />
          </Route>
        </Route>
        <Route exact  path='' element={<MobileUsersOnly />}>
            <Route path='mobile' element={<Mobile />} />
        </Route>
      </Route>

      <Route exact path='' element={<UnauthenticatedUsersOnly />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />}>
          <Route path="" element={<Navigate to='step-one' />} />        
          <Route path='step-one' element={<RegistrationStepOne />} />      
          <Route path='step-two' element={<RegistrationStepTwo />} />      
          <Route path='step-three' element={<RegistrationStepThree />} />      
          <Route path='completed' element={<RegistrationCompleted />} />      
          <Route path='failed' element={<RegistrationFailed />} />      
        </Route>
        <Route path='credential-reset' element={<CredentialReset />}>
          <Route path="" element={<Navigate to='step-one' />} />        
          <Route path='step-one' element={<CredentialResetStepOne />} />      
          <Route path='step-two' element={<CredentialResetStepTwo />} />      
          <Route path='step-three' element={<CredentialResetStepThree />} />      
          <Route path='completed' element={<CredentialResetCompleted />} />      
          <Route path='failed' element={<CredentialResetFailed />} />      
        </Route>
        <Route path='password-update' element={<PasswordUpdateWrapper />}>
          <Route path="" element={< PasswordUpdate />} />             
          <Route path='completed' element={<PasswordUpdateCompleted />} />      
          <Route path='failed' element={<PasswordUpdateFailed />} />      
        </Route>
      </Route>


      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
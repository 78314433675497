import { useEffect, useMemo } from "react";
import { Pagination, Spinner, Table as ReactTable } from "react-bootstrap";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useTable, usePagination, useSortBy } from "react-table";
import './CustomSegmentationsTable.scss'


export default function CustomSegmentationsTable({ columns, data, fetchData, loading, pageCount: controlledPageCount, dataCount, controlledPageIndex: pageIndex, setPageIndex, disableSortBy, manualSortBy, initialSortBy, onChangeSort, disableSortRemove }) {
    const pageSize = 15

    const initialState = initialSortBy ? { sortBy: initialSortBy } : {}

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { sortBy },
    } = useTable({ columns, data, manualPagination: true, pageCount: controlledPageCount, useControlledState: state => useMemo(() => ({...state, pageIndex: pageIndex}), [state]), disableSortBy: disableSortBy, manualSortBy: manualSortBy, disableSortRemove: disableSortRemove, initialState: initialState,}, useSortBy, usePagination)

    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        onChangeSort && onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);

    return (
        <>
            {/* <pre className="before-table"><code>{JSON.stringify({ pageIndex, pageSize, pageCount, canNextPage, canPreviousPage,},null,2)}</code></pre> */}
            <div className="CustomSegmentationsTable">
                <div className="table-container mh-100">
                    <ReactTable {...getTableProps()} className="mb-0 table-fixed">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <div className="d-flex align-items-center figma-p2-semibold neutral-600">
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                        ? <FiArrowDown size='14px' className="ms-3 primary-600" />
                                                        : <FiArrowUp size='14px' className="ms-3 primary-600" />
                                                        : ''}
                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => <td {...cell.getCellProps()}><span className="figma-p1 neutral-700">{cell.render('Cell')}</span></td>)}
                                    </tr>
                                )
                            })}
                            <tr className="results-row">
                                {loading ? (
                                    <td colSpan="10000"><Spinner animation="border" variant="secondary" size="sm" className="me-2" />Loading...</td>
                                ) : 
                                    page.length === 0 &&
                                        <td colSpan="10000">No records available</td>
                                }
                            </tr>
                        </tbody>
                    </ReactTable>
                </div>
            </div>
        </>
    );
}
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import React from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FiAlertCircle } from 'react-icons/fi';


export default function SaveModal({ handleSave, setShowCaseModal, setShowSaveModal, fetchData, wasEdited }) {

    return (
        <>
            <Modal centered show={true} onHide={() => {setShowSaveModal(false)}}>
                <Modal.Body id="delete-case-modal">
                    <Row className="text-center">
                        <Col md={12} className="d-flex justify-content-center">
                            <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                                <div className="d-block m-auto p-0"><FiAlertCircle className="primary-600" size="20px"/></div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <p className="figma-p1-semibold neutral-900">Save changes</p>
                        </Col>
                        <Col md={12}>
                            <p className="figma-p1 neutral-500">Do you want to save the changes made to the layers before exiting?</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                    <Button variant="outline-primary" className="px-5" onClick={() => {setShowCaseModal(false); setShowSaveModal(false); wasEdited && fetchData()}}>Exit</Button>
                    <Button variant="primary" className="px-5" onClick={() => {handleSave()}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


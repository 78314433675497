import React, { useState } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

export default function PasswordUpdateWrapper(props) {
  const location = useLocation()
  const [token1, setToken1] = useState(location.state?.token1);
  const [token2, setToken2] = useState(location.state?.token2);
  const [username, setUsername] = useState(location.state?.username);
  const outletContext = {
    ...useOutletContext(),
    token1: token1,
    setToken1: setToken1,
    token2: token2,
    setToken2: setToken2,
    username: username,
    setUsername: setUsername,
  };
  return <Outlet context={outletContext} />;
}

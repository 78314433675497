import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { ReactComponent as CreateCustomIcon } from '../../static/icons/create_custom.svg';
import { ReactComponent as CreateSTLIcon } from '../../static/icons/create_stl.svg';
import { getAvailableCustomSegmentations, getUser } from "../../utils/Common";
import { CUSTOMER_URL, ECOMMERCE_CUSTOM_SEGMENTATIONS_URL, ENTERPRISE_WORKSPACE_URL, WEBSITE_PRICING_URL } from "../../utils/Endpoints";
import Loader from "../../utils/widgets/Loader/Loader";
import './Dashboard.scss';

export default function CreateCaseModal({showCreateCaseModal, setShowCreateCaseModal, closeSidebar}) {
    const user = getUser()
    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(false)
    const [license, setLicense] = useState(null)
    const [enterpriseObj, setEnterpriseObj] = useState(null)

    async function fetchData() {
        setLoading(true)
        try {
            const customer_response = await axios({
                method: 'get',
                url: `${CUSTOMER_URL}${user.customer.id}/`,
            })
            const license_response = await axios({
                method: 'get',
                url: customer_response.data.license,
            })
            setLicense(license_response.data)
            if (license_response.data.plan === '3') {
                const enterprise_response = await axios({
                    method: 'get',
                    url: `${ENTERPRISE_WORKSPACE_URL}`,
                })
                setEnterpriseObj(enterprise_response.data)
            }
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])


    let remaining_stl_cases = 0
    let available_stl_cases = false
    let total_custom_segmentations = 0
    let available_custom_segmentations = false
    let is_top_level_user = false
    let is_enterprise = false

    if (license) {
        const stl_monthly_usage = license.montly_usage.reduce((total, usage) => usage.from_stl + total, 0)
        remaining_stl_cases = license.license_thresholds.create_from_stl - stl_monthly_usage
        available_stl_cases = remaining_stl_cases > 0
        
        is_enterprise = license.plan === '3'
        if (is_enterprise) {
            total_custom_segmentations = enterpriseObj?.enterprise_custom_segmentations || 0
        }
        else {
            const custom_segmentations_dict = getAvailableCustomSegmentations(license)
            total_custom_segmentations = Object.values(custom_segmentations_dict).reduce((a, b) => a + b, 0)
        }
        available_custom_segmentations = total_custom_segmentations > 0

        is_top_level_user = parseInt(license.plan) > 1
        
    }

    return (
        <Modal size="lg" centered show={showCreateCaseModal} onHide={() => setShowCreateCaseModal(false)}>
            <Modal.Header className="py-4 border-bottom-0" closeButton>
                <Modal.Title className="figma-h6-semibold neutral-900 text-center w-100 ms-4" >Which case type do you want to create?</Modal.Title>
            </Modal.Header>
            <Modal.Body id="create-case-modal" className="p-5 pt-4">
                {loading
                    ?
                        <div className="h-222">
                            <Loader />
                        </div>
                    :
                        <div className="d-flex">
                            <div className="col me-5 pt-4">
                                <Button className="pt-5 pb-4 w-100 d-flex align-items-center justify-content-center" disabled={!available_stl_cases} onClick={() => {setShowCreateCaseModal(false); closeSidebar(); navigate('/create-case/stl')}}>
                                    <CreateSTLIcon className={"position-absolute custom-top-8 " + (available_stl_cases ? "primary-600" : "neutral-200")} /> 
                                    <span className="figma-h4-semibold">STL case</span>
                                </Button>
                                <div className="d-flex mt-3">
                                    <FiInfo size="16px" className="primary-600" />
                                    <span className="ms-2 figma-caption neutral-500">Reconstruct your clinical case from existing 3D models.</span>
                                </div>
                                {available_stl_cases
                                    ?
                                        <div className="mt-4 figma-p1 neutral-500">You can still process <span className="figma-p1-semibold primary-600">{remaining_stl_cases} cases</span> this month.</div>
                                    :
                                        <>
                                            <div className="mt-4 figma-p1-semibold error-500 w-100 text-center">
                                                <div>You have reached your monthly upload limit.</div>
                                                {!is_top_level_user &&
                                                    <div className="mt-2"><a href={WEBSITE_PRICING_URL} target="_blank" rel="noreferrer" className="figma-p1-medium-underlined primary-600">UPGRADE YOUR LICENSE</a></div>
                                                }
                                            </div>
                                        </>
                                }
                            </div>
                            <div className="col ps-5 pt-4 custom-case-col">
                                <Button className="pt-5 pb-4 w-100 d-flex align-items-center justify-content-center" disabled={!available_custom_segmentations} onClick={() => {setShowCreateCaseModal(false); closeSidebar(); navigate('/create-case/custom')}}>
                                    <CreateCustomIcon className={"position-absolute custom-top-8 " + (available_custom_segmentations ? "primary-600" : "neutral-200")} />
                                    <span className="figma-h4-semibold">Custom case</span>
                                </Button>
                                <div className="d-flex mt-3">
                                    <FiInfo size="16px" className="primary-600" />
                                    <div className="ms-2 figma-caption neutral-500">Upload your clinical case and request a custom segmentation.</div>
                                </div>
                                {available_custom_segmentations
                                    ?
                                        <div className="mt-4 figma-p1 neutral-500">You can still request <span className="figma-p1-semibold primary-600">{total_custom_segmentations} custom cases</span>.</div>
                                    :
                                        <>
                                            <div className="mt-4 figma-p1-semibold error-500 w-100 text-center">
                                                <div className="mt-4 figma-p1 neutral-500">You have <span className="figma-p1-semibold error-500">{total_custom_segmentations} custom cases</span> requests available.</div>
                                                {is_enterprise
                                                ?
                                                    <div className="mt-2 text-start">
                                                        <span className="figma-caption">
                                                            {`${CUSTOMER_URL}${user.customer.id}/` === enterpriseObj.enterprise_manager
                                                                ?
                                                                'To add additional Custom Segmentations, please contact our business support.'
                                                                :
                                                                'To add additional Custom Segmentations, please contact the account manager.'
                                                            }
                                                        </span>
                                                    </div>
                                                :
                                                    <div className="mt-2">
                                                        <a href={ECOMMERCE_CUSTOM_SEGMENTATIONS_URL} target="_blank" rel="noreferrer" className="figma-p1-medium-underlined primary-600">BUY CUSTOM SEGMENTATIONS</a>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                }
                                
                            </div>
                        </div>
                }
            </Modal.Body>
        </Modal>
    );
}


import { useEffect, useMemo } from "react";
import { Pagination, Spinner, Table as ReactTable } from "react-bootstrap";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useTable, usePagination, useSortBy } from "react-table";
import './Table.scss'


export default function Table({ columns, data, fetchData, loading, pageCount: controlledPageCount, dataCount, controlledPageIndex: pageIndex, setPageIndex, disableSortBy, manualSortBy, initialSortBy, onChangeSort, disableSortRemove }) {
    const pageSize = 15

    const initialState = initialSortBy ? { sortBy: initialSortBy } : {}

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        state: { sortBy },
    } = useTable({ columns, data, manualPagination: true, pageCount: controlledPageCount, useControlledState: state => useMemo(() => ({...state, pageIndex: pageIndex}), [state]), disableSortBy: disableSortBy, manualSortBy: manualSortBy, disableSortRemove: disableSortRemove, initialState: initialState,}, useSortBy, usePagination)

    const canPreviousPage = (pageCount > 0) && (pageIndex > 0)
    const canNextPage = (pageCount > 0) && (pageIndex < (pageCount - 1))

    const gotoPage = (targetIndex) => {
        if ((targetIndex >= 0) && (targetIndex < pageCount)) { setPageIndex(targetIndex)}
    }
    const nextPage = () => {
        if (canNextPage) { setPageIndex(pageIndex + 1)}
    }
    const previousPage = () => {
        if (canPreviousPage) { setPageIndex(pageIndex - 1)}
    }

    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        onChangeSort && onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);
    return (
        <>
            {/* <pre className="before-table"><code>{JSON.stringify({ pageIndex, pageSize, pageCount, canNextPage, canPreviousPage,},null,2)}</code></pre> */}
            <div className="Table">
                <div className="table-container mh-100">
                    <ReactTable {...getTableProps()} className="mb-0 table-fixed">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <div className={`figma-p2-semibold neutral-600 ${(column.Header === 'DETAILS' || column.Header === 'DELETE') ? "text-center" : "d-flex align-items-center"}`}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                        ? <FiArrowDown size='14px' className="ms-3 primary-600" />
                                                        : <FiArrowUp size='14px' className="ms-3 primary-600" />
                                                        : ''}
                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => <td {...cell.getCellProps()}><span className={`figma-p1 neutral-700 ${(cell.column.Header === 'DETAILS' || cell.column.Header === 'DELETE') && "d-flex justify-content-center"}`}>{cell.render('Cell')}</span></td>)}
                                    </tr>
                                )
                            })}
                            <tr className="results-row">
                                {loading ? (
                                    <td colSpan="10000"><Spinner animation="border" variant="secondary" size="sm" className="me-2" />Loading...</td>
                                ) : 
                                    page.length === 0 &&
                                        <td colSpan="10000">No records available</td>
                                }
                            </tr>
                        </tbody>
                    </ReactTable>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 mt-auto paginator">
                <p className="figma-p1 neutral-900 mb-0">Showing {page.length === 0 ? 0 : ((pageIndex * 15) + 1)}-{(pageIndex * 15) + page.length} of {dataCount} results</p>
                <Pagination className="mb-0">
                    <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                    {(pageIndex > 2) &&
                        <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
                    }
                    {(pageIndex > 3) &&
                        <Pagination.Ellipsis disabled />
                    }
                    {(pageIndex > 1) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item>
                    }
                    {(pageIndex > 0) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item>
                    }
                    <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
                    {(pageIndex < pageCount - 1) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item>
                    }
                    {(pageIndex < pageCount - 2) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item>
                    }
                    {(pageIndex < pageCount - 4) &&
                        <Pagination.Ellipsis disabled />
                    }
                    {(pageIndex < pageCount - 3) &&
                        <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageCount}</Pagination.Item>
                    }
                    <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                </Pagination>
            </div>
        </>
    );
}
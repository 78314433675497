import axios from "axios";
import React, { useState } from 'react';
import { Button, Form, Image } from "react-bootstrap";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import logo from '../../static/images/logo_animato.gif';
import witapp_logo from '../../static/images/powered_by_witapp.svg';
import { getPlayerId, getQuerystring, setUserSession } from '../../utils/Common';
import { ENTERPRISE_TEAM_MEMBERS_URL, ENTERPRISE_WORKSPACE_URL, LOGIN_URL } from '../../utils/Endpoints';
import SideTextureTemplate from "../../utils/widgets/SideTextureTemplate/SideTextureTemplate";
import Loader from "../../utils/widgets/Loader/Loader";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const [verErrors, setVerErrors] = useState([]);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  function cleanErrors(preserve_username_error=false, preserve_password_error=false) {
    if (!preserve_username_error) {
      setUsernameError(null);
    }
    if (!preserve_password_error) {
      setPasswordError(null);
    }
    setNonFieldErrors([]);
    setVerErrors([]);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    cleanErrors();
    await axios.post(LOGIN_URL, {
      username: username,
      password: password,
      player_id: getPlayerId(),
    }).then(async (response) => {
      setUserSession(response.data.token, response.data.expiry, response.data.user)
      if (response.data.user.plan === '3') {
        try {
          const enterprise_response = await axios({
              method: 'get',
              url: `${ENTERPRISE_WORKSPACE_URL}`,
          })
          try {
              const enterprise_member_response = await axios({
                  method: 'get',
                  url: `${ENTERPRISE_TEAM_MEMBERS_URL}${response.data.user.customer.id}/`,
              })
              if (enterprise_member_response.data.is_enterprise_manager && !enterprise_response.data.enterprise_name) {
                navigate("/enterprise-workspace");
              }
              else {
                navigate(getQuerystring("redirect") || "/my-cases");
              }
          }
          catch (error) {
              if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
          }
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }
      }
      else {
        navigate(getQuerystring("redirect") || "/my-cases");
      }
      if (config.SHOULD_LOG) {console.log('response >>> ', JSON.stringify(response));}
    }).catch(error => {
      if (error.response?.status === 451) {
        navigate('/password-update', {state: {username: error.response.data.username, token1: error.response.data.token1, token2: error.response.data.token2}})
      }
      else {
        if (error.response?.data) {
          setUsernameError(error.response.data.username);
          setPasswordError(error.response.data.password);
          setNonFieldErrors(error.response.data.non_field_errors || []);
          setVerErrors(error.response.data.ver_error || []);
        }
        setLoading(false);
      }
     if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
    })
  }

  function buttonEnabled() {
    return username.length > 0 && password.length > 0 && nonFieldErrors.length === 0 && verErrors.length === 0 && !usernameError && !passwordError;
  }

  return (
    <SideTextureTemplate>

      {loading
      ?
      <Loader />
      :
      <Form onSubmit={handleSubmit} className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
        <div className="d-flex justify-content-center mb-5">
          <Image key={Date.now()} fluid alt="Verima Logo" src={logo} className="verima-logo m-auto" />
        </div>
        <div className="d-block d-lg-none figma-h4-semibold text-center primary-600 my-4">
          Enter your personal area
        </div>
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            size="lg"
            autoFocus
            type="text"
            placeholder="Insert your username"
            value={username}
            onChange={(e) => { setUsername(e.target.value); cleanErrors(false, true) }}
            isInvalid={(usernameError) ? true : false}
          />
          <Form.Control.Feedback type="invalid">{usernameError}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            size="lg"
            type={visiblePassword ? "text" : "password" }
            placeholder="Insert your password"
            value={password}
            className="d-inline password-input"
            onChange={(e) => { setPassword(e.target.value); cleanErrors(true, false) }}
            isInvalid={(passwordError) ? true : false}
          />
          {visiblePassword ? <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(false) }><FiEyeOff size="24px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(true) }><FiEye size="24px" className='password-icon' /></div>}
          <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
        </Form.Group>
        <div className="nonfield-errors mt-1 w-100">
          {nonFieldErrors.map((error) =>
            <p key={error}>{error}</p>
          )}
        </div>
        <div className="nonfield-errors mt-1 w-100">
          {verErrors.map((error) =>
            <p key={error.code}>{error.msg}</p>
          )}
        </div>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <Button variant='link' className='link-primary figma-p1 p-0' onClick={() => navigate('/credential-reset/step-one')}>Forgot credentials</Button>
        </div>
        <div className="d-grid mb-5">
          <Button variant="primary" size="lg" type="submit" disabled={!buttonEnabled()}>
            Login
          </Button>
        </div>
        <div className="d-grid mt-5 mb-2 text-center">
          <span className='figma-p1 neutral-600'>Don't have login credentials? <Button variant='link' className='p-0 figma-p2-semibold primary-600' onClick={() => navigate('/registration/step-one')}>SIGN UP</Button></span>
        </div>
      </Form>
      }
      <div className="d-none d-lg-block col-12 mt-4 mt-lg-5 mb-3 mb-lg-5 text-center align-self-end">
        <a href="https://witapp.it" target="_blank" rel="noreferrer"><Image fluid alt="Powered by Witapp" src={witapp_logo} className="witapp-logo m-auto" /></a>
      </div>
    </SideTextureTemplate>
  );
}
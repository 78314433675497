import { useState } from 'react';
import { FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { DATA_SHEETS_ENG, DATA_SHEETS_ITA, USER_MANUAL_ENG, USER_MANUAL_ITA } from '../../../utils/Endpoints';
import '../Profile.scss';
import { ReactComponent as ItalyFlag } from '../../../static/images/flags/it.svg';
import { ReactComponent as UKFlag } from '../../../static/images/flags/gb.svg';

export default function HelpCenter() {
    const [activeFaqID, setActiveFaqID] = useState(null)

    const FAQ = [
        {
            id: 1,
            title: 'What is Verima Desk?',
            body: 'Verima Desk is the cloud platform for viewing, managing and sharing clinical cases associated with your Verima account.',
            category: 'generic',
        },
        {
            id: 2,
            title: 'Do I necessarily have to purchase a license to access the platform?',
            body: 'No, Verima Desk can also be accessed without purchasing a license. However, you must register and create a Verima user account so you can log in. Register here.',
            category: 'generic',
        },
        {
            id: 3,
            title: 'Is Verima Desk a secure software?',
            body: 'Yes. In the event that you experience a computer vulnerability of your device or the entire facility hosting the Verima application, please contact Customer Support immediately. After analyzing the situation, our trained staff will report the most accurate procedures to protect or restore your Verima user data.',
            category: 'generic',
        },
        {
            id: 4,
            title: 'What are Verima Desk main functionalities?',
            body: <>
                <span>There are many, we point out the main ones:</span>
                <br /><br />
                <ul>
                    <li>Creating cases from pre-existing 3D models (STL extension);</li>
                    <li>Sending CT or MRI generated files (DICOM) to the cloud platform for on-demand tissue segmentation;</li>
                    <li>Management of a cloud archive containing cases of interest;</li>
                    <li>Management of favorite cases;</li>
                    <li>Management of case sharing with other colleagues or with own patients;</li>
                    <li>Module for 3D visualization of cases imported to the platform.</li>
                </ul>
            </>,
            category: 'generic',
        },
        {
            id: 5,
            title: 'Is Verima Desk synchronized with the mobile app and mixed reality viewer?',
            body: <span>Yes, all your cases are always available on all applications in the Verima suite. You use Verima Desk to upload and generate cases.<br /><br />After that, they will be viewable, editable, and shareable from all devices connected to your account via mobile app (Verima Viewer AR) and mixed reality viewer app (Verima Viewer MR).</span>,
            category: 'generic',
        },
        {
            id: 6,
            title: 'Is my patients data protected during and after using the software?',
            body: <span>Yes. The system is not intended to store patient data and/or personally identifiable information.<br /><br />You will be responsible for de-identifying all personal data in the files you upload as a user to the Verima applications.</span>,
            category: 'generic',
        },
        {
            id: 7,
            title: 'How do I log in?',
            body: 'To access, you must log in with your credentials at the following link. If you have not yet registered, you can create a Verima user account at the following link.',
            category: 'technical',
        },
        {
            id: 8,
            title: 'I lost my password, what should I do?',
            body: 'If you forgot or lost your credentials, click "Forgot credentials," enter your associated email address, and you will automatically receive instructions to reset or change your password.',
            category: 'technical',
        },
        {
            id: 9,
            title: 'Can I use the software with other devices or apps I already own that are not associated with the Verima suite?',
            body: 'We do not recommend it. The combined use of the Verima suite software and apps with other unauthorized tools may compromise the safety and/or effectiveness of the devices or the accuracy of 3D reproduction.',
            category: 'technical',
        },
        {
            id: 10,
            title: 'Can I use Verima Desk with my default browser?',
            body: <>
                <span>Yes, Verima Desk supports all recent versions of modern browsers i.e. Edge, Firefox, Chrome, Safari.<br /><br />Verima Desk also works properly on older versions of the browsers mentioned above as long as they support ES6 and WEBGL2. For more details regarding the compatibility of your browser you can consult the following sites:</span>
                <br /><br />
                <ul>
                    <li><a href='https://caniuse.com/es6' target='_blank' rel='noreferrer'>https://caniuse.com/es6 </a></li>
                    <li><a href='https://get.webgl.org' target='_blank' rel='noreferrer'>https://get.webgl.org</a></li>
                </ul>
            </>,
            category: 'technical',
        },
        {
            id: 11,
            title: 'Do I have to enter my credentials each time I log in?',
            body: 'Yes. We care about protecting your data and your patients\' data, we want to make sure that it\'s always you using the tool or someone under your authorized supervision.',
            category: 'technical',
        },
        {
            id: 12,
            title: 'Can I share my cases with other doctors or my patients?',
            body: 'Yes, with the purchase of the Basic license or higher you will have the case sharing feature active. This feature allows you to share your clinical case with your colleagues, patients and/or students anywhere in the world.',
            category: 'technical',
        },
        {
            id: 13,
            title: 'Can I share a case with a person who is not a Verima user or is not among my contacts?',
            body: 'Yes, just specify when sharing the case the recipient email address. They will receive a notification email through which they can register for free to Verima and thus view the shared case.',
            category: 'technical',
        },
        {
            id: 14,
            title: 'Do the images I upload to obtain a customized 3D model need any special requirements?',
            body: <>
            Yes, the quality of the obtained 3D model is strongly influenced by the quality of the images that are processed by the software.
            <br /><br />
            To obtain a good quality 3D model, we recommend that the images used meet the following parameters:
            <br /><br />
            <ul>
                <li>slice thickness not exceeding 2 mm;</li>
                <li>axial images, not reconstructed;</li>
                <li>reconstruction algorithm for medium-resolution bone tissue;</li>
                <li>in case of CT, we recommend using images from low-dose radiation CT.</li>
            </ul>
            To visualize nonbony tissue, images from examinations with contrast medium should be processed. If metal implants are present, an artifact reduction filter must be applied before sending DICOMs.
            </>,
            category: 'technical',
        },
    ]

    return (
        <div className="HelpCenter h-100 d-flex flex-column" >
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Resources</span>
                <div className='d-flex flex-wrap'>
                    <div className='me-5'>
                        <span className="figma-p2-semibold primary-600">Verima Desk User Manual</span>
                        <div className='d-flex mt-2 flex-wrap mb-2'>
                            <a href={USER_MANUAL_ENG} target='_blank' rel='noreferrer' className="btn btn-outline-primary figma-p1-medium primary-600 d-flex align-items-center help-center-link mb-2 me-2"><UKFlag className='help-center-flag me-2' />User Manual<FiExternalLink size={'16px'} className='ms-auto' /></a>
                            <a href={USER_MANUAL_ITA} target='_blank' rel='noreferrer' className="btn btn-outline-primary figma-p1-medium primary-600 d-flex align-items-center help-center-link mb-2"><ItalyFlag className='help-center-flag me-2' />Manuale d'uso<FiExternalLink size={'16px'} className='ms-auto' /></a>
                        </div>
                    </div>
                    <div className='me-5'>
                        <span className="figma-p2-semibold primary-600">Verima Desk Data Sheet</span>
                        <div className='d-flex mt-2 flex-wrap mb-2'>
                            <a href={DATA_SHEETS_ENG} target='_blank' rel='noreferrer' className="btn btn-outline-primary figma-p1-medium primary-600 d-flex align-items-center help-center-link mb-2 me-2"><UKFlag className='help-center-flag me-2' />Data Sheet<FiExternalLink size={'16px'} className='ms-auto' /></a>
                            <a href={DATA_SHEETS_ITA} target='_blank' rel='noreferrer' className="btn btn-outline-primary figma-p1-medium primary-600 d-flex align-items-center help-center-link mb-2"><ItalyFlag className='help-center-flag me-2' />Scheda tecnica<FiExternalLink size={'16px'} className='ms-auto' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='horizontal-line my-4'></div>
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Generic FAQ</span>
                {FAQ.filter(faq => faq.category === 'generic').map((faq, index) => <FAQCard key={index} faq={faq} activeFaqID={activeFaqID} setActiveFaqID={setActiveFaqID} />)}
            </div>
            <div className='horizontal-line my-4'></div>
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Technical FAQ</span>
                {FAQ.filter(faq => faq.category === 'technical').map((faq, index) => <FAQCard key={index} faq={faq} activeFaqID={activeFaqID} setActiveFaqID={setActiveFaqID} />)}
            </div>
        </div>
    );
}

function FAQCard({faq, activeFaqID, setActiveFaqID}) {
    const isActiveFaq = activeFaqID === faq.id
    return (
        <div className={'mt-2 faq-container neutral-600 p-3 py-2 figma-p1' + (isActiveFaq ? ' active' : '')}>
            <div className='d-flex justify-content-between align-items-center figma-p1-semibold cursor-pointer' onClick={() => {isActiveFaq ? setActiveFaqID(null) : setActiveFaqID(faq.id)}}><span>{faq.title}</span> <div className='p-1 cursor-pointer'><FiChevronDown size={'24px'} id='faq-chevron' className='primary-600' /></div></div>
            <div className='mt-1 neutral-700 faq-body'><div className='mb-1'>{faq.body}</div></div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { getUser, removeUserSession, setUserSession } from "../../utils/Common";
import Sidebar from "../Sidebar/Sidebar";
import CreateCaseModal from "./CreateCaseModal";
import './Dashboard.scss';
import {CUSTOMER_URL, ENTERPRISE_TEAM_MEMBERS_URL, ENTERPRISE_WORKSPACE_URL, LOGOUT_URL, NOTIFICATION_URL} from '../../utils/Endpoints'
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import { FiBriefcase } from "react-icons/fi";
import { ToastContainer } from "react-toastify";
import Loader from "../../utils/widgets/Loader/Loader";

export default function Dashboard() {
  let navigate = useNavigate();

  const [user, setUser] = useState(getUser())

  const [unreadNotifications, setUnreadNotifications] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showCreateCaseModal, setShowCreateCaseModal] = useState(false);

  const casesSubmenuActive = ['/my-cases', '/shared-with-me'].includes(window.location.pathname)
  const [casesSubmenuOpen, setCasesSubmenuOpen] = useState((casesSubmenuActive && sidebarOpen) ? true : false);

  const [enterpriseObj, setEnterpriseObj] = useState(null)
  const [enterpriseMemberObj, setEnterpriseMemberObj] = useState(null)

  const [loading, setLoading] = useState(true)

  function handleLogout() {
    axios({
      method: 'post',
      url: `${LOGOUT_URL}`,
    })
    setUser(null)
    removeUserSession();
    navigate('/login');
  }

  function updateUser(user) {
    setUserSession(false, false, user);
    setUser(user)
  }

  function openSidebar() {
    setSidebarOpen(true)
    setCasesSubmenuOpen(casesSubmenuActive ? true : false)
  }

  function closeSidebar() {
    setSidebarOpen(false)
    setCasesSubmenuOpen(false)
  }

  function toggleSidebarOpen() {
    sidebarOpen ? closeSidebar() : openSidebar()
  }

  function toggleCasesSubmenuOpen() {
    const actual_state = casesSubmenuOpen
    setCasesSubmenuOpen(!actual_state)
    if (!casesSubmenuOpen) {
      setSidebarOpen(true)
    }
  }

  async function checkUpdateUnreadNotifications() {
    try {
      const notification_response = await axios({
        method: "get",
        url: `${NOTIFICATION_URL}?opened=false`,
      });
      setUnreadNotifications(notification_response.data.count > 0);
    }
    catch (error) {
      if (config.SHOULD_LOG) {
        console.error("response error >>> ", error);
      }
    }
  }

  async function checkEnterprise() {
    if (user.plan === '3') {
      try {
        const enterprise_response = await axios({
            method: 'get',
            url: `${ENTERPRISE_WORKSPACE_URL}`,
        })
        setEnterpriseObj(enterprise_response.data)
        try {
            const enterprise_member_response = await axios({
                method: 'get',
                url: `${ENTERPRISE_TEAM_MEMBERS_URL}${user.customer.id}/`,
            })
            setEnterpriseMemberObj(enterprise_member_response.data)
            if (enterprise_member_response.data.is_enterprise_manager && !enterprise_response.data.enterprise_name) {
              toast.dismiss()
              toast.warning(
                <div>You have not configured your workspace yet. You can do it from the <b onClick={() => {navigate('/enterprise-workspace'); toast.dismiss()}} className="underlined">Enterprise Workspace</b> section.</div>,
                {
                  icon: <FiBriefcase size={'20px'} />,
                  containerId: "enterprise",
                }
              )
            }
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }
      }
      catch (error) {
          if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
      }
    }
  }
  
  async function fetchAndUpdateUser() {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${CUSTOMER_URL}${getUser().customer.id}/`,
      });
      let customerData = response.data;
      const license_response = await axios({
        method: "get",
        url: customerData.license,
      });
      updateUser({
        ...user,
        customer: customerData,
        plan: license_response.data.plan,
        plan_label: license_response.data.plan_label,
        license_start_date: license_response.data.start_date,
        license_end_date: license_response.data.end_date,
      });
    } catch (error) {
      if (config.SHOULD_LOG) {
        console.error("response error >>> ", error);
      }
    }
    setLoading(false)
  }


  async function callInitialFunctions() {
    await fetchAndUpdateUser()
    await checkEnterprise()
  }

  useEffect(() => {
    callInitialFunctions()
  }, []);

  useEffect(() => {
    const UNREAD_NOTIFICATIONS_CHECK_TIME_INTERVAL = 5 * 60 * 1000
    checkUpdateUnreadNotifications();
    const interval = setInterval(() => {
      checkUpdateUnreadNotifications();
    }, UNREAD_NOTIFICATIONS_CHECK_TIME_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const context = {
    'sidebarOpen': sidebarOpen,
    'openSidebar': openSidebar,
    'closeSidebar': closeSidebar,
    'user': user,
    'updateUser': updateUser,
    'enterpriseObj': enterpriseObj,
    'checkUpdateUnreadNotifications': checkUpdateUnreadNotifications
  }

  return (
    <div className="Dashboard container-fluid h-100 p-0">
      {loading
      ?
        <Loader />
      :
        <>
          <Row className={"m-0 gx-0 h-100 flex-nowrap"}>
            <Sidebar isOpen={sidebarOpen} openCreateCaseModal={() => setShowCreateCaseModal(true)} casesSubmenuActive={casesSubmenuActive} casesSubmenuOpen={casesSubmenuOpen} toggleSidebarOpen={toggleSidebarOpen} toggleCasesSubmenuOpen={toggleCasesSubmenuOpen} user={user} handleLogout={handleLogout} unreadNotifications={unreadNotifications} enterpriseObj={enterpriseObj} enterpriseMemberObj={enterpriseMemberObj} />
            <Col className="h-100 box">
              <div className="w-100 header hidden"></div>
              <div className="content"><Outlet context={context} /></div>
              {showCreateCaseModal && <CreateCaseModal showCreateCaseModal={showCreateCaseModal} setShowCreateCaseModal={setShowCreateCaseModal} closeSidebar={closeSidebar} />}
            </Col>
          </Row>
          <ToastContainer
            enableMultiContainer
            containerId={'enterprise'}
            position="top-center"
            autoClose={false}
            limit={1}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            className="enterprise-toast-container"
          />
        </>
      }
      </div >
  );
}
import { useEffect, useMemo } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import { useTable, usePagination, useSortBy } from "react-table";
import './TeamMembersTable.scss'


export default function TeamMembersTable({ columns, data, fetchData, loading, pageCount: controlledPageCount, dataCount, controlledPageIndex: pageIndex, setPageIndex, disableSortBy, manualSortBy, initialSortBy, onChangeSort, disableSortRemove }) {
    const pageSize = 15

    const initialState = initialSortBy ? { sortBy: initialSortBy } : {}

    const {
        prepareRow,
        page,
        pageCount,
        state: { sortBy },
    } = useTable({ columns, data, manualPagination: true, pageCount: controlledPageCount, useControlledState: state => useMemo(() => ({ ...state, pageIndex: pageIndex }), [state]), disableSortBy: disableSortBy, manualSortBy: manualSortBy, disableSortRemove: disableSortRemove, initialState: initialState, }, useSortBy, usePagination)

    const canPreviousPage = (pageCount > 0) && (pageIndex > 0)
    const canNextPage = (pageCount > 0) && (pageIndex < (pageCount - 1))

    const gotoPage = (targetIndex) => {
        if ((targetIndex >= 0) && (targetIndex < pageCount)) { setPageIndex(targetIndex) }
    }
    const nextPage = () => {
        if (canNextPage) { setPageIndex(pageIndex + 1) }
    }
    const previousPage = () => {
        if (canPreviousPage) { setPageIndex(pageIndex - 1) }
    }

    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        onChangeSort && onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);

    return (
        <>
            <div className="TeamMembersTable">
                <div className="table-container mh-100 neutral-600 mx-1">
                    {loading
                        ? <div><Spinner animation="border" variant="secondary" size="sm" className="me-2" />Loading...</div>
                        : <>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return <TeamMemberRow key={row.original.id} row={row} />
                            })}
                        </>
                    }
                </div>
            </div>
            {page.length > 0 &&
                <div className="d-flex justify-content-between align-items-center pt-3 mt-auto paginator">
                    <p className="figma-p1 neutral-900 mb-0">Showing {page.length === 0 ? 0 : ((pageIndex * 15) + 1)}-{(pageIndex * 15) + page.length} of {dataCount} results</p>
                    <Pagination className="mb-0">
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        {(pageIndex > 2) &&
                            <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
                        }
                        {(pageIndex > 3) &&
                            <Pagination.Ellipsis disabled />
                        }
                        {(pageIndex > 1) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item>
                        }
                        {(pageIndex > 0) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item>
                        }
                        <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
                        {(pageIndex < pageCount - 1) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item>
                        }
                        {(pageIndex < pageCount - 2) &&
                            <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item>
                        }
                        {(pageIndex < pageCount - 4) &&
                            <Pagination.Ellipsis disabled />
                        }
                        {(pageIndex < pageCount - 3) &&
                            <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageCount}</Pagination.Item>
                        }
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                    </Pagination>
                </div>
            }
        </>
    );
}

function TeamMemberRow({ row }) {
    const icon = row.cells[0].render('Cell')
    const registration_pending = row.cells[1].value
    const first_name = row.cells[2].render('Cell')
    const last_name = row.cells[3].render('Cell')
    const email = row.cells[4].render('Cell')
    const has_tool = row.cells[5].render('Cell')
    const tool_ai_modules = row.cells[6].render('Cell')
    return (
        <div className="d-flex w-100 mb-4 team-member-row justify-content-between">
            <div className={"d-flex w-100 justify-content-start align-items-center me-2 " + (registration_pending ? "neutral-400" : "neutral-600")}>
                <div className="d-flex flex-column position-relative">
                    <div className="mb-1 d-flex align-items-center figma-p2-semibold">
                        {icon}
                        <div className="ms-2">{registration_pending ? email : <>{first_name} {last_name}</>}</div>
                        <div>{has_tool}</div>
                        <div>{tool_ai_modules}</div>
                    </div>
                    <div className="figma-p1 neutral-400 subtext">{registration_pending ? 'Pending account' : email}</div>
                </div>
            </div>
        </div>
    )
}
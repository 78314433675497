import { m as macro } from "@kitware/vtk.js/macros2.js";
import Constants from "@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget/Constants.js";
import vtkOrientationMarkerWidget from "@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget";

// ----------------------------------------------------------------------------
// vtkOrientationMarkerWidget
// ----------------------------------------------------------------------------

function customOrientationMarkerWidget(publicAPI, model) {
  model.classHierarchy.push("customOrientationMarkerWidget");

  publicAPI.computeViewport = () => {
    const parentRen =
      model.parentRenderer || model._interactor.getCurrentRenderer();
    const xMin = parentRen.getViewport()[0];
    const yMax = parentRen.getViewport()[3];
    const view = model._interactor.getView();
    const canvasSize = view.getSize();
    const [viewXSize, viewYSize] = view.getViewportSize(parentRen);
    const minViewSize = Math.min(viewXSize, viewYSize);
    let pixelSize = model.viewportSize * minViewSize;
    // clamp pixel size
    pixelSize = Math.max(
      Math.min(model.minPixelSize, minViewSize),
      Math.min(model.maxPixelSize, pixelSize)
    );
    const xFrac = pixelSize / canvasSize[0];
    const yFrac = pixelSize / canvasSize[1];

    return [
      xMin + xFrac * 0.5,
      yMax / 2 - yFrac,
      xMin + xFrac * 2 + xFrac * 0.5,
      yMax / 2 + yFrac,
    ];
  };
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model) {
  vtkOrientationMarkerWidget.extend(publicAPI, model);
  customOrientationMarkerWidget(publicAPI, model);
}

// ----------------------------------------------------------------------------

const newInstance = macro.newInstance(extend, "customOrientationMarkerWidget");

// ----------------------------------------------------------------------------

var customOrientationMarkerWidget$1 = {
  newInstance,
  extend,
  ...Constants,
};

// export default Object.assign({ newInstance, extend }, Constants);
export { customOrientationMarkerWidget$1 as default, newInstance };

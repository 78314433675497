import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import './CaseList.scss';


export default function DeleteDemoCaseModal({showDeleteDemoCaseModal, setShowDeleteDemoCaseModal, deleteDemoCaseId, deleteSharedCase}) {
    return (
        <Modal centered show={showDeleteDemoCaseModal} onHide={() => setShowDeleteDemoCaseModal(false)}>
            <Modal.Body id="delete-case-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiTrash2 className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">Delete demo case</p>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1 neutral-500">Are you sure you want to remove this demo case?</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="outline-primary" className="px-5" onClick={(e) => {setShowDeleteDemoCaseModal(false); e.target.blur()}}>Cancel</Button>
                <Button variant="primary" className="px-5" onClick={() => {deleteSharedCase(deleteDemoCaseId, true)}}>Remove</Button>
            </Modal.Footer>
        </Modal>
    );
}


import { Image, Nav } from "react-bootstrap";
import { FiBell, FiBriefcase, FiChevronLeft, FiGrid, FiLogOut, FiPlusCircle } from 'react-icons/fi';
import { LinkContainer } from "react-router-bootstrap";
// import {FiUsers, FiDollarSign, FiVideo, FiExternalLink, FiHelpCircle } from 'react-icons/fi';
import { useState } from "react";
import full_logo from '../../static/images/full_logo_verima.svg';
import small_logo from '../../static/images/small_logo_verima.svg';
import { DEFAULT_IMAGE_FALLBACK } from "../../utils/Common";
import LogoutModal from "./LogoutModal";
import './Sidebar.scss';


export default function Sidebar({ isOpen, openCreateCaseModal, casesSubmenuActive, casesSubmenuOpen, toggleSidebarOpen, toggleCasesSubmenuOpen, user, handleLogout, unreadNotifications, enterpriseObj, enterpriseMemberObj }) {
    const freeUser = user.plan === '0'
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    return (
        <Nav activeKey={window.location.pathname} className={"d-flex Sidebar" + (isOpen ? "" : " collapsed")}>
            <div className="d-flex flex-column w-100 h-100">
                <div className="px-auto mx-2 text-center verima-logo-container">
                    <Image fluid alt="Verima Logo" src={small_logo} className="verima-logo small" />
                    <Image fluid alt="Verima Logo" src={full_logo} className="verima-logo full" />
                </div>
                <div className="sidebar-arrow-container">
                    <div className="sidebar-arrow d-flex align-items-center">
                        <LinkContainer to="#" onClick={() => toggleSidebarOpen()}>
                            <Nav.Link className="d-block p-0 m-auto">
                                <FiChevronLeft size="24px" className={"primary-600 sidebar-chevron " + (isOpen ? "" : "closed")} />
                            </Nav.Link>
                        </LinkContainer>
                    </div>
                </div>
                {!freeUser &&
                    <div className="px-4 creation-button-container">
                        <ul className="nav nav-pills flex-column">
                            <li className="nav-item btn btn-primary p-0 creation-button">
                                <LinkContainer to="#" onClick={() => openCreateCaseModal(true)}>
                                    <Nav.Link className="text-white d-flex justify-content-center nav-link-button btn">
                                        <FiPlusCircle className={"align-self-center"} />
                                        <span>Create new case</span>
                                    </Nav.Link>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                }
                <div className="mb-auto px-4">
                    <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                            <LinkContainer to="#" onClick={() => toggleCasesSubmenuOpen()}>
                                <Nav.Link className={"link-dark d-flex submenu-navlink " + (casesSubmenuActive ? 'active' : '')}>
                                    <FiGrid className="me-2" />
                                    <span>Cases</span>
                                    <div className="ms-auto">
                                        <FiChevronLeft size="16px" className={"submenu-chevron primary-600 " + (casesSubmenuOpen ? "" : "closed")} />
                                    </div>
                                </Nav.Link>
                            </LinkContainer>
                            <div className={"submenu ms-4 ps-3 " + (freeUser ? "hide-my-cases" : "") + " " + (casesSubmenuOpen ? "" : "closed")} >
                                <ul className="nav nav-pills flex-column subnav">
                                    {!freeUser &&
                                        <li className="nav-item">
                                            <LinkContainer to="/my-cases">
                                                <Nav.Link className="link-dark d-flex">
                                                    <span>My Cases</span>
                                                </Nav.Link>
                                            </LinkContainer>
                                        </li>
                                    }
                                    <li className="nav-item">
                                        <LinkContainer to="/shared-with-me">
                                            <Nav.Link className="link-dark d-flex">
                                                <span>Shared With Me</span>
                                            </Nav.Link>
                                        </LinkContainer>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <LinkContainer to="/notifications">
                                <Nav.Link className="link-dark d-flex">
                                    <div className="position-relative">
                                        <FiBell className="me-2" />
                                        {unreadNotifications &&  <div className="unread-notifications-dot position-absolute"></div>}
                                    </div>
                                    <span>Notifications</span>
                                </Nav.Link>
                            </LinkContainer>
                        </li>
                        {/* <li className="nav-item">
                            <LinkContainer to="/contacts">
                                <Nav.Link className="link-dark d-flex">
                                    <FiUsers className="me-2" />
                                    <span>Contacts</span>
                                </Nav.Link>
                            </LinkContainer>
                        </li> */}
                        {/* <li className="nav-item">
                            <div>
                                <a className="nav-link link-dark d-flex align-items-center" target="_blank" rel="noreferrer" href="https://www.google.com">
                                    <FiVideo className="me-2" />
                                    <span>Verima Streaming <FiExternalLink className="ms-1 external-link" /></span>
                                </a>
                            </div>
                        </li> */}
                    </ul>
                </div>
                {enterpriseMemberObj?.is_enterprise_manager &&
                    <div className="px-4">
                        <ul className="nav nav-pills flex-column">
                            <li className="nav-item">
                                <LinkContainer to="/enterprise-workspace">
                                    <Nav.Link className="link-dark d-flex">
                                        <FiBriefcase className="me-2" />
                                        <span>Enterprise workspace</span>
                                    </Nav.Link>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                }
                <div className="px-4 divider-container mt-3">
                    <hr className="primary-600" />
                </div>
                <div className="px-4">
                    <ul className="nav nav-pills flex-column mb-4">
                        {/* <li className="nav-item">
                            <LinkContainer to="/help">
                                <Nav.Link className="link-dark d-flex">
                                    <FiHelpCircle className="me-2" />
                                    <span>Help</span>
                                </Nav.Link>
                            </LinkContainer>
                        </li> */}
                        <li className="nav-item">
                            <Nav.Link className="link-dark d-flex" onClick={() => { setShowLogoutModal(true)}}>
                                <FiLogOut className="me-2" />
                                <span>Logout</span>
                            </Nav.Link>
                            <LogoutModal showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} handleLogout={handleLogout} />
                        </li>
                    </ul>
                </div>
                <div className={"profile-container px-4 py-3" + (isOpen ? " overflow-hidden" : "")}>
                    <LinkContainer to="/profile">
                        <Nav.Link className="link-dark profile-link">
                            <div className={"d-flex " + (isOpen ? "justify-content-start overflow-hidden" : "justify-content-center")}>
                                <div className="align-self-center profile-picture-container">
                                    <Image className="profile-picture" fluid roundedCircle src={user.customer.profile_img_medium} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src=DEFAULT_IMAGE_FALLBACK}} />
                                </div>
                                <div className="profile-text-container overflow-hidden">
                                    <div className="figma-p2-semibold shades-0 text-truncate">
                                        {user.customer.first_name} {user.customer.last_name}
                                    </div>
                                    <div className="figma-p1 neutral-500 text-truncate">{user.customer.email}</div>
                                </div>
                            </div>
                        </Nav.Link>
                    </LinkContainer>
                </div>
            </div>
        </Nav>
    );
}
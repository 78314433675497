import { ProgressBar } from 'react-bootstrap';
import { FiArchive, FiBox, FiShare2 } from 'react-icons/fi';
import { ReactComponent as CustomSegmentationsIcon } from '../../../static/icons/custom_segmentations.svg';
import { getAvailableCustomSegmentations, getDateString } from '../../../utils/Common';
import { ECOMMERCE_CUSTOM_SEGMENTATIONS_URL, WEBSITE_PRICING_URL } from '../../../utils/Endpoints';
import '../Profile.scss';

export default function AccountSummary({ license, enterprise_workspace, enterprise_member_detail }) {

    const expiration = new Date(license.end_date)

    const is_free = license.plan === '0'
    const is_enterprise = license.plan === '3'

    const stl_monthly_usage = license.montly_usage.reduce((total, usage) => usage.from_stl + total, 0)
    const remaining_stl_cases = license.license_thresholds.create_from_stl - stl_monthly_usage
    let stl_cases_status = 'basic'
    if (remaining_stl_cases <= 0) {
        stl_cases_status = 'full'
    }
    else if ((license.license_thresholds.create_from_stl < 100) ? (remaining_stl_cases <= 3) : (remaining_stl_cases <= 5)) {
        stl_cases_status = 'warning'
    }

    const remaining_storage = license.license_thresholds.archive_size - license.archive_size
    let remaining_storage_progressbar_variant = 'primary'
    if (remaining_storage <= 0) {
        remaining_storage_progressbar_variant = 'danger'
    }
    else if ((license.license_thresholds.archive_size < 100) ? (remaining_storage <= 3) : (remaining_storage <= 5)) {
        remaining_storage_progressbar_variant = 'warning'
    }

    const remaining_sharing = license.license_thresholds.share - license.active_share
    let remaining_sharing_progressbar_variant = 'primary'
    if (remaining_sharing <= 0) {
        remaining_sharing_progressbar_variant = 'danger'
    }
    else if ((license.license_thresholds.share < 100) ? (remaining_sharing <= 3) : (remaining_sharing <= 5)) {
        remaining_sharing_progressbar_variant = 'warning'
    }

    const custom_segmentations_dict = is_enterprise ? {} : getAvailableCustomSegmentations(license)
    const custom_segmentation_types = is_enterprise ? 0 : Object.entries(custom_segmentations_dict).length
    const total_custom_segmentations = is_enterprise ? enterprise_workspace.enterprise_custom_segmentations : Object.values(custom_segmentations_dict).reduce((a, b) => a + b, 0)
    const available_custom_segmentations = total_custom_segmentations > 0

    return (
        <div className="AccountSummary h-100 d-flex flex-column" >
            <div className="topContainer w-100 pb-4 d-flex justify-content-start neutral-600">
                <div className="d-flex flex-column">
                    <span className="figma-p2 mb-2">License:</span>
                    <div className={(!is_free && !is_enterprise) ? 'd-flex align-items-center' : ''}>
                        <span className="figma-p2-semibold primary-600">{license.plan_label}</span>
                        {is_free
                            ? <div className="d-flex flex-column justify-space-between align-items-start mt-2">
                                <span className="figma-caption mb-4">With this license you can only view the cases that have been shared with you. We recommend to buy a license to take full advantage of the Verima services.</span>
                                <a href={WEBSITE_PRICING_URL} target="_blank" rel="noreferrer" className="btn btn-primary btn-sm px-4 py-2 figma-caption-semibold">Upgrade license</a>
                            </div>
                            : <span className="figma-caption ms-3">Expires <span className="figma-caption-semibold neutral-600">{getDateString(expiration)}</span></span>
                        }
                        {is_enterprise
                            && <div className="enterprise-card d-flex flex-column justify-space-between align-items-start mt-2">
                                <span className="figma-caption">You are {enterprise_member_detail.is_enterprise_manager ? 'the manager' : 'a member'} of the <b>{enterprise_workspace.enterprise_name}</b> workspace{enterprise_member_detail.has_tool ? ' and you have user permissions for Tool usage' : ''}.</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {!is_free &&
                <div className="bottomContainer d-flex flex-column neutral-600 flex-grow-1">
                    <>
                        <div className="d-flex w-100 align-items-center mt-4">
                            <FiBox size="24px" className="me-3" />
                            <span className="figma-p2">STL cases</span>
                            <div className={"ms-auto figma-p2-semibold stl-counter " + stl_cases_status}>
                                <span className="figma-p3-semibold">{stl_monthly_usage}</span>/{license.license_thresholds.create_from_stl}
                            </div>
                        </div>
                        <div className="d-flex w-100 align-items-center">
                            <span className="figma-caption mt-2 subtext">
                                {stl_cases_status === 'full'
                                    ? <span className="error-600">You can no longer process STL cases this month.<br />Upgrade your license to get more of these each month.</span>
                                    : <>You can still process {remaining_stl_cases} STL cases this month.</>
                                }
                            </span>
                        </div>
                    </>
                    <>
                        <div className="d-flex w-100 align-items-center mt-5">
                            <FiArchive size="24px" className="me-3" />
                            <span className="figma-p2 me-3">Storage</span>
                            <span className={"figma-p2-semibold progress-number" + (remaining_storage_progressbar_variant === "danger" ? " error-600" : "") }>{license.archive_size}</span>
                            <ProgressBar variant={remaining_storage_progressbar_variant} now={license.archive_size} max={license.license_thresholds.archive_size} className="flex-grow-1 mx-2" />
                            <span className="figma-p2-semibold progress-number">{license.license_thresholds.archive_size}</span>
                        </div>
                        <div className="d-flex w-100 align-items-center">
                            <span className="figma-caption mt-2 subtext">
                                {remaining_storage_progressbar_variant === 'danger'
                                    ? <span className="error-600">Your cloud cannot contain any other cases.<br />Upgrade your license or delete cases to get space.</span>
                                    : <>Your cloud may contain {remaining_storage} more cases.</>
                                }
                            </span>
                        </div>
                    </>
                    <>
                        <div className="d-flex w-100 align-items-center mt-5">
                            <FiShare2 size="24px" className="me-3" />
                            <span className="figma-p2 me-3">Sharing</span>
                            <span className={"figma-p2-semibold progress-number" + (remaining_sharing_progressbar_variant === "danger" ? " error-600" : "") }>{license.active_share}</span>
                            <ProgressBar variant={remaining_sharing_progressbar_variant} now={license.active_share} max={license.license_thresholds.share} className="flex-grow-1 mx-2" />
                            <span className="figma-p2-semibold progress-number">{license.license_thresholds.share}</span>
                        </div>
                        <div className="d-flex w-100 align-items-center">
                            <span className="figma-caption mt-2 subtext">
                                {remaining_sharing_progressbar_variant === 'danger'
                                    ? <span className="error-600">You cannot share any other cases.<br />Upgrade your license or stop shares to get space.</span>
                                    : <>You may share {remaining_sharing} more cases.</>
                                }
                            </span>
                        </div>
                    </>
                    <>
                        <div className="d-flex w-100 align-items-center mt-5">
                            <CustomSegmentationsIcon size="24px" className="me-3 neutral-600" />
                            <span className="figma-p2">{available_custom_segmentations ? "Available Custom Segmentations" : "No Custom Segmentations available"}</span>
                            {available_custom_segmentations
                                ? <span className="ms-auto figma-p3-semibold primary-600">{total_custom_segmentations}</span>
                                : <>{!is_enterprise && <a href={ECOMMERCE_CUSTOM_SEGMENTATIONS_URL} target="_blank" rel="noreferrer" className="btn btn-outline-primary figma-caption-semibold btn-sm ms-auto px-3 py-2">Buy Custom Segmentation</a>}</>
                            }
                        </div>
                        {is_enterprise &&
                            <div className="w-100 figma-caption mt-2 subtext">
                                Custom Segmentations can be used by all team members.
                            </div>
                        }
                        {custom_segmentation_types > 0 &&
                            <div className="w-100 figma-caption mt-2 subtext">
                                {
                                    custom_segmentation_types > 1
                                    ? (
                                        Object.entries(custom_segmentations_dict).map(([key, value], index) => 
                                            <div key={index} className='mb-1 d-flex align-items-center'>
                                                <div className="available-custom-segmentation-key">{key}</div>
                                                <div className='figma-caption-semibold'>{value}</div>
                                            </div>
                                        )
                                    )
                                    : <>Clinical area: <span className='figma-caption-semibold'>{Object.keys(custom_segmentations_dict)[0]}</span></>
                                }
                            </div>
                        }
                    </>
                </div>
            }
        </div>
    );
}
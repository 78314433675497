const local_conf = {
    ENV_NAME: 'local',
    SHOULD_LOG: true,
    apiGateway: {
        BASE_URL: "http://0.0.0.0:8666"
    },
    ECOMMERCE_URL: "http://localhost:8668",
    GTM_TAG: null
};

const staging_conf = {
    ENV_NAME: 'staging',
    SHOULD_LOG: true,
    apiGateway: {
        BASE_URL: "https://api-staging.verima.it"
    },
    ECOMMERCE_URL: "https://ecommerce-staging.verima.it",
    GTM_TAG: null
};

const production_conf = {
    ENV_NAME: 'production',
    SHOULD_LOG: false,
    apiGateway: {
        BASE_URL: "https://api.verima.it"
    },
    ECOMMERCE_URL: "https://ecommerce.verima.it",
    GTM_TAG: 'GTM-WQW5HMB'
};

let env_conf;
switch (process.env.REACT_APP_STAGE) {
    case 'production':
        env_conf = production_conf
        break
    case 'staging':
        debugger
        env_conf = staging_conf
        break
    case 'local':
        env_conf = local_conf
        break
    default:
        env_conf = {}
        break
}

const config = {
    // Add common config values here, i.e.:
    // MAX_ATTACHMENT_SIZE: 5000000,
    ...env_conf,
    REACT_APP_NAME: process.env.REACT_APP_NAME,
    REACT_APP_VERSION: process.env.REACT_APP_VERSION
}

export default config;
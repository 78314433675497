import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";


export default function CustomSegmentationsModal({showModal, setShowModal, modalTitle, modalDescription}) {
    return (
        <Modal size={'md'} centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body id="delete-case-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiAlertCircle className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">{modalTitle}</p>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1 neutral-500">{modalDescription}</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="primary" className="w-100" onClick={(e) => {setShowModal(false); e.target.blur()}}>Continue</Button>
            </Modal.Footer>
        </Modal>
    );
}


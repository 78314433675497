import { useState, useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import './LicenseExpired.scss'
import axios from "axios";
import { COOKIE_POLICY_URL, ECOMMERCE_PAYMENT_METHOD, LOGOUT_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, WEBSITE_PRICING_URL } from "../../utils/Endpoints";
import { useNavigate } from "react-router-dom";
import { getLicenseRemainingDays, getUser, isLicenseExpired, removeUserSession, setUserSession } from "../../utils/Common";
import logo from '../../static/images/logo_verima_negativo.svg';
import DowngradeModal from "../../utils/widgets/DowngradeModal/DowngradeModal";

export default function LicenseExpired(props) {
    let navigate = useNavigate();

    let [user, setUser] = useState(getUser())
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);    

    const remaining_days = 11 + getLicenseRemainingDays(user.license_end_date)
    const remaining_days_text = remaining_days === 1 ? 'TOMORROW' : `IN ${remaining_days} DAYS`

    const updateLicenseData = async () => {
        const license_response = await axios({
            method: 'get',
            url: user.customer.license
        })
        const updatedUser = {
            ...user,
            plan: license_response.data.plan,
            plan_label: license_response.data.plan_label,
            license_start_date: license_response.data.start_date,
            license_end_date: license_response.data.end_date,
        }
        setUserSession(false, false, updatedUser);
        setUser(updatedUser)
        if (!isLicenseExpired()) {
            navigate('/')
        }
    }

    function handleLogout() {
        axios({
            method: 'post',
            url: `${LOGOUT_URL}`,
        })
        removeUserSession();
        navigate('/login');
    }

    useEffect(() => {
        updateLicenseData()
    }, [])

    const downgradeToFree = async () => {
        await axios({
            method: 'post',
            url: user.customer.license + 'downgrade_to_free/'
        })
        updateLicenseData()
        setShowDowngradeModal(false)
    }

    return (
        <Container fluid className="LicenseExpired shades-0 text-center w-100 h-100 d-flex justify-content-center align-items-center overflow-auto">
            <div className="elements-container d-flex flex-column justify-content-start align-items-center">
                <Image alt="Verima Logo" src={logo} className="verima-logo my-5" />
                <div className="figma-h2-semibold mb-4">
                    {user.customer.first_name}<br />your license has expired!
                </div>
                <div className="figma-h5-bold mb-4">
                    <div className="mb-2">{remaining_days_text}</div><div>your license will be converted to free.</div>
                </div>
                <div className="figma-p3 mb-5 pb-2">
                    By converting your license to Free, you will lose access to your cases without the possibility to recover them.
                </div>
                <Button variant="light" size="lg" className="w-100 mb-4" href={ECOMMERCE_PAYMENT_METHOD}>
                    Renew {user.plan_label} license
                </Button>
                <Button variant="outline-light" size="lg" className="w-100 mb-4" onClick={() => { setShowDowngradeModal(true)}}>
                    Switch to Free
                </Button>
                <div className="d-flex w-100 justify-content-between">
                    <a className='figma-p2-semibold link-light mb-4' href={WEBSITE_PRICING_URL}>
                        COMPARE PLANS
                    </a>
                    <Button variant="link" className='figma-p2-semibold link-light mb-4' onClick={() => {handleLogout()}}>
                        LOGOUT
                    </Button>
                </div>
                <div className="figma-p1 pb-4">You can still consult our <a className="link-light" target='_blank' rel='noreferrer' href={PRIVACY_POLICY_URL}>privacy</a> and <a className="link-light" target='_blank' rel='noreferrer' href={COOKIE_POLICY_URL}>cookie policy</a>, as well as our <a className="link-light" target='_blank' rel='noreferrer' href={TERMS_OF_SERVICE_URL}>terms of service</a>.</div>
            </div>
            <DowngradeModal showDowngradeModal={showDowngradeModal} setShowDowngradeModal={setShowDowngradeModal} downgradeToFree={downgradeToFree} />
        </Container>
    );
}
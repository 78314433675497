import { useState, useMemo, useCallback } from "react";
import { Badge, Button, Container, Image, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import './CaseList.scss'
import axios from "axios";
import { FiEye, FiTrash2 } from 'react-icons/fi';
import config from '../../config'
import { CASE_URL, SHARED_WITH_ME_URL, CUSTOMER_URL } from "../../utils/Endpoints";
import no_preview from '../../static/images/no_preview.svg';
import { ReactComponent as EmptyFolder } from '../../static/images/empty_folder.svg';
import DeleteCaseModal from "./DeleteCaseModal";
import DeleteSharedCaseModal from "./DeleteSharedCaseModal";
import { useNavigate, useOutletContext } from "react-router-dom";
import { addToast, capitalizeFirstLetter, getDateTimeString, getSelectedPreviewUrl, getUser } from "../../utils/Common";
import Table from "../../utils/widgets/Table/Table";
import MoreInfoModal from "./MoreInfoModal";
import DeleteDemoCaseModal from "./DeleteDemoCaseModal";

export default function CaseList(props) {
    const outletContext = useOutletContext()
    const user = getUser()
    const navigate = useNavigate()
    const [emptyDiv, setEmptyDiv] = useState(false)
    const [noCases, setNoCases] = useState(false)
    const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
    
    const columns = useMemo(() => {
        let tmp_columns = [
            {
                Header: 'PREVIEW',
                accessor: 'thumbnail_preview',
                Cell: cell =>
                    <OverlayTrigger
                        placement='right'
                        overlay={
                            <Popover id={`tooltip-${cell.row.original.id}`}>
                                <PopoverBody>
                                    {cell.row.original.full_preview === no_preview
                                        ? <span>You have to open the case at least once from on a device to preview it.</span>
                                        : (<Image fluid src={cell.row.original.full_preview} id={`tooltip-preview-${cell.row.original.id}`} className="preview-image m-auto"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                var image = document.getElementById(`tooltip-preview-${cell.row.original.id}`);
                                                var span = document.createElement('span');
                                                span.setAttribute('id', image.getAttribute('id'));
                                                span.innerHTML = "You have to open the case at least once from on a device to preview it.";
                                                image.parentNode.replaceChild(span, image);
                                            }}
                                        />)
                                    }
                                </PopoverBody>
                            </Popover>
                        }
                    >
                        <Image fluid src={cell.row.original.thumbnail_preview} className="preview-image m-auto" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = no_preview }} />
                    </OverlayTrigger>
            },
            {
                Header: 'CASE NAME',
                accessor: 'case_name',
                Cell: ({ cell }) => {
                    const new_case = cell.row.original.case_opened === false
                    const is_demo = cell.row.original.is_demo
                    const is_received = cell.row.original.received_badge
                    const status = cell.row.original.status
                    {
                        let bg;
                        let text_color;
                        let text;
                        if(is_demo){
                            bg = 'primary'
                            text_color = 'secondary'
                            text = 'DEMO'
                        } else if(new_case){
                            bg = 'secondary'
                            text_color = 'primary'
                            text = 'NEW'
                        } else if(is_received){
                            bg = 'primary-100'
                            text_color = 'primary'
                            text = 'RECEIVED'
                        } else {
                            if(status == 'PENDING'){
                                bg = 'warning'
                                text_color = 'white'
                                text = status
                            } else if(status == 'FAILURE'){
                                bg = 'danger'
                                text_color = 'white'
                                text = status
                            } else if(status == 'INCOMPLETE'){
                                bg = 'neutral-200'
                                text_color = 'neutral-400'
                                text = status
                            }
                        }
                        return(
                            <span className='d-flex align-items-center'>
                                <span>{cell.row.original.case_name}</span>
                                <Badge pill className='ms-3 figma-caption-semibold px-3 py-1' bg={bg} text={text_color}>{text}</Badge>
                            </span>
                        )
                    }
                }
            },
            {
                Header: 'CREATED AT',
                accessor: 'created_at',
            },
            {
                Header: 'OWNER',
                accessor: 'owner',
            },
            {
                Header: 'DETAILS',
                accessor: '',
                Cell: cell =>
                    <Button
                        type="button"
                        size="sm"
                        disabled={(cell.row.original.status === 'SUCCESS') ? false : true}
                        className="d-flex justify-content-center align-items-center figma-p1-semibold"
                        onClick={() => {
                            if (props.type === 'my_cases') {
                                navigate(`${cell.row.original.id}`)
                            }
                            else if (props.type === 'shared_with_me') {
                                navigate(`${cell.row.original.caseshare_id}`)
                            }
                            outletContext.closeSidebar()
                        }}
                    >
                        <FiEye className="me-2" /> View details
                    </Button>
            },
            {
                Header: 'DELETE',
                accessor: '',
                Cell: cell => {
                    if (props.type === 'my_cases') {
                        return (
                            <Button type="button" variant="outline-primary" className="delete-case-button p-0" onClick={(e) => { setShowDeleteCaseModal(true); setDeleteCaseId(cell.row.original.id); setDeleteCaseName(cell.row.original.case_name); e.target.blur() }}>
                                <div className="d-block p-0 m-auto">
                                    <FiTrash2 size="24px" />
                                </div>
                            </Button >
                        )
                    }
                    else if (props.type === 'shared_with_me') {
                        if (cell.row.original.is_demo) {
                            return (
                                <Button type="button" variant="outline-primary" className="delete-case-button p-0" onClick={(e) => { setShowDeleteDemoCaseModal(true); setDeleteDemoCaseId(cell.row.original.caseshare_id); e.target.blur() }}>
                                    <div className="d-block p-0 m-auto">
                                        <FiTrash2 size="24px" />
                                    </div>
                                </Button >
                            )
                        }
                        else {
                            return (
                                <Button type="button" variant="outline-primary" className="delete-case-button p-0" onClick={(e) => { setShowDeleteSharedCaseModal(true); setDeleteSharedCaseId(cell.row.original.caseshare_id); setDeleteSharedCaseName(cell.row.original.case_name); e.target.blur() }}>
                                    <div className="d-block p-0 m-auto">
                                        <FiTrash2 size="24px" />
                                    </div>
                                </Button >
                            )
                        }
                    }
                }
            },
        ]
        switch (props.type) {
            case 'my_cases':
                tmp_columns = tmp_columns.filter((col) => col.accessor !== 'owner')
                break;
            case 'shared_with_me':
                tmp_columns = tmp_columns.filter((col) => col.accessor !== 'status')
                break;
            default:
            //pass
        }
        return tmp_columns
    }, [props.type])

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)

    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);
    const [deleteCaseId, setDeleteCaseId] = useState(null);
    const [deleteCaseName, setDeleteCaseName] = useState(null);

    const [showDeleteSharedCaseModal, setShowDeleteSharedCaseModal] = useState(false);
    const [deleteSharedCaseId, setDeleteSharedCaseId] = useState(null);
    const [deleteSharedCaseName, setDeleteSharedCaseName] = useState(null);

    const [showDeleteDemoCaseModal, setShowDeleteDemoCaseModal] = useState(false);
    const [deleteDemoCaseId, setDeleteDemoCaseId] = useState(null);

    const fetchData = useCallback(async ({ pageSize, pageIndex }) => {
        setLoading(true)
        setData([])
        let fetch_url = ''
        switch (props.type) {
            case 'my_cases':
                fetch_url = CASE_URL
                break
            case 'shared_with_me':
                fetch_url = SHARED_WITH_ME_URL
                break
            default:
                fetch_url = ''
        }
        if (fetch_url) {
            const requestConfig = {
                method: 'get',
                url: fetch_url,
                params: {
                    page: pageIndex + 1
                }
            }
            try {
                const response = await axios(requestConfig)
                let cases = response.data.results
                if (props.type === 'shared_with_me') {
                    let caseShareList = response.data.results
                    const caseShareRequests = caseShareList.map((caseShare) => {
                        const caseRequestConfig = {
                            method: 'get',
                            url: caseShare.shared_case,
                        }
                        return axios(caseRequestConfig)
                    })
                    try {
                        const responseList = await Promise.all(caseShareRequests)
                        cases = responseList.map((response, idx) => {
                            return {
                                ...response.data,
                                caseshare_id: caseShareList[idx].id,
                                case_opened: caseShareList[idx].case_opened,
                                is_demo: caseShareList[idx].is_demo,
                            }
                        })
                    }
                    catch (error) {
                        if (config.SHOULD_LOG) { console.error('responseList error >>> ', error); }
                    }
                }
                let casesData = await Promise.all(cases.map(async (caseObj) => {
                    let owner = ''
                    if (props.type === 'shared_with_me' && caseObj.customer) {
                        const ownerRequestConfig = {
                            method: 'get',
                            url: `${CUSTOMER_URL}${caseObj.customer}/`
                        }
                        try {
                            const ownerResponse = await axios(ownerRequestConfig)
                            owner = ownerResponse.data.username.startsWith('__') ? <span className="neutral-400 fst-italic">Unavailable</span> : `${ownerResponse.data.first_name} ${ownerResponse.data.last_name}`
                            return {
                                id: caseObj.id,
                                full_preview: getSelectedPreviewUrl(caseObj, false),
                                thumbnail_preview: getSelectedPreviewUrl(caseObj, true),
                                case_name: caseObj.verinfo ? caseObj.verinfo.case_name : caseObj.id,
                                created_at: getDateTimeString(new Date(caseObj.created_at)),
                                status: caseObj.task_status === 'ERROR: result not found' ? 'INCOMPLETE' : caseObj.task_status,
                                owner: owner,
                                caseshare_id: caseObj.caseshare_id,
                                case_opened: caseObj.case_opened,
                                is_demo: caseObj.is_demo,
                                received_badge: caseObj.received_badge
                            }
                        }
                        catch (error) {
                            if (config.SHOULD_LOG) { console.error('ownerResponse error >>> ', error); }
                        }
                    }
                    return {
                        id: caseObj.id,
                        full_preview: getSelectedPreviewUrl(caseObj, false),
                        thumbnail_preview: getSelectedPreviewUrl(caseObj, true),
                        case_name: caseObj.verinfo ? caseObj.verinfo.case_name : caseObj.id,
                        created_at: getDateTimeString(new Date(caseObj.created_at)),
                        status:  caseObj.task_status === 'ERROR: result not found' ? 'INCOMPLETE' : caseObj.task_status,
                        owner: owner,
                        caseshare_id: null,
                        case_opened: null,
                        is_demo: null,
                        received_badge: caseObj.received_badge
                    }
                }))
                const empty_list = casesData.length === 0
                setEmptyDiv(empty_list)
                if (empty_list) {
                    let check_response;
                    switch (props.type) {
                        case 'my_cases':
                            check_response = await axios({
                                method: 'get',
                                url: SHARED_WITH_ME_URL,
                            })
                            break
                        case 'shared_with_me':
                            check_response = await axios({
                                method: 'get',
                                url: CASE_URL,
                            })
                            break
                        default:
                            check_response = ''
                    }
                    if (check_response.data.results.length === 0) {
                        setNoCases(true)
                    }
                }
                setData(casesData)
                setPageCount(Math.ceil(response.data.count / pageSize))
                setDataCount(response.data.count)
            }
            catch (error) {
                if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                setData([])
            }
            setLoading(false)
        }
    }, [props.type])

    const deleteSharedCase = async (caseShareId, is_demo=false) => {
        setLoading(true)
        setData([])
        let case_label
        if (is_demo) {
            setShowDeleteDemoCaseModal(false)
            case_label = 'demo case'
        }
        else {
            setShowDeleteSharedCaseModal(false)
            case_label = 'case sharing'
        }
        const requestConfig = {
            method: 'delete',
            url: `${SHARED_WITH_ME_URL}${caseShareId}/`
        }
        let alert;
        try {
            const response = await axios(requestConfig)
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            alert = {
                text: `${capitalizeFirstLetter(case_label)} has been removed successfully!`,
                type: "success",
            };
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
            alert = {
                text: `An error occurred during ${case_label} removal.`,
                type: "error",
            };
        }
        finally {
            await refreshPage()
            addToast(alert.type, alert.text)
        }
    }

    const deleteCase = async (caseId) => {
        setLoading(true)
        setData([])
        setShowDeleteCaseModal(false)
        const requestConfig = {
            method: 'delete',
            url: `${CASE_URL}${caseId}/`
        }
        let alert;
        try {
            const response = await axios(requestConfig)
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            alert = {
                text: "Case has been deleted successfully!",
                type: "success",
            };
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
            alert = {
                text: "An error occurred during case deletion.",
                type: "error",
            };
        }
        finally {
            await refreshPage()
            addToast(alert.type, alert.text)
        }
    }

    const refreshPage = async () => {
        await fetchData({ pageSize: 15, pageIndex: pageIndex })
    }

    return (
        <div className="CaseList p-4">
            <Container fluid className="h-100 w-100">
                <div className="d-flex flex-column h-100">
                    <p className="figma-h4-semibold primary-600 mb-4">
                        {props.type === 'my_cases' && <>My cases</>}
                        {props.type === 'shared_with_me' && <>Shared with me</>}
                    </p>
                    {(emptyDiv)
                        ? <div className='d-flex flex-column h-100 w-100 justify-content-start align-items-center pt-5 mt-5'>
                            <EmptyFolder />
                            <span className="figma-h6-bold neutral-500 mt-4">No cases available</span>
                            {noCases
                                ? (user.customer.user_type === 'hc_professional')
                                    ? <>
                                        <span className="figma-p1 neutral-800 mt-2 text-center">
                                            Your account does not contain any cases.<br />
                                            If you want to try the features of Verima<br />
                                            you can request our <span className="figma-p1-semibold">demo cases</span>.
                                        </span>
                                        <Button variant='outline-primary' className="mt-4 px-4 py-3 figma-p1-semibold" onClick={() => {navigate('/profile?subsection=demo-cases')}}>Get demo cases</Button>
                                    </>
                                    : <>
                                        <span className="figma-p1 neutral-800 mt-2 text-center">
                                            Your account does not contain any cases.<br />
                                            Ask your doctor to share a case with you.
                                        </span>
                                        <Button variant='outline-primary' className="mt-4 px-4 py-3 figma-p1-semibold" onClick={() => {setShowMoreInfoModal(true)}}>More info</Button>
                                        <MoreInfoModal showMoreInfoModal={showMoreInfoModal} setShowMoreInfoModal={setShowMoreInfoModal} />
                                    </>
                                : <>
                                    {props.type === 'my_cases' &&
                                        <span className="figma-p1 neutral-800 mt-2">Your account does not contain any personal cases.</span>
                                    }
                                    {props.type === 'shared_with_me' &&
                                        <span className="figma-p1 neutral-800 mt-2">Your account does not contain any cases shared with you.</span>
                                    }
                                </>
                            }
                            
                        </div>
                        : <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        loading={loading}
                        pageCount={pageCount}
                        dataCount={dataCount}
                        controlledPageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        disableSortBy={true}
                    />
                    }
                </div>
            </Container>
            <DeleteCaseModal showDeleteCaseModal={showDeleteCaseModal} setShowDeleteCaseModal={setShowDeleteCaseModal} deleteCaseId={deleteCaseId} deleteCaseName={deleteCaseName} deleteCase={deleteCase} />
            <DeleteSharedCaseModal showDeleteSharedCaseModal={showDeleteSharedCaseModal} setShowDeleteSharedCaseModal={setShowDeleteSharedCaseModal} deleteSharedCaseId={deleteSharedCaseId} deleteSharedCaseName={deleteSharedCaseName} deleteSharedCase={deleteSharedCase} />
            <DeleteDemoCaseModal showDeleteDemoCaseModal={showDeleteDemoCaseModal} setShowDeleteDemoCaseModal={setShowDeleteDemoCaseModal} deleteDemoCaseId={deleteDemoCaseId} deleteSharedCase={deleteSharedCase} />
        </div>
    );
}

export const availableProductsConfigurations = {
    '1': {
        'yearly_billing_monthly_price': 89,
        'license_thresholds': {
            'create_from_stl': 20,
            'archive_size': 20,
            'share': 10
        },
    },
    '2': {
        'yearly_billing_monthly_price': 179,
        'license_thresholds': {
            'create_from_stl': 100,
            'archive_size': 100,
            'share': 20
        },
    }
}
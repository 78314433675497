import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import { FiAlertCircle} from "react-icons/fi";
import './Notifications.scss';


export default function DetailNotificationModal({showDetailNotificationModal, setShowDetailNotificationModal, detailNotificationTitle, detailNotificationText}) {
    return (
        <Modal centered size={'sm'} show={showDetailNotificationModal} onHide={() => setShowDetailNotificationModal(false)}>
            <Modal.Body id="detail-notification-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="alert-circle mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiAlertCircle className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">{detailNotificationTitle}</p>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1 neutral-500">{detailNotificationText}</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="primary" className="px-5 w-100" onClick={(e) => {setShowDetailNotificationModal(false); e.target.blur()}}>Continue</Button>
            </Modal.Footer>
        </Modal>
    );
}

import axios from "axios";
import { unzipSync } from 'fflate';
import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Image, Overlay, OverlayTrigger, Popover, PopoverBody, Row, Spinner } from "react-bootstrap";
import { FiShare2, FiTrash2, FiUser, FiBookmark } from 'react-icons/fi';
import config from '../../config';
import no_preview from '../../static/images/no_preview.svg';
import {ReactComponent as EditCase} from '../../static/icons/edit_case_details.svg';
import { CASE_URL, CUSTOMER_URL, SHARED_WITH_ME_URL } from "../../utils/Endpoints";
import './CaseDetail.scss';
// import { saveAs } from 'file-saver';
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { DEFAULT_IMAGE_FALLBACK, addToast, capitalizeFirstLetter, get8DigitHex, getAsByteArray, getColorInfo, getDateTimeString, getPreviewUrl, getSelectedPreviewUrl } from "../../utils/Common";
import DeleteCaseModal from "../CaseList/DeleteCaseModal";
import DeleteDemoCaseModal from "../CaseList/DeleteDemoCaseModal";
import DeleteSharedCaseModal from "../CaseList/DeleteSharedCaseModal";
import CaseModal from "./CaseModal";
import ShareModal from "./ShareModal";
import TransferModal from "./TransferModal";

export default function CaseDetail(props) {
    const outletContext = useOutletContext()
    let params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [loadingShares, setLoadingShares] = useState(true)
    const [loadingOwner, setLoadingOwner] = useState(true)
    const [caseObj, setCaseObj] = useState({ id: null })
    const [editingCaseObj, setEditingCaseObj] = useState({ id: null })
    const [caseShareObj, setCaseShareObj] = useState({ id: null })
    const [caseOwner, setCaseOwner] = useState(null)
    const [sharedWith, setSharedWith] = useState([])
    const [showCaseModal, setShowCaseModal] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [showTransferModal, setShowTransferModal] = useState(false)
    const [STLFile, setSTLFile] = useState(null)

    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);
    const [deleteCaseId, setDeleteCaseId] = useState(null);
    const [deleteCaseName, setDeleteCaseName] = useState(null);

    const [showDeleteSharedCaseModal, setShowDeleteSharedCaseModal] = useState(false);
    const [deleteSharedCaseId, setDeleteSharedCaseId] = useState(null);
    const [deleteSharedCaseName, setDeleteSharedCaseName] = useState(null);

    const [showDeleteDemoCaseModal, setShowDeleteDemoCaseModal] = useState(false);
    const [deleteDemoCaseId, setDeleteDemoCaseId] = useState(null);

    const [starFilled, setStarFilled] = useState(false)
    const favTarget = useRef(null);
    const [favPopoverAdd, setFavPopoverAdd] = useState(true);
    const [showFavPopover, setShowFavPopover] = useState(false);
    const [lastTimeout, setLastTimeout] = useState(null);

    const [editMode, setEditMode] = useState(false);

    const [caseNameError, setCaseNameError] = useState(null);
    const [caseDescriptionError, setCaseDescriptionError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);

    const maxLenghtDescription = 400;

    const previewFirstThumbnailRef = useRef(null);
    const previewSecondThumbnailRef = useRef(null);
    const previewThirdThumbnailRef = useRef(null);

    useEffect(() => {
        if(editMode && editingCaseObj && !loading){
            const previewFirstThumbnail = 'background-image: url(' + getPreviewUrl(editingCaseObj, 'sagittal', true, '132x76') + ')';
            const previewSecondThumbnail = 'background-image: url(' + getPreviewUrl(editingCaseObj, 'coronal', true, '132x76') + ')';
            const previewThirdThumbnail = 'background-image: url(' + getPreviewUrl(editingCaseObj, 'axial', true, '132x76') + ')';
            previewFirstThumbnailRef.current.style =  previewFirstThumbnail;
            previewSecondThumbnailRef.current.style = previewSecondThumbnail;
            previewThirdThumbnailRef.current.style = previewThirdThumbnail;
        }
    }, [editMode, editingCaseObj, loading])
  
    async function fetchData() {
        setLoading(true)
        setCaseObj(null)
        setCaseShareObj(null)
        setSTLFile(null)
        let caseUrl;
        let actualStarFilled;
        let formData;
        if (props.type === 'my_cases') {
            caseUrl = `${CASE_URL}${params.caseId}/`
            formData = new FormData();
            formData.append("received_badge", false);
            try {
                await axios({
                    method: 'patch',
                    url: caseUrl,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
            }
            catch (error) {
                if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            }
        }
        else if (props.type === 'shared_with_me') {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${SHARED_WITH_ME_URL}${params.caseShareId}/`,
                })
                let caseShareData = response.data
                formData = new FormData();
                formData.append("case_opened", true);
                await axios({
                    method: 'patch',
                    url: `${SHARED_WITH_ME_URL}${params.caseShareId}/`,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                caseUrl = caseShareData.shared_case
                actualStarFilled = caseShareData.is_favourite
                setCaseShareObj(caseShareData)
            }
            catch (error) {
                if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                if (error.response?.status === 404) {
                    addToast('error', 'The case is no longer available for your account.')
                    if (props.type === 'my_cases') {
                        navigate('/my-cases')
                    }
                    else if (props.type === 'shared_with_me') {
                        navigate('/shared-with-me')
                    }
                }
                else {
                    setCaseObj(null)
                    setSTLFile(null)
                    setCaseShareObj(null)
                    setLoading(false)
                }
            }
        }
        const requestConfig = {
            method: 'get',
            url: caseUrl,
        }
        try {
            const response = await axios(requestConfig)
            let caseData = response.data
            caseData.verinfo.layerinfo_set = caseData.verinfo.layerinfo_set.map((layer) => {
                layer.errors = {}
                return layer
            })
            setCaseObj(caseData)
            updateCaseOwner(caseData)
            if (props.type === 'my_cases') {
                updateShares(caseData)
                actualStarFilled = caseData.is_favourite
            }
            setStarFilled(actualStarFilled)
            setLoading(false)
            const fileUrl = caseData.output_file
            const file_response = await axios({
                method: 'get',
                url: fileUrl,
                headers: [],
                responseType: 'blob'
            })
            const output_zip = await getAsByteArray(file_response.data)
            const decompressed = unzipSync(output_zip)
            setSTLFile(decompressed)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            if (error.response?.status === 404) {
                addToast('error', 'The case is no longer available.')
                if (props.type === 'my_cases') {
                    navigate('/my-cases')
                }
                else if (props.type === 'shared_with_me') {
                    navigate('/shared-with-me')
                }
            }
            else {
                setCaseObj(null)
                setSTLFile(null)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const updateShares = async (caseObj) => {
        setLoadingShares(true)
        const sharedWithCustomers = await Promise.all(caseObj.shared_with.map(async (customerId) => {
            const customerRequestConfig = {
                method: 'get',
                url: `${CUSTOMER_URL}${customerId}/`
            }
            try {
                const customerResponse = await axios(customerRequestConfig)
                return customerResponse.data
            }
            catch (error) {
                if (config.SHOULD_LOG) { console.error('customerResponse error >>> ', error); }
            }
        }))
        setSharedWith(sharedWithCustomers)
        setLoadingShares(false)
    }

    const updateCaseOwner = async (caseObj) => {
        setLoadingOwner(true)
        const ownerRequestConfig = {
            method: 'get',
            url: `${CUSTOMER_URL}${caseObj.customer}/`
        }
        try {
            const ownerResponse = await axios(ownerRequestConfig)
            setCaseOwner(ownerResponse.data)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('ownerResponse error >>> ', error); }
        }
        setLoadingOwner(false)
    }

    const deleteSharedCase = async (caseShareId, is_demo=false) => {
        setLoading(true)
        let case_label
        if (is_demo) {
            setShowDeleteDemoCaseModal(false)
            case_label = 'demo case'
        }
        else {
            setShowDeleteSharedCaseModal(false)
            case_label = 'case sharing'
        }
        const requestConfig = {
            method: 'delete',
            url: `${SHARED_WITH_ME_URL}${caseShareId}/`
        }
        let alert;
        try {
            const response = await axios(requestConfig)
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            alert = {
                text: `${capitalizeFirstLetter(case_label)} has been removed successfully!`,
                type: "success",
            };
            navigate('/shared-with-me')
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
            alert = {
                text: `An error occurred during ${case_label} removal.`,
                type: "error",
            };
        }
        finally {
            addToast(alert.type, alert.text)
        }
    }

    const deleteCase = async (caseId) => {
        setLoading(true)
        setShowDeleteCaseModal(false)
        const requestConfig = {
            method: 'delete',
            url: `${CASE_URL}${caseId}/`
        }
        let alert;
        try {
            const response = await axios(requestConfig)
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            alert = {
                text: "Case has been deleted successfully!",
                type: "success",
            };
            navigate('/my-cases');
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
            alert = {
                text: "An error occurred during case deletion.",
                type: "error",
            };
        }
        finally {
            addToast(alert.type, alert.text)
        }
    }

    const setFavourite = async (bool) => {
        setStarFilled(bool)
        var formData = new FormData();
        formData.append("is_favourite", bool);
        const requestConfig = {
            method: 'patch',
            url: (props.type === 'my_cases') ? `${CASE_URL}${caseObj.id}/` : `${SHARED_WITH_ME_URL}${caseShareObj.id}/`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        }
        try {
            const response = await axios(requestConfig)
            clearTimeout(lastTimeout);
            setFavPopoverAdd(bool);
            setShowFavPopover(true);
            setLastTimeout(setTimeout(() => { setShowFavPopover(false) }, 1500))
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }

        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }

        }
    }

    const startEditing = () => {
        setEditMode(true)
        const actualTmpCaseObj = JSON.parse(JSON.stringify(caseObj))
        // spread operator copy {...caseObj} is not deep enough
        setEditingCaseObj(actualTmpCaseObj)
    }

    const stopEditing = () => {
        setEditMode(false)
        setEditingCaseObj({ id: null })
        cleanErrors()
    }

    const cleanErrors = () => {
        const actualCaseObj = {...caseObj}
        actualCaseObj.verinfo.layerinfo_set.map((layer) => {
            layer.errors = {}
            return layer
        })
        setCaseObj(actualCaseObj)
        setCaseNameError(null);
        setNonFieldErrors([]);
        setVerErrors([]);
    }

    const handleCaseNameChange = (e) => {
        const regex = /^[a-zA-Z\u00C0-\u00FF0-9-_\x20]+$/
        if (!e.target.value.match(regex) && e.target.value != '') {
            setCaseNameError('Case name contains unacceptable characters.')
        } else if (e.target.value == ''){
            setCaseNameError('Case name cannot be empty.')
        } else {
            setCaseNameError(null)
        }
        setEditingCaseObj({
            ...editingCaseObj,
            verinfo: {
                ...editingCaseObj.verinfo,
                'case_name': e.target.value,
            }
        })
    };

    const handleCaseDescriptionChange = (e) => {
        if(e.target.value.length <= maxLenghtDescription)
        {
            setEditingCaseObj({
                ...editingCaseObj,
                case_description: e.target.value,
            })
        }
    };

    const handleLayerChange = (e) => {
        const fieldtype = e.target.attributes.fieldtype.value
        let actualTmpCaseObj = {...editingCaseObj}
        let updatedLayers = actualTmpCaseObj.verinfo.layerinfo_set.map((layer) => {
            if (layer.id.toString() === e.target.dataset.idx) {
                if (fieldtype === "layer_name") {
                    layer[fieldtype] = e.target.value
                }
                else if (fieldtype === "color") {
                    const opacity = getColorInfo(layer.material_color)['opacity_percentage']
                    layer['material_color'] = get8DigitHex(e.target.value, opacity)
                }
                else if (fieldtype === "opacity") {
                    const color = getColorInfo(layer.material_color)['color_hex']
                    layer['material_color'] = get8DigitHex(color, e.target.value)
                }
            }
            return layer;
        })

        actualTmpCaseObj.verinfo.layerinfo_set = updatedLayers
        setEditingCaseObj(actualTmpCaseObj)
    }

    const updateSelectedPreview = (type) => {
        const actualEditingCaseObj = {...editingCaseObj}
        actualEditingCaseObj.verinfo.selected_preview = type
        setEditingCaseObj(actualEditingCaseObj)
    }

    const saveButtonEnabled = () => {
        const changes = JSON.stringify(caseObj) !== JSON.stringify(editingCaseObj)
        const emptyCasename = !editingCaseObj.verinfo.case_name
        const emptyLayers = editingCaseObj.verinfo.layerinfo_set.some(layer => !layer.layer_name)
        const layerErrors = editingCaseObj.verinfo.layerinfo_set.some(layer => layer.errors.layer_name)
        const errors = layerErrors || nonFieldErrors.length > 0 || caseNameError
        return changes && !emptyCasename && !emptyLayers && !errors
    }

    async function handleSave(event) {
        event.preventDefault();
        setLoading(true);
        var formData = new FormData();
        for (const [idx, layer] of editingCaseObj.verinfo.layerinfo_set.entries()) {
            formData.append(`verinfo.layerinfo_set[${idx}]id`, layer.id);
            formData.append(`verinfo.layerinfo_set[${idx}]layer_name`, layer.layer_name);
            formData.append(`verinfo.layerinfo_set[${idx}]material_color`, layer.material_color);
            formData.append(`verinfo.layerinfo_set[${idx}]material_type`, layer.opacity === '100' ? 'standard' : 'transparent');
        }
        formData.append("verinfo.case_name", editingCaseObj.verinfo.case_name);
        formData.append("case_description", editingCaseObj.case_description);
        formData.append("verinfo.selected_preview", editingCaseObj.verinfo.selected_preview);
        try {
            const response = await axios({
                method: 'patch',
                url: `${CASE_URL}${editingCaseObj.id}/`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) {console.log('response >>> ', response);}
            addToast('success', "Your case has been updated successfully!");
            stopEditing()
            await fetchData()
        }
        catch (error) {
            if (error.response?.data) {
                setCaseNameError(error.response.data.case_name);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
        }
        setLoading(false);
    }

    return (
        <div className="CaseDetail" >
            <Container fluid className="h-100 w-100 p-4">
                {loading
                    ? (
                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="fullSpinner" />
                        </div>
                    )
                    : (
                        <>
                            <Row className="main-row h-100 flex-nowrap">
                                <Col className="leftColumn pe-5" md={7}>
                                    <p className="figma-h4-semibold primary-600 mb-4">{!editMode ? 'Case details' : 'Edit case'}</p>
                                    <div className="nonfield-errors mt-1 w-100">
                                        {nonFieldErrors.map((error) =>
                                        <p key={error}>{error}</p>
                                        )}
                                    </div>
                                    <div className="nonfield-errors mt-1 w-100">
                                        {verErrors.map((error) =>
                                        <p key={error.code}>{error.msg}</p>
                                        )}
                                    </div>
                                    {!editMode &&
                                        <Row className="mb-4 flex-nowrap">
                                            <Col className="flex-grow-1 flex-shrink-0 figma-p1 neutral-600">
                                                Created at: <span className="figma-p1-semibold">{getDateTimeString(new Date(caseObj.created_at))}</span>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="flex-nowrap mb-3">
                                        <Col className="flex-grow-1 flex-shrink-0">
                                            <Form.Group >
                                                <Form.Label>Case name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Insert your case name here"
                                                    name="case_name"
                                                    id="case_name"
                                                    value={editMode ? editingCaseObj.verinfo.case_name : caseObj.verinfo.case_name}
                                                    onChange={(e) => { handleCaseNameChange(e) }}
                                                    isInvalid={(caseNameError) ? true : false}
                                                    disabled={!editMode}
                                                />
                                                <Form.Control.Feedback type="invalid">{caseNameError}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {!editMode &&
                                            <Col className="flex-grow-0 flex-shrink-1">
                                                <Form.Group className="text-center">
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    <Overlay key={favPopoverAdd} target={favTarget.current} show={showFavPopover} placement="bottom">
                                                        <Popover className={"favourite-popover"}>
                                                            <PopoverBody className="py-1">
                                                                {favPopoverAdd ? 'Case added to favourites' : 'Case removed from favourites'}
                                                            </PopoverBody>
                                                        </Popover>
                                                    </Overlay>
                                                    <Button  ref={favTarget} type="button" variant="outline-primary" key="edit-case" className="edit-case-button p-0" onClick={(e) => { !editMode && setFavourite(!starFilled); e.currentTarget.blur();}}>
                                                        <div className="d-block p-0 m-auto">
                                                            <FiBookmark size="24px" className={" star-icon clickable" + (starFilled ? " filled" : "")} />
                                                        </div>
                                                    </Button>
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mb-4 flex-nowrap">
                                        <Col className="flex-grow-1 flex-shrink-0">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Case description</Form.Label>
                                                <Form.Control
                                                    className="figma-p1 p-2"
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder={(editMode) ? "Add description here" : ((props.type === 'my_cases') ? "There is no description, press the edit button to add it." : "There is no description for this case.")}
                                                    name="case_description"
                                                    id="case_description"
                                                    value={editMode ? editingCaseObj.case_description : caseObj.case_description}
                                                    onChange={(e) => { handleCaseDescriptionChange(e) }}
                                                    isInvalid={(caseDescriptionError) ? true : false}
                                                    disabled={!editMode}
                                                />
                                                {editMode &&
                                                    <div className="text-end">
                                                        <Form.Label className={(editingCaseObj.case_description.length == maxLenghtDescription) ? "text-danger" : ""} >{maxLenghtDescription - editingCaseObj.case_description.length}/{maxLenghtDescription}</Form.Label>
                                                    </div>
                                                }
                                                <Form.Control.Feedback type="invalid">{caseDescriptionError}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <span className="figma-h5 primary-600">Case layers</span>
                                    {
                                        (editMode ? editingCaseObj : caseObj).verinfo.layerinfo_set.map((layer) => {
                                            const layer_id = layer.id
                                            const nameId = `layer-name-${layer_id}`
                                            const colorId = `color-${layer_id}`
                                            const opacityId = `opacity-${layer_id}`
                                            const { color_hex, opacity_percentage } = getColorInfo(layer.material_color)
                                            return (
                                                <div key={`layer-${layer_id}`} className="my-3">
                                                    <Row className="justify-content-between">
                                                        <Col className="flex-grow-1 flex-shrink-0">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Layer name</Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    type="text"
                                                                    placeholder="Insert layer name"
                                                                    name={nameId}
                                                                    data-idx={layer_id}
                                                                    id={nameId}
                                                                    fieldtype="layer_name"
                                                                    value={layer.layer_name}
                                                                    autoComplete="layer name"
                                                                    onChange={(e) => { handleLayerChange(e) }}
                                                                    isInvalid={editMode && layer.errors.layer_name}
                                                                    disabled={!editMode}
                                                                />
                                                                {editMode && <Form.Control.Feedback type="invalid">{layer.errors.layer_name}</Form.Control.Feedback>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="flex-grow-0 flex-shrink-1">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Color</Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    type="color"
                                                                    name={colorId}
                                                                    data-idx={layer_id}
                                                                    id={colorId}
                                                                    fieldtype="color"
                                                                    value={color_hex}
                                                                    disabled={!editMode}
                                                                    onChange={(e) => { handleLayerChange(e) }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="flex-grow-0 flex-shrink-1">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Opacity %</Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    type="number"
                                                                    min="0"
                                                                    max="100"
                                                                    name={opacityId}
                                                                    data-idx={layer_id}
                                                                    id={opacityId}
                                                                    fieldtype="opacity"
                                                                    value={opacity_percentage}
                                                                    onChange={handleLayerChange}
                                                                    disabled={!editMode}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                                <Col md={5} className="rightColumn ps-5">
                                    <Row className="w-100 gx-0 justify-content-start mb-4 mt-1">
                                        {editMode
                                            ?
                                            <>
                                                <Button size="sm" type="button" variant="outline-primary" key="cancel-changes" className="cancel-changes-button p-0 ms-auto" onClick={(e) => { stopEditing(); e.target.blur() }}>
                                                    Cancel
                                                </Button>
                                                <Button size="sm" type="submit" variant="primary" key="save-changes" className="save-changes-button p-0 ms-3" disabled={!saveButtonEnabled()} onClick={(e) => { handleSave(e); }}>
                                                    Save changes
                                                </Button>
                                            </>
                                            :
                                            <>
                                                {(props.type === 'my_cases') &&
                                                    <Button type="button" variant="outline-primary" key="edit-case" className="edit-case-button p-0 me-3" onClick={(e) => { startEditing(); e.target.blur() }}>
                                                        <div className="d-block p-0 m-auto">
                                                            <EditCase className="icon icon-edit"/>
                                                        </div>
                                                    </Button>}
                                                {
                                                    <Button type="button" variant="outline-primary" key="delete-case" className="delete-case-button p-0 me-3 align-self-start"
                                                        onClick={(e) => {
                                                            if (props.type === 'my_cases') {
                                                                setShowDeleteCaseModal(true); setDeleteCaseId(caseObj.id); setDeleteCaseName(caseObj.verinfo.case_name);
                                                            }
                                                            else {
                                                                if (caseShareObj.is_demo) {
                                                                    setShowDeleteDemoCaseModal(true); setDeleteDemoCaseId(caseShareObj.id);
                                                                }
                                                                else {
                                                                    setShowDeleteSharedCaseModal(true); setDeleteSharedCaseId(caseShareObj.id); setDeleteSharedCaseName(caseObj.verinfo.case_name);
                                                                }
                                                            }
                                                            e.target.blur()
                                                        }}>
                                                        <div className="d-block p-0 m-auto">
                                                            <FiTrash2 size="24px" />
                                                        </div>
                                                    </Button >
                                                }
                                                <Button size="sm" type="button" variant="primary" key="open-case" className="open-case-button p-0 ms-auto" onClick={(e) => { setShowCaseModal(true); e.target.blur() }} disabled={Boolean(!STLFile)}>
                                                    {STLFile
                                                        ? "Open the case"
                                                        : <><Spinner animation="border" variant="secondary" size="sm" className="me-3" /> Loading...</>
                                                    }
                                                </Button>
                                            </>
                                        }
                                    </Row>
                                    <div className="">
                                        <p className="figma-h5 primary-600 mb-3">Preview</p>
                                        <Card className="p-3 preview-card">
                                            <Image fluid src={getSelectedPreviewUrl((editMode ? editingCaseObj : caseObj), false)} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = no_preview }} />
                                        </Card>
                                        {editMode
                                            ? <>
                                                <div className="figma-p1 neutral-600 mt-4 mb-3">Choose another preview image:</div>
                                                <div ref={previewFirstThumbnailRef} className={"preview-thumbnail me-2" + (["sagittal", ""].includes(editingCaseObj.verinfo.selected_preview) ? " selected" : "")} onClick={() => {updateSelectedPreview('sagittal')}}><div className="greenDot"></div>                                                </div>
                                                <div ref={previewSecondThumbnailRef} className={"preview-thumbnail me-2" + (editingCaseObj.verinfo.selected_preview === "coronal" ? " selected" : "")} onClick={() => {updateSelectedPreview('coronal')}}><div className="greenDot"></div>                                                </div>
                                                <div ref={previewThirdThumbnailRef} className={"preview-thumbnail me-2" + (editingCaseObj.verinfo.selected_preview === "axial" ? " selected" : "")} onClick={() => {updateSelectedPreview('axial')}}><div className="greenDot"></div>                                                </div>
                                            </>
                                            : <div className="d-flex justify-content-between mt-5">
                                                <div className={(props.type === 'my_cases' ? "col-6" : "col-12") + "d-flex flex-column justify-content-left"}>
                                                    <p className="figma-h5 primary-600 mb-3">Owner</p>
                                                    <div className="d-flex justify-content-left">
                                                        {loadingOwner
                                                            ? (
                                                                <Spinner animation="border" variant="secondary" />
                                                            )
                                                            : (<>
                                                                {!caseOwner.username.startsWith('__') &&
                                                                    <div className="align-self-center">
                                                                        <Image className="profile-picture" fluid roundedCircle src={caseOwner.profile_img_medium} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = DEFAULT_IMAGE_FALLBACK }} />
                                                                    </div>
                                                                }
                                                                <div className={"d-flex align-items-center" + (caseOwner.username.startsWith('__') ? '' : ' ms-2')}>
                                                                    <div className="figma-p1 neutral-600">
                                                                        {caseOwner.username.startsWith('__') ? <span className="neutral-400 fst-italic">Unavailable</span> : `${caseOwner.first_name} ${caseOwner.last_name}`}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            )
                                                        }
                                                    </div>
                                                    {props.type === 'my_cases' && outletContext.user.plan === '3' &&
                                                        <Button type="button" variant="outline-primary" className="d-flex justify-content-center align-items-center figma-p1-semibold p-2 mt-3 align-self-end fit-content" onClick={(e) => { setShowTransferModal(true); e.target.blur() }}>
                                                            <FiUser size="14px" className="me-2" /> Change owner
                                                        </Button>
                                                    }
                                                </div>
                                                {props.type === 'my_cases' &&
                                                    <div className="col-6 d-flex flex-column justify-content-right">
                                                        <p className="figma-h5 primary-600 mb-3 text-end">Sharing</p>
                                                        <div className="d-flex justify-content-end align-items-center sharing-thumbnails-container">
                                                            {loadingShares
                                                                ? (
                                                                    <Spinner animation="border" variant="secondary" />
                                                                )
                                                                : (
                                                                    (sharedWith.length > 0)
                                                                        ? (
                                                                            (sharedWith.length > 5)
                                                                                ? (
                                                                                    <>
                                                                                        <div className="shared-picture-container me-0 z-index-shared-with-number">
                                                                                            <div className="shared-picture number d-flex align-items-center justify-content-center">
                                                                                                {sharedWith.length - 4}
                                                                                            </div>
                                                                                        </div>
                                                                                        {sharedWith.slice(0, 4).map((customer, idx) => {
                                                                                            const newIdx = idx + 1
                                                                                            return (
                                                                                                <CaseShareIcon key={newIdx} idx={newIdx} customer={customer} total={sharedWith.length} />
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                )
                                                                                : (
                                                                                    sharedWith.map((customer, idx) =>
                                                                                        <CaseShareIcon key={idx} idx={idx} customer={customer} total={sharedWith.length} />
                                                                                    )
                                                                                )
                                                                        )
                                                                        : (
                                                                            <span className="figma-p1 neutral-600">Not shared</span>
                                                                        )
                                                                )
                                                            }
                                                        </div>
                                                        <Button type="button" variant="outline-primary" className="d-flex justify-content-center align-items-center figma-p1-semibold p-2 mt-3 align-self-end fit-content" onClick={(e) => { setShowShareModal(true); e.target.blur() }}>
                                                            <FiShare2 size="14px" className="me-2" /> Manage sharing
                                                        </Button>
                                                    </div>
                                                }
                                                {props.type === 'shared_with_me' && caseShareObj.is_demo &&
                                                    <div className="col-6 d-flex flex-column justify-content-right">
                                                        <p className="figma-h5 primary-600 mb-3 text-end">
                                                            &nbsp;
                                                        </p>
                                                        <div className="d-flex justify-content-end align-items-center sharing-thumbnails-container">
                                                            <Badge pill className='ms-3 figma-caption-semibold px-3 py-1' bg='primary' text='secondary'>DEMO</Badge>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {showCaseModal && STLFile &&
                                <CaseModal setShowCaseModal={setShowCaseModal} stlFile={STLFile} caseObj={caseObj} fetchData={fetchData} isMyCase={props.type === 'my_cases'} />
                            }
                            {showShareModal &&
                                <ShareModal setShowShareModal={setShowShareModal} sharedWith={sharedWith} updateShares={updateShares} loadingShares={loadingShares} setLoadingShares={setLoadingShares} caseId={params.caseId} caseObj={caseObj} isEnterprise={outletContext.user.plan === '3'} />
                            }
                            {showTransferModal &&
                                <TransferModal setShowTransferModal={setShowTransferModal} caseOwner={caseOwner} caseId={params.caseId} caseObj={caseObj} />
                            }
                        </>
                    )
                }
            </Container>
            <DeleteCaseModal showDeleteCaseModal={showDeleteCaseModal} setShowDeleteCaseModal={setShowDeleteCaseModal} deleteCaseId={deleteCaseId} deleteCaseName={deleteCaseName} deleteCase={deleteCase} />
            <DeleteSharedCaseModal showDeleteSharedCaseModal={showDeleteSharedCaseModal} setShowDeleteSharedCaseModal={setShowDeleteSharedCaseModal} deleteSharedCaseId={deleteSharedCaseId} deleteSharedCaseName={deleteSharedCaseName} deleteSharedCase={deleteSharedCase} />
            <DeleteDemoCaseModal showDeleteDemoCaseModal={showDeleteDemoCaseModal} setShowDeleteDemoCaseModal={setShowDeleteDemoCaseModal} deleteDemoCaseId={deleteDemoCaseId} deleteSharedCase={deleteSharedCase} />
        </div>
    );
}

function CaseShareIcon({ idx, customer, total }) {
    return (
        <OverlayTrigger
            placement={"bottom"}
            overlay={
                <Popover className="shared-popover">
                    <PopoverBody className="py-1">
                        {(!customer.username || customer.username.startsWith('__')) ? customer.email : `${customer.first_name} ${customer.last_name}`}
                    </PopoverBody>
                </Popover>
            }
        >
            <div className={`shared-picture-container z-index-shared-with-${idx}`}>
                <Image className="shared-picture" fluid roundedCircle src={customer.profile_img_medium} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = DEFAULT_IMAGE_FALLBACK }} />
            </div>
        </OverlayTrigger>
    )
}
import { useState, useMemo, useCallback } from "react";
import { Container, Form, Image } from "react-bootstrap";
import './Notifications.scss'
import axios from "axios";
import { FiX } from 'react-icons/fi';
import config from '../../config'
import { CASE_URL, NOTIFICATION_URL, SHARED_WITH_ME_URL } from "../../utils/Endpoints";
import { ReactComponent as EmptyNotifications } from '../../static/images/empty_notifications.svg';
import notification_case_success from '../../static/images/notification_case_success.svg';
import notification_case_failure from '../../static/images/notification_case_failure.svg';
import notification_share_added from '../../static/images/notification_share_added.svg';
import notification_share_deleted from '../../static/images/notification_share_deleted.svg';
import notification_license_alert from '../../static/images/notification_license_alert.svg';
import notification_license from '../../static/images/notification_license.svg';
import notification_tool from '../../static/images/notification_tool.svg';
import notification_tool_alert from '../../static/images/notification_tool_alert.svg';
import notification_case_transfer from '../../static/images/notification_case_transfer.svg';
import DeleteNotificationModal from "./DeleteNotificationModal";
import DetailNotificationModal from "./DetailNotificationModal";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getDateString, isToday } from "../../utils/Common";
import NotificationsTable from "./NotificationsTable/NotificationsTable";

export default function Notifications(props) {
    const CASE_SUCCESS = 'case_creation_success'
    const CASE_FAILURE = 'case_creation_failure'
    const SHARE_ADDED = 'new_case_share'
    const SHARE_DELETED = 'case_share_deleted'
    const LICENSE_ALERT = 'license_alert'
    const LICENSE = 'license'
    const TOOL_ADDED = 'tool_added'
    const TOOL_REMOVED = 'tool_removed'
    const CASE_TRANSFER = 'case_transfer'
    const MULTIPLE_CASE_TRANSFER = 'multiple_case_transfer'
    const ADDED_TO_WORKSPACE = 'added_to_workspace'
    const REMOVED_FROM_WORKSPACE = 'removed_from_workspace'

    const outletContext = useOutletContext()
    const navigate = useNavigate()
    const [emptyDiv, setEmptyDiv] = useState(false)

    const columns = useMemo(() => {
        return [
            {
                Header: 'Icon',
                accessor: 'icon',
                Cell: cell => {
                    let imageSrc;
                    switch (cell.row.original.category) {
                        case CASE_SUCCESS:
                            imageSrc = notification_case_success
                            break
                        case CASE_FAILURE:
                            imageSrc = notification_case_failure
                            break
                        case SHARE_ADDED:
                            imageSrc = notification_share_added
                            break
                        case SHARE_DELETED:
                            imageSrc = notification_share_deleted
                            break
                        case LICENSE_ALERT:
                            imageSrc = notification_license_alert
                            break
                        case LICENSE:
                            imageSrc = notification_license
                            break
                        case TOOL_ADDED:
                            imageSrc = notification_tool
                            break
                        case TOOL_REMOVED:
                            imageSrc = notification_tool_alert
                            break
                        case CASE_TRANSFER:
                            imageSrc = notification_case_transfer
                            break
                        case MULTIPLE_CASE_TRANSFER:
                            imageSrc = notification_case_transfer
                            break
                        case ADDED_TO_WORKSPACE:
                            imageSrc = notification_license
                            break
                        case REMOVED_FROM_WORKSPACE:
                            imageSrc = notification_license
                            break
                        default:
                            imageSrc = ""
                            break
                    }
                    return <Image width={40} src={imageSrc} className="preview-image me-4 ms-2" />
                }
            },
            {
                Header: 'Title',
                accessor: 'title'
            },
            {
                Header: 'Text',
                accessor: 'text'
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ cell: { value } }) => {
                    if (isToday(value)) {
                        return value.toLocaleTimeString('it-IT', {hour: '2-digit', minute: '2-digit'})
                    }
                    else {
                        return getDateString(value)
                    }
                }
            },
            {
                Header: 'DELETE',
                accessor: '',
                Cell: cell => {
                    return (
                        <div className="delete-notification-icon-container h-100 d-flex align-items-center justify-content-center"  onClick={(e) => { setShowDeleteNotificationModal(true); setDeleteNotificationId(cell.row.original.id); e.target.blur() }}>
                            <FiX className="delete-notification-icon cursor-pointer" size={'24px'} />
                        </div>
                    )
                }
            },
        ]
    }, [])

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    
    const [showDeleteNotificationModal, setShowDeleteNotificationModal] = useState(false);
    const [deleteNotificationId, setDeleteNotificationId] = useState(null);
    
    const [showDetailNotificationModal, setShowDetailNotificationModal] = useState(false);
    const [detailNotificationTitle, setDetailNotificationTitle] = useState('');
    const [detailNotificationText, setDetailNotificationText] = useState('');
    
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)
    
    const fetchData = useCallback(async ({ pageSize, pageIndex }) => {
        setLoading(true)
        setData([])
        let target_url = NOTIFICATION_URL
        if (showOnlyUnread) {
            target_url += '?opened=false'
        }
        const requestConfig = {
            method: 'get',
            url: target_url,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            let notificationsData = response.data.results.map((notification) => {
                return {
                    ...notification,
                    created_at: new Date(notification.created_at), 
                }
            })
            setEmptyDiv(notificationsData.length === 0)
            setData(notificationsData)
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
            outletContext.checkUpdateUnreadNotifications()
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setData([])
        }
        setLoading(false)
    }, [showOnlyUnread])

    const deleteNotification = async (notificationId) => {
        setLoading(true)
        setData([])
        setShowDeleteNotificationModal(false)
        const requestConfig = {
            method: 'delete',
            url: `${NOTIFICATION_URL}${notificationId}/`
        }
        try {
            const response = await axios(requestConfig)
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
        finally {
            await refreshPage()
        }
    }

    const handleNotificationClick = async (notification) => {
        // Call a GET on the notification to set it as opened on the server
        const was_opened = notification.opened
        await axios({
            method: 'get',
            url: `${NOTIFICATION_URL}${notification.id}/`,
        })
        switch (notification.category) {
            case CASE_SUCCESS:
                const case_success_id = notification.target_case_id
                try {
                    await axios({
                        method: 'get',
                        url: `${CASE_URL}${case_success_id}/`,
                    })
                    navigate(`/my-cases/${case_success_id}`)
                }
                catch {
                    handleSimpleNotification(notification, 'The case is no longer available', 'The case you are looking for is no longer available for your account.')
                }
                break
            case CASE_FAILURE:
                handleSimpleNotification(notification)
                break
            case SHARE_ADDED:
                const share_id = notification.target_share_id
                try {
                    await axios({
                        method: 'get',
                        url: `${SHARED_WITH_ME_URL}${share_id}/`,
                    })
                    navigate(`/shared-with-me/${share_id}`)
                }
                catch {
                    handleSimpleNotification(notification, 'The case sharing is no longer available', 'The case sharing you are looking for is no longer available for your account.')
                }
                break
            case SHARE_DELETED:
                handleSimpleNotification(notification)
                break
            case LICENSE_ALERT:
                handleSimpleNotification(notification)
                break
            case LICENSE:
                handleSimpleNotification(notification)
                break
            case TOOL_ADDED:
                handleSimpleNotification(notification)
                break
            case TOOL_REMOVED:
                handleSimpleNotification(notification)
                break
            case CASE_TRANSFER:
                const case_transfer_id = notification.target_case_id
                try {
                    await axios({
                        method: 'get',
                        url: `${CASE_URL}${case_transfer_id}/`,
                    })
                    navigate(`/my-cases/${case_transfer_id}`)
                }
                catch {
                    handleSimpleNotification(notification, 'The case is no longer available', 'The case you are looking for is no longer available for your account.')
                }
                break
            case MULTIPLE_CASE_TRANSFER:
                navigate(`/my-cases`)
                break
            case ADDED_TO_WORKSPACE:
                handleSimpleNotification(notification)
                break
            case REMOVED_FROM_WORKSPACE:
                handleSimpleNotification(notification)
                break
            default:
                break
        }
        if (!was_opened) {
            outletContext.checkUpdateUnreadNotifications()
        }
    }

    const handleSimpleNotification = (notification, title = null, text = null) => {
        title = title || notification.title
        text = text || notification.text
        setShowDetailNotificationModal(true);
        setDetailNotificationTitle(title);
        setDetailNotificationText(text);
        const index = data.findIndex(obj => obj.id === notification.id)
        data[index].opened = true
    }

    const refreshPage = async () => {
        await fetchData({ pageSize: 15, pageIndex: pageIndex })
    }

    return (
        <div className="Notifications p-4">
            <Container fluid className="h-100 w-100">
                <div className="d-flex flex-column h-100 overflow-auto">
                    <p className="figma-h4-semibold primary-600 mb-4">Notifications</p>
                    <div className="notifications-container w-50 h-100 ms-auto me-auto d-flex flex-column">
                        <Form className="pb-4 d-flex align-items-center">
                            <span className="figma-p1 neutral-600">Choose the type of visualization:</span>
                            <Form.Check.Label htmlFor="opened-switch" className="figma-p1-semibold neutral-600 ms-auto">Show only unread notifications</Form.Check.Label>
                            <Form.Check
                                type="switch"
                                id="opened-switch"
                                className="d-inline ms-3 fs-5"
                                checked={showOnlyUnread}
                                onChange={(e) => { setShowOnlyUnread(e.target.checked) }}
                            />
                        </Form>
                            <div className={'flex-column h-100 w-100 justify-content-center align-items-center ' + ((!loading && emptyDiv) ? 'd-flex' : 'd-none')}> 
                                <EmptyNotifications />
                                <>
                                    <span className="figma-h6-bold neutral-400 mt-4">No notice right now here</span>
                                    <span className="figma-p1 neutral-800 mt-2">Your account currently has no notification.</span>
                                </>
                            </div>
                            <NotificationsTable
                                className={(emptyDiv ? 'd-none' : 'd-flex')}
                                columns={columns}
                                data={data}
                                fetchData={fetchData}
                                loading={loading}
                                pageCount={pageCount}
                                dataCount={dataCount}
                                controlledPageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                disableSortBy={true}
                                handleNotificationClick={handleNotificationClick}
                            />
                    </div>
                </div>
            </Container>
            <DeleteNotificationModal showDeleteNotificationModal={showDeleteNotificationModal} setShowDeleteNotificationModal={setShowDeleteNotificationModal} deleteNotificationId={deleteNotificationId} deleteNotification={deleteNotification} />
            <DetailNotificationModal showDetailNotificationModal={showDetailNotificationModal} setShowDetailNotificationModal={setShowDetailNotificationModal} detailNotificationTitle={detailNotificationTitle} detailNotificationText={detailNotificationText} />
        </div>
    );
}
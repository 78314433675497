import axios from 'axios';
import { useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { FiCheckCircle, FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import no_preview from '../../../static/images/no_preview.svg';
import { addToast } from '../../../utils/Common';
import { DEMO_CASE_URL} from "../../../utils/Endpoints";
import { ReactComponent as EmptyFolder } from '../../../static/images/empty_folder.svg';
import '../Profile.scss';

export default function DemoCases({demoCases, customer, setLoading}) {
    const navigate = useNavigate()

    /* DEMO CASE CATEGORIES */
    // - orthopedics
    // - abdominal
    // - thoracic
    // - cardiac

    /* PROFESSIONS */
    // - general_surgeon
    // - urologist
    // - ophthalmologist
    // - vascular_surgeon
    // - pediatric_surgeon
    // - maxillofacial_surgeon
    // - neurosurgeon
    // - orthopedic_surgeon
    // - cardiac_surgeon
    // - thoracic_surgeon
    // - otolaryngologist
    // - abdominal_surgeon
    // - dermatologist
    // - anesthesiologist
    // - oncologist
    // - gynecologist
    // - colorectal_surgeon
    // - hand_surgeon
    // - radiologist
    // - dentist
    // - other

    let cards = [
        {
            id: 0,
            name: 'Orthopedics',
            demo_case_categories: ['orthopedics'],
            professions: ['orthopedic_surgeon'],
            cases: []
        },
        {
            id: 1,
            name: 'Abdominal surgery',
            demo_case_categories: ['abdominal'],
            professions: ['abdominal_surgeon'],
            cases: []
        },
        {
            id: 2,
            name: 'Thoracic surgery',
            demo_case_categories: ['thoracic'],
            professions: ['thoracic_surgeon'],
            cases: []
        },
        {
            id: 3,
            name: 'Cardiac surgery',
            demo_case_categories: ['cardiac'],
            professions: ['cardiac_surgeon'],
            cases: []
        },
        {
            id: 999,
            name: 'General',
            demo_case_categories: undefined,
            professions: undefined,
            cases: []
        },
    ]

    demoCases.forEach(demoCase => {
        const card = cards.find(card => card.demo_case_categories?.includes(demoCase.category)) || cards.find(card => card.demo_case_categories === undefined)
        card.cases.push(demoCase)
    })

    cards = cards.filter(card => card.cases.length > 0)
    
    
    const targetCardIndex = cards.findIndex((card) => card.professions?.includes(customer.profession)) || cards.findIndex(card => card.professions === undefined);
    if (targetCardIndex >= 0) {
        const element = cards.splice(targetCardIndex, 1)[0];
        cards.splice(0, 0, element);
    }

    const [activeCardID, setActiveCardID] = useState(cards[0]?.id)

    async function requestDemoCase(demo_case) {
      setLoading(true);
      try {
        await axios({
          method: "post",
          url: `${DEMO_CASE_URL}${demo_case.id}/request_share/`,
        });
        navigate("/shared-with-me");
      } catch (error) {
        addToast("error", "An error occurred. Please, try again.");
        setLoading(false);
      }
    }

    return (
        <div className="DemoCases h-100 d-flex flex-column" >
        {cards.length === 0
        ?
            <div className='d-flex flex-column w-100 justify-content-center align-items-center pt-5 mt-5'>
                <EmptyFolder />
                <span className="figma-h6-bold neutral-500 mt-4">No demo case available</span>
                <span className="figma-p1 neutral-800 mt-2 text-center">
                    There are no demo cases available at this time.
                </span>
            </div>
        :
            <>
                <div className='figma-p2-semibold primary-600 mb-3'>Choose the clinical area and get the demo case you prefer to visualize.</div>
                {cards.map(card => 
                    <CardBlock key={card.id} card={card} activeCardID={activeCardID} setActiveCardID={setActiveCardID} requestDemoCase={requestDemoCase} />
                )}
            </>
        }
        </div>
    );
}

function CardBlock({card, activeCardID, setActiveCardID, requestDemoCase}) {
    const isActiveCard = activeCardID === card.id
    return (
        <>
            <div className='d-flex justify-content-between align-items-center neutral-600 cursor-pointer p-3' onClick={() => {setActiveCardID(isActiveCard ? null : card.id)}}>
                <div className="figma-p1-semibold neutral-600">{card.name}</div>
                <FiChevronDown id='card-accordion-chevron' size="24px" className={'ms-2 primary-600' + (isActiveCard ? ' opened' : '')} />
            </div>
            <div className='d-flex flex-column justify-content-start'>
                <div id='card-accordion' className={isActiveCard ? 'opened' : ''}>
                    <div className='d-flex flex-column mb-3'>
                        {card.cases.map(demo_case =>
                            <DemoCase key={demo_case.id} demo_case={demo_case} requestDemoCase={requestDemoCase} />
                        )}
                    </div>
                </div>
            </div>
            <div className='light-horizontal-line'></div>
        </>
    )
}

function DemoCase({demo_case, requestDemoCase}) {
    const navigate = useNavigate()
    return (
        <>
            <Card className='demo-case-card mx-3 me-5 mb-2 p-3 overflow-auto'>
                <div className='d-flex'>
                    <div className='me-4 d-flex align-items-center'>
                        <div className='preview-image-container'><Image fluid src={demo_case.preview_132x76} className='m-auto' onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = no_preview }} /></div>
                    </div>
                    <div className='neutral-600 me-4'>
                        <div className='figma-p1-semibold mb-2'>{demo_case.title}</div>
                        <div className='figma-p1'>{demo_case.description}</div>
                    </div>
                    <div className='ms-auto me-4 d-flex align-items-center'>
                        <div className='check-circle-container text-center'>
                            {demo_case.caseshare_id && <FiCheckCircle size='24px' className='m-auto primary-600' />}
                        </div>
                    </div>
                    <div className='flex-shrink-0 d-flex align-items-center'>
                        {demo_case.caseshare_id
                            ? <Button variant='outline-primary' className='figma-caption-semibold py-2 px-3' onClick={() => {navigate(`/shared-with-me/${demo_case.caseshare_id}`)}}>Open demo case</Button>
                            : <Button className='figma-caption-semibold py-2 px-3' onClick={() => {requestDemoCase(demo_case)}}>Get demo case</Button>
                        }
                    </div>
                </div>
            </Card>
        </>
    )
}
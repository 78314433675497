import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { FiCalendar, FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import config from '../../../config';
import { ReactComponent as CustomSegmentationsIcon } from '../../../static/icons/custom_segmentations.svg';
import { ReactComponent as EmptyFolder } from '../../../static/images/empty_folder.svg';
import { getAvailableCustomSegmentations, getDateString } from '../../../utils/Common';
import { CASE_URL, CUSTOM_SEGMENTATIONS_TRACKING_URL, ECOMMERCE_CUSTOM_SEGMENTATIONS_URL } from '../../../utils/Endpoints';
import Loader from '../../../utils/widgets/Loader/Loader';
import Table from '../../../utils/widgets/Table/Table';
import '../Profile.scss';
import CustomSegmentationsModal from './CustomSegmentationsModal';

export default function CustomSegmentations({ license, enterprise_workspace, enterprise_member_detail }) {
    const is_free = license.plan === '0'
    const navigate = useNavigate()
    const [modalTitle, setModalTitle] = useState('')
    const [modalDescription, setModalDescription] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [sortBy, setSortBy] = useState([{ desc: true, id: 'request_date' }])
    const [loading, setLoading] = useState(false)
    const [emptyDiv, setEmptyDiv] = useState(false)
    const is_enterprise = license.plan === '3'

    const columns = useMemo(() => {
        let tmp_columns = [
            {
                Header: 'Case ID',
                accessor: 'case_id',
                Cell: cell => <span className="figma-p1-semibold primary-600">{cell.row.original.case_id} </span>,
                disableSortBy: true,
            },
            {
                Header: <><FiCalendar size='14px' className='me-2' />Date</>,
                accessor: 'request_date',
            },
            {
                Header: <><FiPlusCircle size='14px' className='me-2' />Clinical area</>,
                accessor: 'category',
                disableSortBy: true,
            },
            {
                Header: '',
                accessor: 'details',
                Cell: cell =>
                    <Button
                        type="button"
                        variant="link"
                        className="details-link d-flex justify-content-center align-items-center figma-p1-semibold"
                        onClick={async () => {
                            setLoading(true)
                            try {
                                const caseResponse = await axios({
                                    method: 'get',
                                    url: `${CASE_URL}${cell.row.original.case_id}/`
                                })
                                if (caseResponse.data.task_status === 'FAILURE') {
                                    setShowModal(true)
                                    setModalTitle('Segmentation failed')
                                    setModalDescription('The segmentation you are looking for has failed and is not available.')
                                    setLoading(false)
                                }
                                else if (caseResponse.data.task_status === 'PENDING') {
                                    setShowModal(true)
                                    setModalTitle('Segmentation processing')
                                    setModalDescription('The segmentation you are looking for is still in progress.')
                                    setLoading(false)
                                }
                                else if (caseResponse.data.task_status === 'SUCCESS') {
                                    navigate(`/my-cases/${cell.row.original.case_id}`)
                                }
                            }
                            catch (error) {
                                if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                                setShowModal(true)
                                setModalTitle('Segmentation no longer available')
                                setModalDescription('The segmentation you are looking for is no longer present in your cloud storage space.')
                                setLoading(false)
                            }
                        }}
                    >
                        DETAILS
                    </Button>,
                disableSortBy: true,
            },
        ]
        tmp_columns = tmp_columns.filter((col) => col.accessor !== 'owner')
        return tmp_columns
    }, [])

    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [loadingData, setLoadingData] = useState(false)

    const fetchData = useCallback(async ({ pageSize, pageIndex }) => {
        setLoadingData(true)
        setData([])
        let targetUrl = CUSTOM_SEGMENTATIONS_TRACKING_URL
        if (sortBy.length > 0) {
            const strSortBy = (sortBy[0].desc ? '-' : '') + sortBy[0].id
            targetUrl += `?ordering=${strSortBy}`
        }
        const requestConfig = {
            method: 'get',
            url: targetUrl,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            const casesData = response.data.results.map((obj) => {
                return {
                    case_id: obj.case_id,
                    request_date: getDateString(new Date(obj.request_date)),
                    category: obj.manual_case_category_label,
                }
            })
            setEmptyDiv(casesData.length === 0)
            setData(casesData)
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setData([])
        }
        setLoadingData(false)
    }, [sortBy])

    const custom_segmentations_dict = is_enterprise ? {} : getAvailableCustomSegmentations(license)
    const custom_segmentation_types = is_enterprise ? 0 : Object.entries(custom_segmentations_dict).length
    const total_custom_segmentations = is_enterprise ? enterprise_workspace.enterprise_custom_segmentations : Object.values(custom_segmentations_dict).reduce((a, b) => a + b, 0)
    const available_custom_segmentations = total_custom_segmentations > 0

    return (
        <div className='CustomSegmentations w-100 h-100'>
            <div className={'h-100 ' + (loading ? 'd-block' : 'd-none')}>
                <Loader />
            </div>
            <div className={"flex-column h-100 " + (loading ? 'd-none' : 'd-flex')} >
                <div className="d-flex align-items-center">
                    <CustomSegmentationsIcon size="24px" className="me-3 neutral-600 flex-shrink-0" />
                    <span className="figma-p2 neutral-600">{available_custom_segmentations ? "Available Custom Segmentations" : "No Custom Segmentations available"}</span>
                    {available_custom_segmentations && <span className="figma-p3-semibold primary-600 ms-3">{total_custom_segmentations}</span>}
                    {(!is_free && !is_enterprise) && <a href={ECOMMERCE_CUSTOM_SEGMENTATIONS_URL} target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-sm px-3 py-2 ms-4 figma-caption-semibold">{available_custom_segmentations ? "Add Custom Segmentations" : "Buy Custom Segmentations"}</a>}
                </div>
                {custom_segmentation_types > 0 &&
                    <div className="w-100 figma-caption subtext mt-2 neutral-600">
                        {
                            custom_segmentation_types > 1
                            ? (
                                Object.entries(custom_segmentations_dict).map(([key, value], index) => 
                                    <div key={index} className='mb-1 d-flex align-items-center'>
                                        <div className="available-custom-segmentation-key">{key}</div>
                                        <div className='figma-caption-semibold'>{value}</div>
                                    </div>
                                )
                            )
                            : <>Clinical area: <span className='figma-caption-semibold'>{Object.keys(custom_segmentations_dict)[0]}</span></>
                        }
                    </div>
                }
                {is_free &&
                    <> {
                        custom_segmentation_types > 0
                        ? <div className="w-100 subtext figma-caption mt-3 error-500">To use your custom segmetations you need to upgrade your license.</div>
                        : <div className="w-100 subtext figma-caption mt-2 neutral-600">Custom Segmentation cannot be purchased without having a <span className="figma-caption-semibold">Basic</span> or  <span className="figma-caption-semibold">Advanced</span> license.</div>
                    }
                    </>
                }
                {is_enterprise &&
                    <>
                        <div className="figma-caption mt-2 subtext neutral-600">
                            Custom Segmentations can be used by all team members.
                        </div>
                        {!available_custom_segmentations &&
                            <div className="figma-caption mt-2 subtext error-500">
                                {enterprise_member_detail.is_enterprise_manager
                                ?
                                <>To add more Custom Segmentations to your Enterprise License, please contact our <b>business support</b>.</>
                                :
                                <>To add more Custom Segmentations, contact the <b>account manager</b> of your Enterprise License.</>
                                }
                            </div>
                        }
                    </>
                }
                <div className='horizontal-line my-4'></div>
                <p className="figma-p3-semibold primary-600 mb-4">Segmentations History</p>
                {(emptyDiv)
                    ? <div className='d-flex flex-column h-100 w-100 justify-content-start align-items-center pt-5 mt-5'>
                        <EmptyFolder />
                        <span className="figma-h6-bold neutral-500 mt-4">No segmentations history available</span>
                        <span className="figma-p1 neutral-800 mt-2">Your account has not yet required any custom segmentation.</span>
                    </div>
                    : <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        loading={loadingData}
                        pageCount={pageCount}
                        dataCount={dataCount}
                        controlledPageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        manualSortBy={true}
                        initialSortBy={sortBy}
                        onChangeSort={setSortBy}
                        disableSortRemove={true}
                    />
                }
            </div>
            <CustomSegmentationsModal showModal={showModal} setShowModal={setShowModal} modalTitle={modalTitle} modalDescription={modalDescription} />
        </div>
    );
}

import React, { useEffect, useRef } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import sfondo from '../../../static/images/sfondo.svg';
import './SideTextureTemplate.scss';


export default function SideTextureTemplate(props) {
  const textureContainerRef = useRef(null)

  useEffect(() => {
    textureContainerRef.current.style =  'background-image: url(' + sfondo + ')';
  }, []);

  return (
    <Container fluid className='p-0 h-100'>
      <Row className={"SideTextureTemplate m-0 h-100 w-100"}>
        <Col sm="0" md="0" lg="6" className="p-0 d-none d-lg-block h-100">
          <div ref={textureContainerRef} className="texture-container w-100 h-100"></div>
        </Col>
        <Col sm="12" md="12" lg="6" className="bg-shades-0 overflow-auto h-100">
          <Row className="justify-content-center m-0 h-100">
            <Col sm="12" md="12" lg="8" className="mt-5 mt-md-5 mt-lg-2 form-container">
              <Row className="justify-content-center h-100 align-items-start align-items-lg-end">
                {props.children}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
import { useState } from "react";
import { Container, Image } from "react-bootstrap";
import './Mobile.scss'
import { useNavigate } from "react-router-dom";
import { getUser, removeUserSession } from "../../utils/Common";
import logo from '../../static/images/logo_verima.svg';
import store_android from '../../static/images/store_android.svg';
import store_ios from '../../static/images/store_ios.svg';
import {LOGOUT_URL, STORE_ANDROID_URL, STORE_IOS_URL} from '../../utils/Endpoints'
import axios from "axios";

export default function Mobile(props) {
    let navigate = useNavigate();

    let [user, setUser] = useState(getUser())

    function handleLogout() {
        axios({
          method: 'post',
          url: `${LOGOUT_URL}`,
        })
        setUser(null)
        removeUserSession();
        navigate('/login');
      }

    return (
        <Container fluid className="Mobile overflow-auto d-flex justify-content-center h-100">
            <div className={"main-row w-100 px-5 d-flex flex-column justify-content-start align-items-center text-center"}>
                <div className={"w-100 d-flex flex-column justify-content-center align-items-center text-center"}>
                    <Image alt="Verima Logo" src={logo} className="verima-logo w-100 mt-5 mb-4" />
                    <div className="figma-h3-semibold mb-3 primary-800">
                        Welcome back<br />{user.customer.first_name}!
                    </div>
                    <div className="figma-p1 mb-5 primary-800">
                        Use the Verima app to access the service from your smartphone.<br />
                        Download it from the store:
                    </div>
                    <a className="w-75 mb-4" href={STORE_ANDROID_URL} target='_blank' rel="noreferrer"><Image alt="Google play store" src={store_android} className="w-100"/></a>
                    <a className="w-75 mb-4" href={STORE_IOS_URL} target='_blank' rel="noreferrer"><Image alt="Apple app store" src={store_ios} className="w-100"/></a>
                </div>
                <div className="mt-auto mb-5 pb-3">
                    <span className="figma-p1 primary-800">Or if you want</span><br />
                    <button className='figma-p1-semibold primary-600 btn btn-link link-primary not-underlined p-0' onClick={() => {handleLogout()}}>LOGOUT</button>
                </div>
            </div>
        </Container>
    );
}
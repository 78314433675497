import { FieldAssociations } from "@kitware/vtk.js/Common/DataModel/DataSet/Constants";
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import { throttle } from "@kitware/vtk.js/macros";
import vtkAxesActorX from "./CustomAxesActorX";
import vtkAxesActorY from "./CustomAxesActorY";
import vtkAxesActorZ from "./CustomAxesActorZ";
import vtkPixelSpaceCallbackMapper from "@kitware/vtk.js/Rendering/Core/PixelSpaceCallbackMapper";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkProperty from "@kitware/vtk.js/Rendering/Core/Property";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkOpenGLRenderWindow from "@kitware/vtk.js/Rendering/OpenGL/RenderWindow";
import customInteractorStyle from "./CustomInteractorStyle";
import vtkOrientationMarkerWidget from "./CustomOrientationMarkerWidget";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  FiBox,
  FiChevronRight,
  FiEye,
  FiEyeOff,
  FiLayers,
  FiMinus,
  FiPlus,
  FiRotateCw,
  FiX,
} from "react-icons/fi";
import { IoFolderOpenOutline, IoFolderOutline } from "react-icons/io5";
import {
  addToast,
  convertToRGB,
  get8DigitHex,
  getColorInfo,
} from "../../utils/Common";
import { CASE_URL } from "../../utils/Endpoints";
import SaveModal from "./SaveModal";

export default function CaseModal({
  setShowCaseModal,
  stlFile,
  caseObj,
  fetchData,
  isMyCase,
}) {
  const layerinfo = caseObj.verinfo.layerinfo_set;
  const [groupedLayers, setGroupedLayers] = useState(
    layerinfo.reduce((groupedLayersAccumulator, layer, idx) => {
      if (layer.group) {
        if (!groupedLayersAccumulator[layer.group]) {
          groupedLayersAccumulator[layer.group] = {
            name: layer.group_name,
            layer_indexes: [idx],
            visible: true,
            opened: false,
          };
        } else {
          groupedLayersAccumulator[layer.group].layer_indexes.push(idx);
        }
      }
      return groupedLayersAccumulator;
    }, {})
  );
  const ungroupedLayersIndexes = layerinfo.reduce((list, layer, idx) => {
    if (!layer.group) {
      list.push(idx);
    }
    return list;
  }, []);

  const vtkContainerRef = useRef(null);
  const context = useRef(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [initialLayerAttributes, setInitialLayerAttributes] = useState(
    layerinfo.map((layer) => {
      return {
        id: layer.id,
        ...getColorInfo(layer.material_color),
        visible: true,
        name: layer.layer_name,
      };
    })
  );
  const [loading, setLoading] = useState(false);
  const [wasEdited, setWasEdited] = useState(false);
  const [layerAttributes, setLayerAttributes] = useState(
    initialLayerAttributes
  );
  const [targetInfoLayerIndex, setTargetInfoLayerIndex] = useState(-1);

  const [showLayerSettings, setShowLayerSettings] = useState(true);

  const resizeTimeout = useRef(null);
  const zoomInInterval = useRef(null);
  const zoomOutInterval = useRef(null);

  const hidePanelTimeout = useRef(null);

  useEffect(() => {
    function handleResize() {
      clearTimeout(resizeTimeout.current);
      resizeTimeout.current = setTimeout(() => {
        const renderer = context.current?.renderer;
        if (renderer) {
          const renderWindow = renderer.getRenderWindow();
          const openglRenderWindow = renderWindow.getViews()[0];
          const container = vtkContainerRef.current;
          openglRenderWindow.setContainer(container);
          const { width, height } = container.getBoundingClientRect();
          openglRenderWindow.setSize(width, height);

          const xAxis = context.current.xAxis;
          const yAxis = context.current.yAxis;
          const zAxis = context.current.zAxis;
          const orientationWidget = context.current.orientationWidget;
          const orientationRenderer = orientationWidget.getRenderer();
          const textCanvasX = document.getElementById("axis-x");
          const textCanvasY = document.getElementById("axis-y");
          const textCanvasZ = document.getElementById("axis-z");

          displayTextAxisCoordinate(
            xAxis,
            "X",
            container,
            orientationWidget,
            orientationRenderer,
            textCanvasX
          );

          displayTextAxisCoordinate(
            yAxis,
            "Y",
            container,
            orientationWidget,
            orientationRenderer,
            textCanvasY
          );

          displayTextAxisCoordinate(
            zAxis,
            "Z",
            container,
            orientationWidget,
            orientationRenderer,
            textCanvasZ
          );

          renderWindow.render();
        }
      }, 50);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  function displayTextAxisCoordinate(
    axis,
    letter,
    container,
    orientationWidget,
    orientationRenderer,
    textCanvas
  ) {
    const psMapper = vtkPixelSpaceCallbackMapper.newInstance();
    psMapper.setInputConnection(axis.getMapper().getInputConnection());
    let textCtx = null;
    const dims = container.getBoundingClientRect();
    const orientationWidgetCoorinates = orientationWidget.computeViewport();

    psMapper.setCallback((coordsList) => {
      if (textCtx && dims) {
        textCtx.clearRect(0, 0, dims.width, dims.height);
        textCtx.font = "18px Inter";
        textCtx.fillStyle = "#ffffff";
        textCtx.strokeStyle = "#ff0000";
        textCtx.textAlign = "center";
        textCtx.textBaseline = "middle";
        const xModifier = (coordsList[24][0] - coordsList[1][0]) * 0.25;
        const yModifier = (coordsList[24][1] - coordsList[1][1]) * 0.25;
        textCtx.fillText(
          letter,
          orientationWidgetCoorinates[0] * dims.width +
          coordsList[24][0] +
          xModifier,
          dims.height -
          (orientationWidgetCoorinates[1] * dims.height +
            coordsList[24][1] +
            yModifier)
        );
      }
    });
    const textActor = vtkActor.newInstance();
    textActor.setMapper(psMapper);

    orientationRenderer.addActor(textActor);

    textCanvas.setAttribute("width", dims.width);
    textCanvas.setAttribute("height", dims.height);
    textCanvas.setAttribute(
      "class",
      "text-canvas-position"
    );

    container.appendChild(textCanvas);

    textCtx = textCanvas.getContext("2d");
  }

  useEffect(() => {
    if (!context.current) {
      const files = Object.fromEntries(
        Object.entries(stlFile)
          .filter(([key, value]) => {
            return key.match(/Model\/./g);
          })
          .map(([key, value]) => {
            return [key.replace("Model/", ""), value];
          })
      );
      const mappers = [];
      const actors = [];

      layerinfo.forEach((layer) => {
        const { color_hex, opacity_percentage } = getColorInfo(
          layer.material_color
        );
        const color_rgb = convertToRGB(color_hex);
        const reader = vtkSTLReader.newInstance();
        const mapper = vtkMapper.newInstance({ scalarVisibility: false });
        const actor = vtkActor.newInstance();
        const property = vtkProperty.newInstance();
        property.setOpacity(opacity_percentage / 100);
        property.setColor(
          color_rgb[0] / 255,
          color_rgb[1] / 255,
          color_rgb[2] / 255
        );

        mapper.setInputConnection(reader.getOutputPort());
        actor.setProperty(property);
        actor.setMapper(mapper);
        reader.parseAsArrayBuffer(
          files[layer.file_name]
            ? files[layer.file_name].buffer
            : new ArrayBuffer(84)
        );
        mappers.push(mapper);
        actors.push(actor);
      });

      const renderWindow = vtkRenderWindow.newInstance();
      const renderer = vtkRenderer.newInstance({
        background: convertToRGB("#131825").map((val) => val / 255),
      });
      renderWindow.addRenderer(renderer);

      actors.forEach((actor) => {
        renderer.addActor(actor);
      });

      const xAxis = vtkAxesActorX.newInstance({
        pickable: true,
        config: { recenter: false },
      });
      const textCanvasX = document.createElement("canvas");
      textCanvasX.setAttribute("id", "axis-x");

      const yAxis = vtkAxesActorY.newInstance({
        pickable: true,
        config: { recenter: false },
      });

      const textCanvasY = document.createElement("canvas");
      textCanvasY.setAttribute("id", "axis-y");

      const zAxis = vtkAxesActorZ.newInstance({
        pickable: true,
        config: { recenter: false },
      });

      const textCanvasZ = document.createElement("canvas");
      textCanvasZ.setAttribute("id", "axis-z");

      const orientationWidget = vtkOrientationMarkerWidget.newInstance();

      const openglRenderWindow = vtkOpenGLRenderWindow.newInstance();
      renderWindow.addView(openglRenderWindow);

      const container = vtkContainerRef.current;
      openglRenderWindow.setContainer(container);
      const { width, height } = container.getBoundingClientRect();
      openglRenderWindow.setSize(width, height);

      const interactor = vtkRenderWindowInteractor.newInstance();
      interactor.setView(openglRenderWindow);
      interactor.initialize();
      interactor.setContainer(container);
      interactor.setInteractorStyle(customInteractorStyle.newInstance());

      const apiSpecificRenderWindow = interactor.getView();

      const orientationRenderer = orientationWidget.getRenderer();
      const xAxisProperty = vtkProperty.newInstance();
      const yAxisProperty = vtkProperty.newInstance();
      const zAxisProperty = vtkProperty.newInstance();
      xAxisProperty.setColor([1, 0, 0]);
      yAxisProperty.setColor([0, 1, 0]);
      zAxisProperty.setColor([0, 0, 1]);
      orientationWidget.setActor(xAxis);
      orientationRenderer.addActor(yAxis);
      orientationRenderer.addActor(zAxis);

      xAxis.setProperty(xAxisProperty);
      yAxis.setProperty(yAxisProperty);
      zAxis.setProperty(zAxisProperty);
      orientationWidget.setInteractor(interactor);
      orientationWidget.setEnabled(true);
      orientationWidget.setViewportSize(0.05);

      displayTextAxisCoordinate(
        xAxis,
        "X",
        container,
        orientationWidget,
        orientationRenderer,
        textCanvasX
      );

      displayTextAxisCoordinate(
        yAxis,
        "Y",
        container,
        orientationWidget,
        orientationRenderer,
        textCanvasY
      );

      displayTextAxisCoordinate(
        zAxis,
        "Z",
        container,
        orientationWidget,
        orientationRenderer,
        textCanvasZ
      );
      renderer.resetCamera();
      renderWindow.render();

      const hardwareSelector = apiSpecificRenderWindow.getSelector();
      hardwareSelector.setCaptureZValues(true);
      hardwareSelector.setFieldAssociation(
        FieldAssociations.FIELD_ASSOCIATION_CELLS
      );

      context.current = {
        renderer,
        actors,
        mappers,
        xAxis,
        yAxis,
        zAxis,
        orientationWidget,
      };
    }
    return () => {
      if (context.current) {
        const {
          renderer,
          actors,
          mappers,
          xAxis,
          yAxis,
          zAxis,
          orientationWidget,
        } = context.current;
        actors.forEach((actor) => {
          actor.delete();
        });
        mappers.forEach((mapper) => {
          mapper.delete();
        });
        xAxis.delete();
        yAxis.delete();
        zAxis.delete();
        orientationWidget.delete();
        renderer.delete();
        context.current = null;
      }
    };
  }, [context, vtkContainerRef, layerinfo, stlFile]);

  const toggleLayerVisibility = (idx) => {
    const targetVisibility = !context.current.actors[idx].getVisibility();
    context.current.actors[idx].setVisibility(targetVisibility);
    context.current.renderer.getRenderWindow().render();
    let tmpLayerAttributes = JSON.parse(JSON.stringify(layerAttributes));
    tmpLayerAttributes[idx].visible = targetVisibility;
    setLayerAttributes(tmpLayerAttributes);
  };

  const handleLayerOpacityChange = (e, idx) => {
    const layerProperty = context.current.actors[idx].getProperty();
    layerProperty.setOpacity(e.target.value / 100);
    context.current.renderer.getRenderWindow().render();
    let tmpLayerAttributes = JSON.parse(JSON.stringify(layerAttributes));
    tmpLayerAttributes[idx].opacity_percentage = parseInt(e.target.value);
    setLayerAttributes(tmpLayerAttributes);
  };

  const handleLayerNameChange = (e, idx) => {
    let tmpLayerAttributes = JSON.parse(JSON.stringify(layerAttributes));
    tmpLayerAttributes[idx].name = e.target.value;
    setLayerAttributes(tmpLayerAttributes);
  };

  const handleLayerColorChange = (e, idx) => {
    const color_rgb = convertToRGB(e.target.value);
    const layerProperty = context.current.actors[idx].getProperty();
    layerProperty.setColor(
      color_rgb[0] / 255,
      color_rgb[1] / 255,
      color_rgb[2] / 255
    );
    context.current.renderer.getRenderWindow().render();
    let tmpLayerAttributes = JSON.parse(JSON.stringify(layerAttributes));
    tmpLayerAttributes[idx].color_hex = e.target.value;
    setLayerAttributes(tmpLayerAttributes);
  };

  async function handleSave(exiting = true) {
    setLoading(true);
    var formData = new FormData();
    layerAttributes.forEach((layer, idx) => {
      formData.append(`verinfo.layerinfo_set[${idx}]id`, layer.id);
      formData.append(
        `verinfo.layerinfo_set[${idx}]material_color`,
        get8DigitHex(layer.color_hex, layer.opacity_percentage)
      );
      formData.append(
        `verinfo.layerinfo_set[${idx}]material_type`,
        layer.opacity_percentage === "100" ? "standard" : "transparent"
      );
      formData.append(`verinfo.layerinfo_set[${idx}]layer_name`, layer.name);
    });
    try {
      await axios({
        method: "patch",
        url: `${CASE_URL}${caseObj.id}/`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      addToast("success", "Your case has been updated successfully!", true);
      setWasEdited(true);
      if (exiting) {
        setShowCaseModal(false);
        setShowSaveModal(false);
        fetchData();
      } else {
        setInitialLayerAttributes(layerAttributes);
      }
    } catch (error) {
      addToast("error", "An error occurred during the case update.", true);
    } finally {
      setLoading(false);
    }
  }

  const changes = () => {
    const tmpInitialLayerAttributes = JSON.parse(
      JSON.stringify(initialLayerAttributes)
    );
    const tmpLayerAttributes = JSON.parse(JSON.stringify(layerAttributes));
    tmpInitialLayerAttributes.forEach((layer) => delete layer.visible);
    tmpLayerAttributes.forEach((layer) => delete layer.visible);
    const changes =
      JSON.stringify(tmpInitialLayerAttributes) !==
      JSON.stringify(tmpLayerAttributes);
    return changes;
  };

  const noErrors = () => {
    const emptyLayerNames = layerAttributes.some((layer) => !layer.name);
    return !emptyLayerNames;
  };

  const acceptable_changes = () => {
    return changes() && noErrors();
  };

  function eventToWindowXY(event) {
    const renderWindow = context.current.renderer.getRenderWindow();
    const interactor = renderWindow.getInteractor();
    const apiSpecificRenderWindow = interactor.getView();
    // We know we are full screen => window.innerXXX
    // Otherwise we can use pixel device ratio or else...
    const { clientX, clientY } = event;
    const [width, height] = apiSpecificRenderWindow.getSize();
    const x = Math.round((width * clientX) / window.innerWidth);
    const y = Math.round(height * (1 - clientY / window.innerHeight)); // Need to flip Y
    return [x, y];
  }

  function pickOnMouseEvent(event) {
    const renderer = context.current.renderer;
    const renderWindow = renderer.getRenderWindow();
    const interactor = renderWindow.getInteractor();
    const apiSpecificRenderWindow = interactor.getView();
    const hardwareSelector = apiSpecificRenderWindow.getSelector();
    if (interactor.isAnimating()) {
      // We should not do picking when interacting with the scene
      return;
    }
    const [x, y] = eventToWindowXY(event);

    hardwareSelector.getSourceDataAsync(renderer, x, y, x, y).then((result) => {
      if (result) {
        processSelections(result.generateSelection(x, y, x, y));
      } else {
        processSelections(null);
      }
    });
  }
  const throttleMouseHandler = throttle(pickOnMouseEvent, 20);

  function processSelections(selections) {
    const targetIndex = context.current.actors.indexOf(
      selections[0]?.getProperties().prop.getActors()[0]
    );
    manageInfoPanelByIndex(targetIndex);
  }

  function manageInfoPanelByIndex(targetIndex) {
    if (targetIndex === -1) {
      clearTimeout(hidePanelTimeout.current);
      hidePanelTimeout.current = setTimeout(() => {
        setTargetInfoLayerIndex(targetIndex);
      }, 1000);
    } else {
      clearTimeout(hidePanelTimeout.current);
      setTargetInfoLayerIndex(targetIndex);
    }
  }

  function resetPosition() {
    const renderer = context.current.renderer;
    renderer.resetCamera();
    const focalPoint = renderer.getActiveCamera().getFocalPoint();
    const position = renderer.getActiveCamera().getPosition();
    const dist = Math.sqrt(
      (position[0] - focalPoint[0]) ** 2 +
      (position[1] - focalPoint[1]) ** 2 +
      (position[2] - focalPoint[2]) ** 2
    );
    renderer
      .getActiveCamera()
      .setPosition(focalPoint[0], focalPoint[1], focalPoint[2] + dist);
    renderer.getActiveCamera().setViewUp(0.0, 1.0, 0.0);
    renderer.getRenderWindow().render();
  }

  function handleModalHide() {
    if (isMyCase && acceptable_changes()) {
      setShowSaveModal(true);
    } else {
      setShowCaseModal(false);
      wasEdited && fetchData();
    }
  }

  function zoomIn() {
    const renderer = context.current.renderer;
    renderer.getActiveCamera().dolly(1.1);
    renderer.resetCameraClippingRange();
    renderer.getRenderWindow().render();
  }

  function zoomInDown() {
    zoomIn();
    zoomInInterval.current = setInterval(zoomIn, 100);
  }

  function zoomInUp() {
    clearInterval(zoomInInterval.current);
  }

  function zoomOut() {
    const renderer = context.current.renderer;
    renderer.getActiveCamera().dolly(1 / 1.1);
    renderer.resetCameraClippingRange();
    renderer.getRenderWindow().render();
  }

  function zoomOutDown() {
    zoomOut();
    zoomOutInterval.current = setInterval(zoomOut, 100);
  }

  function zoomOutUp() {
    clearInterval(zoomOutInterval.current);
  }

  function toggleLayerSettingsOpen() {
    setShowLayerSettings(!showLayerSettings);
  }

  const toggleGroupVisibility = (group_id) => {
    const targetVisibility = !groupedLayers[group_id].visible;
    if (targetVisibility === false) {
      groupedLayers[group_id].layer_indexes.forEach((idx) =>
        context.current.actors[idx].setVisibility(false)
      );
    } else if (targetVisibility === true) {
      groupedLayers[group_id].layer_indexes.forEach((idx) =>
        context.current.actors[idx].setVisibility(layerAttributes[idx].visible)
      );
    }
    context.current.renderer.getRenderWindow().render();
    let tmpGroupedLayers = JSON.parse(JSON.stringify(groupedLayers));
    tmpGroupedLayers[group_id].visible = targetVisibility;
    setGroupedLayers(tmpGroupedLayers);
  };

  const toggleGroupOpened = (group_id) => {
    const targetOpened = !groupedLayers[group_id].opened;
    let tmpGroupedLayers = JSON.parse(JSON.stringify(groupedLayers));
    tmpGroupedLayers[group_id].opened = targetOpened;
    setGroupedLayers(tmpGroupedLayers);
  };

  // function screenshot() {
  //     context.current.renderer.getRenderWindow().captureImages()[0].then(
  //         (image) => { console.log(image); }
  //     );
  // }

  return (
    <>
      <Modal
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
        fullscreen={true}
        show={true}
        onHide={handleModalHide}
      >
        <Modal.Body id="case-modal">
          <div className="w-100 h-100 position-absolute pe-none z-index-99">
            <div className="top-left-panel">
              <div className="d-flex">
                <div className="name-panel figma-h5-small-semibold">
                  <div className="neutral-500 figma-p1-semibold d-flex align-items-center mb-1">
                    <FiBox size="20px" className="me-1" />
                    Case name
                  </div>
                  <span>{caseObj.verinfo.case_name}</span>
                </div>
              </div>
              {targetInfoLayerIndex > -1 && (
                <div className="info-panel neutral-300">
                  <div className="figma-p3-semibold">
                    {layerAttributes[targetInfoLayerIndex].name}
                  </div>
                  {layerinfo[targetInfoLayerIndex].metadata &&
                    Object.entries(
                      layerinfo[targetInfoLayerIndex].metadata
                    ).map(([key, value]) => {
                      return (
                        value !== null && (
                          <div key={key} className="figma-p1 my-2">
                            <span>{key}: </span>
                            <span className="shades-0">
                              {typeof value === "object"
                                ? JSON.stringify(value)
                                : value.toString()}
                            </span>
                          </div>
                        )
                      );
                    })}
                </div>
              )}
            </div>
            <div className="top-right-panel pe-auto">
              {isMyCase && (
                <Button
                  variant="light"
                  className="me-3"
                  disabled={loading || !acceptable_changes()}
                  onClick={() => {
                    handleSave(false);
                  }}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="secondary"
                      size="sm"
                      className="mx-2"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              )}
              <Button
                variant="outline-light"
                onClick={handleModalHide}
                title="Close viewer"
                className="square-icon-btn"
              >
                <FiX size="28px" />
              </Button>
            </div>

            <div className="zoom-panel-container d-flex justify-content-center">
              <div className="zoom-panel pe-auto d-flex align-items-center justify-content-center py-2 px-3">
                <span className="figma-p1-semibold user-select-none">Zoom</span>
                <Button
                  variant="outline-light"
                  className="square-icon-btn ms-3"
                  onTouchStart={zoomOutDown}
                  onMouseDown={zoomOutDown}
                  onMouseUp={zoomOutUp}
                  onMouseLeave={zoomOutUp}
                  onTouchEnd={zoomOutUp}
                >
                  <FiMinus size="28px" />
                </Button>
                <Button
                  variant="outline-light"
                  className="square-icon-btn ms-3"
                  onTouchStart={zoomInDown}
                  onMouseDown={zoomInDown}
                  onMouseUp={zoomInUp}
                  onMouseLeave={zoomInUp}
                  onTouchEnd={zoomInUp}
                >
                  <FiPlus size="28px" />
                </Button>
              </div>
            </div>

            <Button
              variant="outline-light"
              onClick={resetPosition}
              title="Reset position"
              className="pe-auto reset-position-button square-icon-btn p-2"
            >
              <FiRotateCw size="24px" />
            </Button>

            {showLayerSettings ? (
              <div className={"control-panel pe-auto opened pb-4"}>
                <div className="control-panel-header py-3 px-4 d-flex align-items-center user-select-none">
                  <FiLayers size="28px" className="me-3" />
                  <span className="figma-p3-semibold">Layers settings</span>
                  <FiX
                    onClick={toggleLayerSettingsOpen}
                    size="28px"
                    className="ms-auto cursor-pointer"
                  />
                </div>
                <div className="layer-list">
                  {Object.entries(groupedLayers).map(([group_id, group]) => (
                    <div
                      key={group_id}
                      className={
                        "layer-group" + (group.opened ? " opened" : "")
                      }
                    >
                      <div className="layer-group-header d-flex align-items-center neutral-50 mx-4 my-3">
                        <div
                          className="d-flex align-items-center cursor-pointer flex-grow-1 me-3"
                          onClick={() => toggleGroupOpened(group_id)}
                        >
                          <FiChevronRight
                            size="24px"
                            className="group-chevron flex-shrink-0"
                          />
                          <div className="ms-4 me-2">
                            <div className="neutral-500 figma-caption-semibold">
                              {group.opened ? (
                                <IoFolderOpenOutline size="20px" />
                              ) : (
                                <IoFolderOutline size="20px" />
                              )}
                              <span className="ms-2">Layer group</span>
                            </div>
                            <div className="figma-p2-semibold mt-1">
                              {group.name}
                            </div>
                          </div>
                        </div>
                        <div
                          className="eye-container"
                          title="Show/Hide"
                          onClick={() => {
                            toggleGroupVisibility(group_id);
                          }}
                        >
                          {group.visible ? (
                            <FiEye className="eye-icon" size="20px" />
                          ) : (
                            <FiEyeOff className="eye-icon" size="20px" />
                          )}
                        </div>
                      </div>
                      {group.opened && (
                        <div
                          className={
                            "layer-group-list" +
                            (!group.visible ? " hidden-group" : "")
                          }
                        >
                          {group.layer_indexes.map((idx) => (
                            <LayerControl
                              key={idx}
                              idx={idx}
                              layerAttributes={layerAttributes}
                              handleLayerColorChange={handleLayerColorChange}
                              handleLayerNameChange={handleLayerNameChange}
                              handleLayerOpacityChange={
                                handleLayerOpacityChange
                              }
                              toggleLayerVisibility={toggleLayerVisibility}
                              isGroupHidden={!group.visible}
                              manageInfoPanelByIndex={manageInfoPanelByIndex}
                              isMyCase={isMyCase}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {ungroupedLayersIndexes.map((idx) => (
                    <LayerControl
                      key={idx}
                      idx={idx}
                      layerAttributes={layerAttributes}
                      handleLayerColorChange={handleLayerColorChange}
                      handleLayerNameChange={handleLayerNameChange}
                      handleLayerOpacityChange={handleLayerOpacityChange}
                      toggleLayerVisibility={toggleLayerVisibility}
                      manageInfoPanelByIndex={manageInfoPanelByIndex}
                      isMyCase={isMyCase}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <Button
                variant="outline-light"
                onClick={toggleLayerSettingsOpen}
                title="Layer settings"
                className="pe-auto control-panel-button square-icon-btn p-2"
              >
                <FiLayers size="24px" />
              </Button>
            )}
          </div>
          <div className="h-100">
            <div
              className="h-100"
              ref={vtkContainerRef}
              onMouseMove={throttleMouseHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
      {showSaveModal && (
        <SaveModal
          handleSave={handleSave}
          setShowCaseModal={setShowCaseModal}
          setShowSaveModal={setShowSaveModal}
          fetchData={fetchData}
          wasEdited={wasEdited}
        />
      )}
    </>
  );
}

function LayerControl({
  idx,
  layerAttributes,
  handleLayerColorChange,
  handleLayerNameChange,
  handleLayerOpacityChange,
  toggleLayerVisibility,
  isGroupHidden,
  manageInfoPanelByIndex,
  isMyCase,
}) {
  return (
    <div
      className="layer-block"
      onMouseEnter={() => manageInfoPanelByIndex(idx)}
      onMouseLeave={() => manageInfoPanelByIndex(-1)}
    >
      <div className="d-flex align-items-start">
        <Form.Control
          size="sm"
          type="color"
          title="Change color"
          className="flex-shrink-0 dark-mode"
          value={layerAttributes[idx].color_hex}
          onChange={(e) => {
            handleLayerColorChange(e, idx);
          }}
        />
        <Form.Group className="ms-3 flex-grow-1">
          <Form.Control
            size="sm"
            type="text"
            className="dark-mode"
            title={layerAttributes[idx].name}
            value={layerAttributes[idx].name}
            onChange={(e) => {
              handleLayerNameChange(e, idx);
            }}
            disabled={!isMyCase}
            isInvalid={layerAttributes[idx].name.length === 0 ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            Layer name cannot be empty.
          </Form.Control.Feedback>
        </Form.Group>
        <div
          className="cursor-pointer ms-3 eye-container"
          title="Show/Hide"
          onClick={() => {
            !isGroupHidden && toggleLayerVisibility(idx);
          }}
        >
          {layerAttributes[idx].visible ? (
            <FiEye className="eye-icon" size="20px" />
          ) : (
            <FiEyeOff className="eye-icon" size="20px" />
          )}
        </div>
      </div>
      <div className="d-flex align-items-center mt-4 neutral-300">
        <span className="figma-p1-semibold">Opacity</span>
        <Form.Range
          className="ms-2 dark-mode"
          value={layerAttributes[idx].opacity_percentage}
          onChange={(e) => {
            handleLayerOpacityChange(e, idx);
          }}
        />
        <span className="ms-2 figma-p1 opacity-number flex-shrink-0 text-end">
          {layerAttributes[idx].opacity_percentage}%
        </span>
      </div>
    </div>
  );
}

import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, useOutletContext } from 'react-router-dom';
import config from '../../../config';
import { COOKIE_POLICY_URL, PRIVACY_POLICY_URL, REGISTRATION_STEP_THREE_URL, TERMS_OF_SERVICE_URL } from '../../../utils/Endpoints';
import Loader from "../../../utils/widgets/Loader/Loader";
import SideTextureTemplate from "../../../utils/widgets/SideTextureTemplate/SideTextureTemplate";
import QuitRegistrationModal from "./QuitRegistrationModal";
import { getRandomPWD } from "../../../utils/Common";

export default function RegistrationStepThree() {
  let navigate = useNavigate();
  const outletContext = useOutletContext()

  const [userType, setUserType] = useState(outletContext.isEnterprise ? "hc_professional" : "");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [surname, setSurname] = useState("");
  const [surnameError, setSurnameError] = useState(null);
  const [profession, setProfession] = useState("");
  const [professionError, setProfessionError] = useState(null);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [understand, setUnderstand] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const [verErrors, setVerErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [showModal, setShowModal] = useState(false)

  const professions = [
    {value:'general_surgeon', label:'General Surgeon'},
    {value:'urologist', label:'Urologist'},
    {value:'ophthalmologist', label:'Ophthalmologist'},
    {value:'vascular_surgeon', label:'Vascular Surgeon'},
    {value:'pediatric_surgeon', label:'Pediatric Surgeon'},
    {value:'maxillofacial_surgeon', label:'Maxillofacial Surgeon'},
    {value:'neurosurgeon', label:'Neurosurgeon'},
    {value:'orthopedic_surgeon', label:'Orthopedic Surgeon'},
    {value:'cardiac_surgeon', label:'Cardiac Surgeon'},
    {value:'thoracic_surgeon', label:'Thoracic Surgeon'},
    {value:'otolaryngologist', label:'Otolaryngologist'},
    {value:'abdominal_surgeon', label:'Abdominal Surgeon'},
    {value:'dermatologist', label:'Dermatologist'},
    {value:'anesthesiologist', label:'Anesthesiologist'},
    {value:'oncologist', label:'Oncologist'},
    {value:'gynecologist', label:'Gynecologist'},
    {value:'colorectal_surgeon', label:'Colorectal Surgeon'},
    {value:'hand_surgeon', label:'Hand Surgeon'},
    {value:'radiologist', label:'Radiologist'},
    {value:'dentist', label:'Dentist'},
    {value:'other', label:'Other'}
  ]

  useEffect(() => {
    const missingEmail = !outletContext.email || outletContext.email.length === 0
    const missingToken1 = !outletContext.token1 || outletContext.token1.length === 0
    const missingToken2 = !outletContext.token2 || outletContext.token2.length === 0
    if (missingEmail || missingToken1 || missingToken2) {
      navigate('/registration/step-one')
    }
  }, [])

  function cleanGenericErrors() {
    setNonFieldErrors([]);
    setVerErrors([]);
  }

  function cleanAllErrors() {
    setNameError(null)
    setSurnameError(null)
    setProfessionError(null)
    setUsernameError(null)
    setPasswordError(null)
    setConfirmPasswordError(null)
    cleanGenericErrors()
  }

  const handleUserTypeChange = (e) => {
    if (e.target.value === 'on') {
      const billingtype = e.target.attributes.billingtype.value
      setUserType(billingtype)
      setProfession(billingtype === 'hc_professional' ? professions[0].value : '')
      setProfessionError(null)
      cleanGenericErrors()
    }
  }

  const handlePasswordBlur = (e) => {
      const errors = []
      if (e.target.value) {
          if (!isNaN(e.target.value)) {
              errors.push('This password is entirely numeric.')
          }
          if (e.target.value.length < 8) {
              errors.push('This password is too short. It must contain at least 8 characters.')
          }
      }
      if (errors.length > 0) {
          setPasswordError(errors)
      }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true)
    cleanAllErrors();
    try {
      var formData = new FormData();
      formData.append("user_type", userType);
      formData.append("first_name", name);
      formData.append("last_name", surname);
      if (userType === 'hc_professional') {
        formData.append("profession", profession);
      }
      formData.append("username", username);
      formData.append("password1", password);
      formData.append("password2", confirmPassword);
      formData.append("token1", outletContext.token1);
      formData.append("token2", outletContext.token2);
      const response = await axios({
          method: 'post',
          url: REGISTRATION_STEP_THREE_URL,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
      })
      navigate("/registration/completed");
      if (config.SHOULD_LOG) {console.log('response >>> ', JSON.stringify(response));}
    }
    catch (error) {
      if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
      const responseData = error.response?.data
      if (responseData) {
        if (responseData.error && responseData.error === 'step_3_failed') {
          navigate("/registration/failed");
        }
        else {
          setNameError(responseData.first_name);
          setSurnameError(responseData.last_name);
          setUsernameError(responseData.username);
          setProfessionError(responseData.profession);
          setPasswordError(responseData.password1);
          setConfirmPasswordError(responseData.password2);
          setNonFieldErrors(responseData.non_field_errors || []);
          setVerErrors(responseData.ver_error || []);
          setLoading(false)
        }
      }
      else {
        setLoading(false)
      }
    }
  }

  function buttonEnabled() {
    const errors = nameError || surnameError || usernameError || passwordError || confirmPasswordError || (userType === 'hc_professional' && professionError)
    const noEmptyRequiredFields = name.length > 0 && surname.length > 0 && username.length > 0 && password.length > 0 && confirmPassword.length > 0 && userType.length > 0 && (userType === 'hc_professional' ? profession.length > 0 : true)
    return noEmptyRequiredFields && nonFieldErrors.length === 0 && verErrors.length === 0 && !errors && understand;
  }

  function randomEnabled() {
    return password.length === 0 && confirmPassword.length === 0
  }

  function createRandomPassword() {
    const rpwd = getRandomPWD()
    setPassword(rpwd)
    setConfirmPassword(rpwd)
    setVisiblePassword(true)
    setVisibleConfirmPassword(true)
    setPasswordError(null)
    setConfirmPasswordError(null)
    cleanGenericErrors()
  }

  return (
    <SideTextureTemplate>
      {loading
        ? <Loader />
        : <><Form onSubmit={handleSubmit} className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
            <div className="d-block figma-h4-semibold text-center primary-600 mb-4">
              Enter your personal information
            </div>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                autoFocus
                size='lg'
                type="text"
                placeholder="Your name"
                value={name}
                onChange={(e) => { setName(e.target.value); setNameError(null); cleanGenericErrors() }}
                isInvalid={(nameError) ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="surname">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                size='lg'
                type="text"
                placeholder="Your surname"
                value={surname}
                onChange={(e) => { setSurname(e.target.value); setSurnameError(null); cleanGenericErrors() }}
                isInvalid={(surnameError) ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {surnameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                disabled={true}
                size='lg'
                type="email"
                placeholder="Your e-mail"
                value={outletContext.email}
              />
            </Form.Group>
            {!outletContext.isEnterprise &&
              <>
                <div className="d-block figma-p1 neutral-700 mt-3 mb-2">
                  Select the type of user:
                </div>
                <div className='d-flex mb-3'>
                    <Form.Check
                        id="patientUserType"
                        billingtype="patient"
                        checked={userType === 'patient'}
                        label={<span>Patient</span>}
                        type='radio'
                        name="userType"
                        className='form-check-container d-flex align-items-center figma-p1 neutral-900 me-4'
                        onChange={handleUserTypeChange}
                    />
                    <Form.Check
                        id="hcprofessionalBillingType"
                        billingtype="hc_professional"
                        checked={userType === 'hc_professional'}
                        label={<span>Healthcare professional</span>}
                        type='radio'
                        name="userType"
                        className='form-check-container d-flex align-items-center figma-p1 neutral-900'
                        onChange={handleUserTypeChange}
                    />
                </div>
              </>
            }
            {(userType === 'hc_professional') &&
              <Form.Group controlId="profession" className="mt-3">
                <Form.Label>Profession</Form.Label>
                <div className='select-container mb-4 neutral-800 mb-3'>
                  <Form.Select
                    size="lg"
                    placeholder='Select an option'
                    aria-invalid={(professionError) ? true : false}
                    aria-errormessage='profession-error'
                    onChange={e => {
                      setProfession(e.target.value)
                      setProfessionError(null)
                      cleanGenericErrors()
                    }}
                    value={profession}
                  >
                    {professions.map((option, idx) => {
                      return <option key={idx} value={option.value}>{option.label}</option>
                    })}
                  </Form.Select>
                  {professionError &&
                    <span className="figma-caption error-500 mt-1" id='profession-error'>
                      {professionError}
                    </span>
                  }
                </div>
              </Form.Group>
            }
            <div className="horizontal-line my-4"></div>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                size='lg'
                type="text"
                placeholder="Choose your login username"
                value={username}
                onChange={(e) => { setUsername(e.target.value); setUsernameError(null); cleanGenericErrors() }}
                isInvalid={(usernameError) ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {usernameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <div className="d-flex">
                  <Form.Label>Password</Form.Label>
                  <>
                    {randomEnabled()
                    ? <div className='ms-auto figma-p1-semibold not-underlined primary-600 cursor-pointer' onClick={() => createRandomPassword()}>Suggest strong password</div>
                    : <OverlayTrigger
                          placement={"bottom"}
                          overlay={
                              <Popover className="">
                                  <PopoverBody className="py-1">
                                      Clear password fields<br/>to enable this feature
                                  </PopoverBody>
                              </Popover>
                          }
                      >
                        <div className='ms-auto figma-p1-semibold not-underlined neutral-400'>Suggest strong password</div>
                      </OverlayTrigger>
                    }
                  </>
              </div>
              <Form.Control
                  size='lg'
                  type={visiblePassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Insert password"
                  value={password}
                  className="d-inline password-input"
                  onChange={(e) => { setPassword(e.target.value); setPasswordError(null); cleanGenericErrors() }}
                  onBlur={(e) => { handlePasswordBlur(e) }}
                  isInvalid={(passwordError) ? true : false}
              />
              {visiblePassword ? <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisiblePassword(true) }><FiEye size="20px" className='password-icon' /></div>}
              <Form.Control.Feedback type="invalid">{passwordError && passwordError.length && passwordError.map((error, idx) => <div key={idx}>{error}</div>)}</Form.Control.Feedback>
              <ul className="ps-3 mt-2 figma-caption neutral-600">
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can't be entirely numeric.</li>
                  <li>Your password can't be too similar to other data entered.</li>
                  <li>Your password can't be a commonly used password.</li>
              </ul>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                  size='lg'
                  type={visibleConfirmPassword ? 'text' : 'password'}
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  className="d-inline password-input"
                  onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError(null); cleanGenericErrors() }}
                  isInvalid={(confirmPasswordError) ? true : false}
              />
              {visibleConfirmPassword ? <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(true) }><FiEye size="20px" className='password-icon' /></div>}
              <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
            </Form.Group>
            <div className="nonfield-errors mt-1 w-100">
              {nonFieldErrors.map((error) => {
                return (error === 'password_mismatch')
                  ? <p key={error}>The two password fields didn't match.</p>
                  : <p key={error}>{error}</p>
                }
              )}
            </div>
            <div className="nonfield-errors mt-1 w-100 mt-3 primary-600">
              {verErrors.map((error) =>
                <p key={error.code}>{error.msg}</p>
              )}
            </div>
            <Form.Check
                id='understand-check'
                checked={understand}
                label={<span>I have read and I agree to <a href={TERMS_OF_SERVICE_URL} target='_blank' rel='noopener noreferrer'>terms of service</a> and the <a href={PRIVACY_POLICY_URL} target='_blank' rel="noopener noreferrer">privacy</a> and <a href={COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">cookie policy</a>.</span>}
                type='checkbox'
                className='form-check-container d-flex align-items-center figma-p1 neutral-600 mt-3'
                onChange={(e) => { setUnderstand(e.target.checked) }}
                onClick={(e) => { e.target.blur() }}
            />
            <div className="d-grid my-4">
              <Button variant="primary" size="lg" type="submit" disabled={!buttonEnabled()}>
                  <span className="figma-p2-semibold">Complete registration</span>
              </Button>
            </div>
            {!outletContext.skipStepOne &&
              <div className="d-flex justify-content-center align-items-center figma-p1 text-center neutral-700 my-4">
                To quit the registration process,&nbsp;<Button variant='link' className='p-0 figma-p1 primary-600' onClick={(e) => {setShowModal(true); e.target.blur()}}>click here</Button>.
              </div>
            }
            <QuitRegistrationModal showModal={showModal} setShowModal={setShowModal} />
          </Form>
          <div></div>
        </>
      }
    </SideTextureTemplate>
  );
}
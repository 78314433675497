import axios from "axios";
import { useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Popover, PopoverBody, Row } from 'react-bootstrap';
import { FiEye, FiEyeOff, FiUserX } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import { addToast, capitalizeFirstLetter, getDateString, getRandomPWD, removeUnderscores, removeUserSession } from "../../../utils/Common";
import { CHANGE_PASSWORD_URL, CUSTOMER_URL, LOGOUT_URL, WEBSITE_CONTACT_US_URL } from '../../../utils/Endpoints';
import Loader from "../../../utils/widgets/Loader/Loader";
import '../Profile.scss';

export default function PersonalInformations({ customer, fetchData, license, enterprise_member_detail }) {
    const [showModal, setShowModal] = useState(false)
    const registration_completion_date = new Date(customer.registration_completion_date)
    const [isEditingProfileInfo, setIsEditingProfileInfo] = useState(false)
    const [isEditingUsername, setIsEditingUsername] = useState(false)
    const [isEditingPassword, setIsEditingPassword] = useState(false)

    return (
        <div className="PersonalInformations h-100 d-flex flex-column">
            <div className="short-container">
                <div>
                    <div className="figma-p3-semibold primary-600 mb-3">Profile</div>
                    {isEditingProfileInfo
                        ? <ProfileInfoForm customer={customer} fetchData={fetchData} setIsEditingProfileInfo={setIsEditingProfileInfo} />
                        : <>
                            <div className="d-flex justify-content-between align-items-start mb-4">
                                <div>
                                    <div>
                                        <span className="figma-p1-semibold neutral-800 me-1">Name:</span>
                                        <span className="figma-p1 neutral-600">{customer.first_name}</span>
                                    </div>
                                    <div>
                                        <span className="figma-p1-semibold neutral-800 me-1">Surname:</span>
                                        <span className="figma-p1 neutral-600">{customer.last_name}</span>
                                    </div>
                                </div>
                                {!isEditingUsername && !isEditingPassword &&
                                    <Button variant='link' className='p-0 primary-600 figma-p1-semibold not-underlined text-nowrap ms-3' onClick={() => { setIsEditingProfileInfo(true) }}>Change profile info</Button>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className='horizontal-line mb-4'></div>
                <div>
                    <div className="figma-p3-semibold primary-600 mb-3">Login credentials</div>
                    {isEditingUsername
                        ? <UsernameForm customer={customer} fetchData={fetchData} setIsEditingUsername={setIsEditingUsername} />
                        : <>
                            <div className="d-flex justify-content-between align-items-start">
                                <div>
                                    <span className="figma-p1-semibold neutral-800 me-1">Username:</span>
                                    <span className="figma-p1 neutral-600">{customer.username}</span>
                                </div>
                                {!isEditingProfileInfo && !isEditingPassword &&
                                    <Button variant='link' className='p-0 primary-600 figma-p1-semibold not-underlined' onClick={() => { setIsEditingUsername(true) }}>Change username</Button>
                                }
                            </div>
                        </>
                    }
                    {isEditingPassword
                        ? <PasswordForm customer={customer} fetchData={fetchData} setIsEditingPassword={setIsEditingPassword} />
                        : <>
                            <div className="d-flex justify-content-between align-items-start mt-2">
                                <div>
                                    <span className="figma-p1-semibold neutral-800 me-1">Password:</span>
                                    <span className="figma-p1 neutral-600">{<>&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;</>}</span>
                                </div>
                                {!isEditingUsername && !isEditingProfileInfo &&
                                    <Button variant='link' className='p-0 primary-600 figma-p1-semibold not-underlined' onClick={() => { setIsEditingPassword(true) }}>Change password</Button>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className='horizontal-line my-4'></div>
                <div>
                    <div className="figma-p3-semibold primary-600 mb-3">Account information</div>
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">E-mail:</span>
                        <span className="figma-p1 neutral-600">{customer.email}</span>
                    </div>
                    {customer.user_type &&
                        <>
                            <>{customer.user_type === 'hc_professional' &&
                                <>
                                    <div>
                                        <span className="figma-p1-semibold neutral-800 me-1">User type:</span>
                                        <span className="figma-p1 neutral-600">Healthcare professional</span>
                                    </div>
                                    <div>
                                        <span className="figma-p1-semibold neutral-800 me-1">Clinical field:</span>
                                        <span className="figma-p1 neutral-600">{capitalizeFirstLetter(removeUnderscores(customer.profession))}</span>
                                    </div>
                                </>}
                            </>
                            <>{customer.user_type === 'patient' &&
                                <>
                                    <div>
                                        <span className="figma-p1-semibold neutral-800 me-1">User type:</span>
                                        <span className="figma-p1 neutral-600">Patient</span>
                                    </div>
                                </>}
                            </>
                        </>
                    }
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">Account created on:</span>
                        <span className="figma-p1 neutral-600">{getDateString(registration_completion_date)}</span>
                    </div>
                    {!isEditingProfileInfo && !isEditingUsername && !isEditingPassword && 
                        <div className="mt-4">
                            <button className='figma-p2 btn btn-link btn-link-error p-0' onClick={() => {setShowModal(true)}}>Delete account</button>
                            <DeleteAccountModal key={Date.now()} showModal={showModal} setShowModal={setShowModal} customer={customer} license={license} enterprise_member_detail={enterprise_member_detail} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function ProfileInfoForm({customer, fetchData, setIsEditingProfileInfo}) {
    const [editingCustomer, setEditingCustomer] = useState(customer)
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);
    
    const handleFirstNameChange = (e) => {
        if (!e.target.value) {
            setFirstNameError('You must enter a name.')
        }
        else {
            setFirstNameError(null)
        }
        setEditingCustomer({
            ...editingCustomer,
            'first_name': e.target.value,
        })
    };

    const handleLastNameChange = (e) => {
        if (!e.target.value) {
            setLastNameError('You must enter a surname.')
        }
        else {
            setLastNameError(null)
        }
        setEditingCustomer({
            ...editingCustomer,
            'last_name': e.target.value,
        })
    };

    const saveButtonEnabled = () => {
        const changes = JSON.stringify(customer) !== JSON.stringify(editingCustomer)
        const emptyFirstName = !editingCustomer.first_name
        const emptyLastName = !editingCustomer.last_name
        const errors = firstNameError || lastNameError || nonFieldErrors.length > 0
        return changes && !emptyFirstName && !emptyLastName && !errors
    }

    const handleSave = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("first_name", editingCustomer.first_name);
        formData.append("last_name", editingCustomer.last_name);
        try {
            const response = await axios({
                method: 'patch',
                url: `${CUSTOMER_URL}${customer.id}/`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            addToast('success', 'Your personal information have been saved.', true);
            setIsEditingProfileInfo(false);
            await fetchData()
        }
        catch (error) {
            if (error.response?.data) {
                setFirstNameError(error.response.data.first_name);
                setLastNameError(error.response.data.last_name);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    return (
        <Form onSubmit={handleSave} className="col-12">
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={editingCustomer.first_name}
                    onChange={(e) => { handleFirstNameChange(e) }}
                    isInvalid={(firstNameError) ? true : false}
                />
                <Form.Control.Feedback type="invalid">{firstNameError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={editingCustomer.last_name}
                    onChange={(e) => { handleLastNameChange(e) }}
                    isInvalid={(lastNameError) ? true : false}
                />
                <Form.Control.Feedback type="invalid">{lastNameError}</Form.Control.Feedback>
            </Form.Group>
            <div className="nonfield-errors mt-1 w-100">
                {nonFieldErrors.map((error) =>
                    <p key={error}>{error}</p>
                )}
            </div>
            <div className="nonfield-errors mt-1 w-100">
                {verErrors.map((error) =>
                    <p key={error.code}>{error.msg}</p>
                )}
            </div>
            <div className="d-flex justify-content-end my-4">
                <Button variant="outline-primary" size="sm" className="figma-caption-semibold py-2 px-3 me-4" onClick={() => {setIsEditingProfileInfo(false)}}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" type="submit" className="figma-caption-semibold py-2 px-3" disabled={!saveButtonEnabled()}>
                    Save changes
                </Button>
            </div>
        </Form>
    )
}

function UsernameForm({customer, fetchData, setIsEditingUsername}) {
    const [editingCustomer, setEditingCustomer] = useState(customer)
    const [usernameError, setUsernameError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);
    
    const handleUsernameChange = (e) => {
        if (!e.target.value) {
            setUsernameError('You must enter a username')
        }
        else {
            setUsernameError(null)
        }
        setEditingCustomer({
            ...editingCustomer,
            'username': e.target.value,
        })
    };

    const saveButtonEnabled = () => {
        const changes = JSON.stringify(customer) !== JSON.stringify(editingCustomer)
        const emptyUsername = !editingCustomer.username
        const errors = usernameError || nonFieldErrors.length > 0
        return changes && !emptyUsername && !errors
    }

    const handleSave = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("username", editingCustomer.username);
        try {
            const response = await axios({
                method: 'patch',
                url: `${CUSTOMER_URL}${customer.id}/`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            addToast('success', 'Your personal information have been saved.', true);
            setIsEditingUsername(false);
            await fetchData()
        }
        catch (error) {
            if (error.response?.data) {
                setUsernameError(error.response.data.username);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    return (
        <Form onSubmit={handleSave} className="col-12">
            <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    name="username"
                    id="username"
                    value={editingCustomer.username}
                    onChange={(e) => { handleUsernameChange(e) }}
                    isInvalid={(usernameError) ? true : false}
                />
                <Form.Control.Feedback type="invalid">{usernameError}</Form.Control.Feedback>
            </Form.Group>
            <div className="nonfield-errors mt-1 w-100">
                {nonFieldErrors.map((error) =>
                    <p key={error}>{error}</p>
                )}
            </div>
            <div className="nonfield-errors mt-1 w-100">
                {verErrors.map((error) =>
                    <p key={error.code}>{error.msg}</p>
                )}
            </div>
            <div className="d-flex justify-content-end mt-4">
                <Button variant="outline-primary" size="sm" className="figma-caption-semibold py-2 px-3 me-4" onClick={() => {setIsEditingUsername(false)}}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" type="submit" className="figma-caption-semibold py-2 px-3" disabled={!saveButtonEnabled()}>
                    Save changes
                </Button>
            </div>
        </Form>
    )
}

function PasswordForm({customer, fetchData, setIsEditingPassword}) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState(null);
    const [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [visibleNewPassword, setVisibleNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);
    
    const handleCurrentPasswordChange = (e) => {
        setCurrentPasswordError(null)
        setNonFieldErrors([])
        setVerErrors([])
        setCurrentPassword(e.target.value)
    };
    
    const handleNewPasswordChange = (e) => {
        setNewPasswordError(null)
        setNonFieldErrors([])
        setVerErrors([])
        setNewPassword(e.target.value)
    };
    
    
    const handleNewPasswordBlur = (e) => {
        const errors = []
        if (e.target.value) {
            if (!isNaN(e.target.value)) {
                errors.push('This password is entirely numeric.')
            }
            if (e.target.value.length < 8) {
                errors.push('This password is too short. It must contain at least 8 characters.')
            }
        }
        if (errors.length > 0) {
            setNewPasswordError(errors)
        }
    };
    
    const handleConfirmPasswordChange = (e) => {
        setConfirmPasswordError(null)
        setNonFieldErrors([])
        setVerErrors([])
        setConfirmPassword(e.target.value)
    };

    const saveButtonEnabled = () => {
        const errors = currentPasswordError || newPasswordError || confirmPasswordError || nonFieldErrors.length > 0
        return currentPassword && newPassword && confirmPassword && !errors
    }

    const handleSave = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("old_password", currentPassword);
        formData.append("password", newPassword);
        formData.append("password2", confirmPassword);
        try {
            const response = await axios({
                method: 'patch',
                url: `${CHANGE_PASSWORD_URL}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            addToast('success', 'Your personal information have been saved.', true);
            setIsEditingPassword(false);
            await fetchData()
        }
        catch (error) {
            if (error.response?.data) {
                setCurrentPasswordError(error.response.data.old_password);
                setNewPasswordError(error.response.data.password);
                setConfirmPasswordError(error.response.data.password2);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    function randomEnabled() {
      return newPassword.length === 0 && confirmPassword.length === 0
    }

    function createRandomPassword() {
      const rpwd = getRandomPWD()
      setNewPassword(rpwd)
      setConfirmPassword(rpwd)
      setVisibleNewPassword(true)
      setVisibleConfirmPassword(true)
      setNewPasswordError(null)
      setNonFieldErrors([]);
      setVerErrors([]);
    }

    return (
        <Form onSubmit={handleSave} className="col-12 mt-4">
            <Form.Group className="mb-4">
                <Form.Label>Current password</Form.Label>
                <Form.Control
                    type={visibleCurrentPassword ? 'text' : 'password'}
                    name="currentpassword"
                    id="currentpassword"
                    placeholder="Your current password"
                    autoComplete="current-password"
                    value={currentPassword}
                    className="d-inline password-input"
                    onChange={(e) => { handleCurrentPasswordChange(e) }}
                    isInvalid={(currentPasswordError) ? true : false}
                />
                {visibleCurrentPassword ? <div className='password-icon-container d-inline' onClick={() => setVisibleCurrentPassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisibleCurrentPassword(true) }><FiEye size="20px" className='password-icon' /></div>}
                <Form.Control.Feedback type="invalid">{currentPasswordError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
                <div className="d-flex">
                    <Form.Label>Insert new password:</Form.Label>
                    <>
                    {randomEnabled()
                    ? <div className='ms-auto figma-p1-semibold not-underlined primary-600 cursor-pointer' onClick={() => createRandomPassword()}>Suggest strong password</div>
                    : <OverlayTrigger
                            placement={"bottom"}
                            overlay={
                                <Popover className="">
                                    <PopoverBody className="py-1">
                                        Clear password fields<br/>to enable this feature
                                    </PopoverBody>
                                </Popover>
                            }
                        >
                        <div className='ms-auto figma-p1-semibold not-underlined neutral-400'>Suggest strong password</div>
                        </OverlayTrigger>
                    }
                    </>
                </div>
                <Form.Control
                    type={visibleNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    id="newPassword"
                    placeholder="Your new password"
                    autoComplete="new-password"
                    value={newPassword}
                    className="d-inline password-input"
                    onChange={(e) => { handleNewPasswordChange(e) }}
                    onBlur={(e) => { handleNewPasswordBlur(e) }}
                    isInvalid={(newPasswordError) ? true : false}
                />
                {visibleNewPassword ? <div className='password-icon-container d-inline' onClick={() => setVisibleNewPassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisibleNewPassword(true) }><FiEye size="20px" className='password-icon' /></div>}
                <Form.Control.Feedback type="invalid">{newPasswordError && newPasswordError.length && newPasswordError.map(error => <div>{error}</div>)}</Form.Control.Feedback>
                <ul className="ps-3 mt-2 figma-caption neutral-600">
                    <li>Your password must contain at least 8 characters.</li>
                    <li>Your password can't be entirely numeric.</li>
                    <li>Your password can't be too similar to other data entered.</li>
                    <li>Your password can't be a commonly used password.</li>
                </ul>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Confirm new password</Form.Label>
                <Form.Control
                    type={visibleConfirmPassword ? 'text' : 'password'}
                    name="confirmpassword"
                    id="confirmpassword"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    className="d-inline password-input"
                    onChange={(e) => { handleConfirmPasswordChange(e) }}
                    isInvalid={(confirmPasswordError) ? true : false}
                />
                {visibleConfirmPassword ? <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(false) }><FiEyeOff size="20px" className='password-icon' /></div> : <div className='password-icon-container d-inline' onClick={() => setVisibleConfirmPassword(true) }><FiEye size="20px" className='password-icon' /></div>}
                <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
            </Form.Group>
            <div className="nonfield-errors mt-1 w-100">
                {nonFieldErrors.map((error) =>
                    <p key={error}>{error}</p>
                )}
            </div>
            <div className="nonfield-errors mt-1 w-100">
                {verErrors.map((error) =>
                    <p key={error.code}>{error.msg}</p>
                )}
            </div>
            <div className="d-flex justify-content-end mt-4">
                <Button variant="outline-primary" size="sm" className="figma-caption-semibold py-2 px-3 me-4" onClick={() => {setIsEditingPassword(false)}}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" type="submit" className="figma-caption-semibold py-2 px-3" disabled={!saveButtonEnabled()}>
                    Save changes
                </Button>
            </div>
        </Form>
    )
}

function DeleteAccountModal({showModal, setShowModal, customer, license, enterprise_member_detail}) {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const is_enterprise = license.plan === '3'
    const is_enterprise_manager = is_enterprise && enterprise_member_detail.is_enterprise_manager

    const deleteAccount = async () => {
        setLoading(true)
        let checkpointReached = false
        try {
            await axios({
                method: 'delete',
                url: `${CUSTOMER_URL}${customer.id}/`
            })
            checkpointReached = true
            removeUserSession();
            navigate('/login');
            addToast('success', 'Account deleted successfully.')
        }
        catch (error) {
            addToast('error', 'Account deletion failed.')
            setLoading(false)
            setShowModal(false)
        }
        if (checkpointReached) {
            axios({
                method: 'post',
                url: `${LOGOUT_URL}`,
            })
        }
    }

    return (
        <Modal size={(is_enterprise && is_enterprise_manager) ? 'sm' : 'md'} centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body id="delete-case-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiUserX className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">Delete your account?</p>
                    </Col>
                    {is_enterprise
                        ?
                        <Col md={12}>
                            <p className="figma-p1 neutral-500 text-center">
                                    <>{is_enterprise_manager
                                        ?
                                            <>To delete your account, you must contact our <b>Technical support</b>.</>
                                        :
                                            <>Before deleting your account, you must be removed from the workspace by your <b>account manager</b>.</>
                                        }
                                    </>
                            </p>
                        </Col>
                        :
                        <>
                            <Col md={12}>
                                <p className="figma-p1 neutral-500 text-start">
                                    By proceeding you <b>immediately lose</b> the possibility to access the Verima service again with this account.<br /><br />
                                    All your clinical cases still present on our cloud will be <b>permanently deleted</b>. You will also lose access to all cases shared with you.<br /><br />
                                    If your account is linked to a paid license, this <b>will not be refunded</b> in any case.<br /><br />
                                    The remaining available custom segmentation requests <b>will not be refunded</b>.<br /><br />
                                    All personal data linked to your account will be <b>deleted</b>.
                                </p>
                            </Col>
                            <Col md={12}>
                                <div className="neutral-600 text-start my-3">
                                    <Form.Check
                                        id='delete-understand-check'
                                        type='checkbox'
                                        className='me-2 form-check-container d-flex align-items-center justify-content-center figma-p1 neutral-600'
                                        label={<span>I understand all.</span>}
                                        checked={checkboxChecked}
                                        onChange={(e) => { setCheckboxChecked(e.target.checked) }}
                                        onClick={(e) => { e.target.blur() }}
                                    />
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer className="container bg-neutral-50">
                <div className="row w-100 justify-content-between">
                    {is_enterprise
                        ?
                            <>{is_enterprise_manager
                                ?
                                    <>
                                        <div  className="col">
                                            <Button variant="outline-primary" className="w-100" onClick={(e) => {setShowModal(false); e.target.blur()}}>Cancel</Button>
                                        </div>
                                        <div  className="col">
                                            <a className="btn btn-primary w-100" href={WEBSITE_CONTACT_US_URL} target="_blank" rel="noreferrer">Contact</a>
                                        </div>
                                    </>
                                :
                                    <div  className="col text-center">
                                        <Button variant="primary" onClick={(e) => {setShowModal(false); e.target.blur()}}>Continue</Button>
                                    </div>
                                }
                            </>
                        :
                            <>
                                <div  className="col">
                                    <Button variant="outline-primary" className="w-100" onClick={(e) => {setShowModal(false); e.target.blur()}}>No</Button>
                                </div>
                                <div  className="col">
                                    <Button variant="danger" className="w-100" disabled={!checkboxChecked && !loading} onClick={() => {deleteAccount()}}>{loading ? <Loader /> : 'Delete account'}</Button>
                                </div>
                            </>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}
import AppRoutes from "./AppRoutes";
import './static/scss/App.scss';

import axios from "axios";
import { FiAlertTriangle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getExpiry, getToken, getUser, isLicenseExpired, removeUserSession, setUserSession } from './utils/Common';
import { useRef } from "react";

let axiosInterceptor = null

function App() {
  let navigate = useNavigate();
  const networkErrorToastRef = useRef(null)

  const handleLogout = () => {
    removeUserSession();
    navigate('/login');
  }

  const expiry = getExpiry()
  if (expiry && (Date.parse(expiry) <= Date.now())) {
    handleLogout()
  }

  const token = getToken()
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + getToken();
  }
  else {
    axios.defaults.headers.common['Authorization'] = null
  }
  
  const user = getUser()
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }
  axiosInterceptor = axios.interceptors.response.use(
    (response) => {
      if (networkErrorToastRef.current) {
        toast.clearWaitingQueue({containerId: 'network_error'})
        toast.dismiss(networkErrorToastRef.current)
        networkErrorToastRef.current = null
      }
      return response
    },
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          handleLogout()
        }
        else if (error.response.status === 403 && user && user.customer && (error.request.responseURL !== user.customer.license)) {
          try {
            const license_response = await axios({
              method: 'get',
              url: user.customer.license
            })
            const updatedUser = {
              ...user,
              plan: license_response.data.plan,
              plan_label: license_response.data.plan_label,
              license_start_date: license_response.data.start_date,
              license_end_date: license_response.data.end_date,
            }
            setUserSession(false, false, updatedUser);
          }
          catch {
            // do nothing
          }
          finally {
            if (isLicenseExpired()) {
              navigate('/license-expired')
            }
          }
        }
        else if (error.response.status === 408) {
          console.log(`A timeout happend on url ${error.config.url}`)
          //TODO: manage
        }
      }
      else {
        if (!networkErrorToastRef.current || !toast.isActive(networkErrorToastRef.current)) {
          toast.clearWaitingQueue({containerId: 'network_error'})
          networkErrorToastRef.current = toast.warning(!navigator.onLine ? 'No internet connection.' : 'Network error.', {
            icon: <FiAlertTriangle size={'20px'} />,
            autoClose: false,
            containerId: 'network_error'
          });
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  )

  return (
    <>
      <AppRoutes />
      <ToastContainer
        enableMultiContainer
        containerId={'global'}
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ToastContainer
        enableMultiContainer
        containerId={'network_error'}
        position="top-center"
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
export default App;
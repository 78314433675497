import axios from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import { Badge, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { FiAlertTriangle, FiChevronDown, FiShield, FiUser } from 'react-icons/fi';
import { useOutletContext } from 'react-router-dom';
import { addToast, capitalizeFirstLetter, getDateString, getExtendedDateString, priceToString } from '../../../utils/Common';
import { ECOMMERCE_ADVANCED_LICENSE, ECOMMERCE_BASIC_LICENSE, ECOMMERCE_BILLING_CYCLE, ECOMMERCE_BILLING_DETAILS, ECOMMERCE_PAYMENT_METHOD, ENTERPRISE_TEAM_MEMBERS_URL, LICENSE_URL } from '../../../utils/Endpoints';
import { availableProductsConfigurations } from '../../../utils/ProductConstants';
import DowngradeModal from "../../../utils/widgets/DowngradeModal/DowngradeModal";
import { ReactComponent as ToolAiModuleIcon } from '../../../static/icons/tool_ai_module.svg';
import config from '../../../config';
import TeamMembersTable from './LicenseExtra/TeamMembersTable';


export default function License({setLoading, fetchData, licenseObj, userPaymentMethod, billingInfo, selectedBillingInterval, enterprise_workspace, enterprise_member_detail}) {
    const is_enterprise = licenseObj.plan === "3";
    const outletContext = useOutletContext();
    const [licenseAccordionVisible, setLicenseAccordionVisible] = useState(null);
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const [enterpriseMemberList, setEnterpriseMemberList] = useState([])
    const [enterpriseManager, setEnterpriseManager] = useState(null)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)


    const columns = useMemo(() => {
        return [
            {
                Header: 'Role',
                accessor: 'is_enterprise_manager',
                Cell: ({ cell: { value } }) => {
                    if (value) {
                        return <FiShield size="20px" className='flex-shrink-0' />
                    }
                    else {
                        return <FiUser size="20px" className='flex-shrink-0' />
                    }
                }
            },
            {
                Header: 'Is pending',
                accessor: 'registration_pending'
            },
            {
                Header: 'First Name',
                accessor: 'first_name'
            },
            {
                Header: 'Last Name',
                accessor: 'last_name'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Has Tool',
                accessor: 'has_tool',
                Cell: ({ cell: { value } }) => {
                    if (value) {
                        return <Badge pill className='mx-2 figma-caption-semibold px-3 py-1' bg='secondary' text='primary'>TOOL</Badge>
                    }
                    else {
                        return ''
                    }
                }
            },
            {
                Header: 'Has AI',
                accessor: 'tool_ai_modules',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    const assigned_bundle = enterprise_workspace.ai_bundles.find((bundle) => bundle.assigned_to === `${LICENSE_URL}${original_row.license_id}/`)
                    return (
                        <div className='me-3'>
                            {assigned_bundle &&
                                <OverlayTrigger
                                    placement={"bottom"}
                                    overlay={
                                        <Popover className="">
                                            <PopoverBody className="py-1">
                                                {assigned_bundle.name}
                                            </PopoverBody>
                                        </Popover>
                                    }
                                >
                                    <div><ToolAiModuleIcon size="20px" /></div>
                                </OverlayTrigger>
                            }
                        </div>
                    )
                }
            },
        ]
    }, [])


    const toggleLicenseAccordionVisible = () => {
      setLicenseAccordionVisible(!licenseAccordionVisible);
    };

    const emptyBillingInfo = () => {
        const billingIsEmptyDict = billingInfo && Object.keys(billingInfo).length === 0 && Object.getPrototypeOf(billingInfo) === Object.prototype
        const emptyBillingInfo = billingIsEmptyDict || !billingInfo || !billingInfo.billing_type || !billingInfo.city || !billingInfo.address || !billingInfo.zipcode || !billingInfo.invoice_holder || !billingInfo.country || (billingInfo.billing_type === 'private' ? (!billingInfo.national_id) : (!billingInfo.vat)) || (billingInfo.billing_type === 'company' && billingInfo.country === 'IT' && !billingInfo.sdi_code)
        return emptyBillingInfo
    }

    const fetchTeamMembers = useCallback(async ({ pageSize, pageIndex }) => {
        setLoadingMembers(true)
        setEnterpriseMemberList([])
        const requestConfig = {
            method: 'get',
            url: ENTERPRISE_TEAM_MEMBERS_URL,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            let responseData = response.data.results
            setEnterpriseMemberList(responseData)
            const enterprise_manager = responseData.find(member => member.is_enterprise_manager)
            if (enterprise_manager) {
                setEnterpriseManager(enterprise_manager)
            }
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setEnterpriseMemberList([])
        }
        setLoadingMembers(false)
    }, [])

    const downgradeToFree = async () => {
        try {
        setShowDowngradeModal(false)
        setLoading(true)
        await axios({
            method: 'post',
            url: outletContext.user.customer.license + 'downgrade_to_free/'
        })
        addToast('success', 'The license has been successfully downgraded to Free.', true)
        }
        catch (error) {
        addToast('error', 'The downgrade of your license to Free has failed.', true)
        }
        finally {
        await fetchData()
        setLoading(false)
        }
    }

    return (
        <div className={"License h-100 d-flex flex-column " + (is_enterprise ? " tight-column" : "")}>
        <div className='figma-p3-semibold primary-600 mb-3'>Current license</div>
        <div className='d-flex justify-content-between align-items-start mb-2 neutral-600'>
            <div className="figma-p2-semibold">{licenseObj.plan_label}</div>
            {!is_enterprise &&
                <div className='figma-p1-semibold primary-600 cursor-pointer' onClick={toggleLicenseAccordionVisible}>{(licenseObj.plan === '0') ? 'Upgrade' : 'Manage'} license <FiChevronDown id='license-accordion-chevron' size="24px" className={'ms-2' + (licenseAccordionVisible ? ' opened' : '')} /></div>
            }
        </div>
        {is_enterprise
            ?
            <>
                <div className='mb-3 figma-p1 neutral-600'>
                    <div>With this license you are {enterprise_member_detail.is_enterprise_manager ? 'the manager' : 'a member'} of a workspace and you can process <span className='figma-p1-semibold'>{enterprise_workspace.enterprise_create_from_stl} STL cases</span> per month, <span className='figma-p1-semibold'>share {enterprise_workspace.enterprise_share} cases</span>, <span className='figma-p1-semibold'>store {enterprise_workspace.enterprise_archive_size} cases</span> simultaneously and have the ability to create Sharing Rooms.
                    <br/><br/>The License also includes the management of <span className='figma-p1-semibold'>{enterprise_workspace.enterprise_accounts} accounts</span> within the workspace, <span className='figma-p1-semibold'>{enterprise_workspace.enterprise_custom_segmentations} Custom Segmentations</span> that can be used by the team,  <span className='figma-p1-semibold'>{enterprise_workspace.enterprise_tools} Tools</span> and <span className='figma-p1-semibold'>{enterprise_workspace.ai_bundles?.length || 0} AI modules</span>.</div>
                    <div className='mt-4'>Renewal will take place on: <span className='figma-p1-semibold primary-600'>{getDateString(new Date(licenseObj.end_date))}</span></div>
                </div>
                <div className='horizontal-line mb-4'></div>
                <div className='figma-p3-semibold primary-600 mb-3'>Workspace information</div>
                <div className='mb-4'>
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">Name:</span>
                        <span className="figma-p1 neutral-600">{enterprise_workspace.enterprise_name || ' -'}</span>
                    </div>
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">Created:</span>
                        <span className="figma-p1 neutral-600">{enterprise_workspace.first_set_name_date ? getDateString(new Date(enterprise_workspace.first_set_name_date)) : ' -'}</span>
                    </div>
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">Manager:</span>
                        <span className="figma-p1 neutral-600">{enterpriseManager?.first_name || '-'} {enterpriseManager?.last_name}</span>
                    </div>
                    <div>
                        <span className="figma-p1-semibold neutral-800 me-1">Members:</span>
                        <span className="figma-p1 neutral-600">{dataCount || ' -'}</span>
                    </div>
                </div>
                <div className='horizontal-line mb-4'></div>
                <div className='figma-p3-semibold primary-600 mb-3'>Workspace team</div>
                
                <TeamMembersTable
                    columns={columns}
                    data={enterpriseMemberList}
                    fetchData={fetchTeamMembers}
                    loading={loadingMembers}
                    pageCount={pageCount}
                    dataCount={dataCount}
                    controlledPageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    disableSortBy={true}
                />
            </>
            :
            <>
                <div className='mb-3 figma-p1 neutral-600'>
                    <div>With this license you can process <span className='figma-p1-semibold'>{availableProductsConfigurations[licenseObj.plan].license_thresholds.create_from_stl} STL cases</span> per month. You can <span className='figma-p1-semibold'>share {availableProductsConfigurations[licenseObj.plan].license_thresholds.share} cases</span> and <span className='figma-p1-semibold'>store {availableProductsConfigurations[licenseObj.plan].license_thresholds.archive_size} cases</span> simultaneously.</div>
                    <div className='mt-1'>Renewal will take place on: <span className='figma-p1-semibold primary-600'>{getDateString(new Date(licenseObj.end_date))}</span></div>
                </div>
                <div className='d-flex flex-column justify-content-start'>
                    <div id='license-accordion' className={licenseAccordionVisible ? 'opened' : ''}>
                        <div className='d-flex flex-column flex-md-row mb-4'>
                            <a href={ECOMMERCE_BASIC_LICENSE} target='_blank' rel='noreferrer' className={'never-underlined d-flex size-container col mb-2 mb-md-0 me-0 me-md-4' + (licenseObj.plan === '1' ? ' active' : '')}>
                                <div className='size-content w-100 d-flex flex-column justify-content-start align-items-start p-4 pe-3'>
                                    <div className='figma-h6-semibold primary-800 mb-2'>Basic</div>
                                    <div className='figma-caption neutral-600 mb-3'>Upload, view and share your clinical cases with your patients or other specialists.</div>
                                    <div className='neutral-600 mt-auto'><span className='figma-p1-italic'>from:</span><span className='figma-h6-semibold primary-800'> € {priceToString(availableProductsConfigurations['1'].yearly_billing_monthly_price)} </span><span className='figma-p1'>/ month</span></div>
                                </div>
                            </a>
                            <a href={ECOMMERCE_ADVANCED_LICENSE} target='_blank' rel='noreferrer' className={'never-underlined d-flex size-container col' + (licenseObj.plan === '2' ? ' active' : '')}>
                                <div className='size-content w-100 d-flex flex-column justify-content-start align-items-start p-4 pe-3'>
                                    <div className='figma-h6-semibold primary-800 mb-2'>Advanced</div>
                                    <div className='figma-caption neutral-600 mb-3'>Benefit of an increased storage capacity and create unlimited sharing rooms.</div>
                                    <div className='neutral-600 mt-auto'><span className='figma-p1-italic'>from:</span><span className='figma-h6-semibold primary-800'> € {priceToString(availableProductsConfigurations['2'].yearly_billing_monthly_price)} </span><span className='figma-p1'>/ month</span></div>
                                </div>
                            </a>
                        </div>
                        {licenseObj.plan !== '0' &&
                            <div className='mb-4'>
                            <Button variant='link' className='p-0 primary-600 figma-p1-medium-underlined' onClick={() => { setShowDowngradeModal(true) }}>Downgrade to Verima Free license</Button>
                            </div>
                        }
                    </div>
                </div>
                <div className='horizontal-line mb-4'></div>
                {licenseObj.plan !== '0' &&
                    <>
                    <div className='figma-p3-semibold primary-600 mb-3'>Payment method</div>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center mb-4'>
                        <div className={'col-12 col-md-6 ' + (userPaymentMethod ? 'figma-p1-semibold neutral-800' : 'figma-p1 neutral-600')}>
                        {userPaymentMethod
                            ?
                            <>
                            {userPaymentMethod.image} <span className='ms-3'>{userPaymentMethod.label}</span>
                            <div className='mt-2 figma-caption neutral-600'>Next billing date will be <span className='figma-caption-semibold'>{getExtendedDateString(new Date(licenseObj.end_date))}</span>.</div>
                            {userPaymentMethod.last_payment_error
                                &&
                                <div className='figma-caption error-500'>
                                <br />
                                The last attempt to pay with this method was unsuccessful.
                                <br />
                                {userPaymentMethod.last_payment_error}
                                </div>
                            }
                            </>
                            :
                            <>No payment method</>
                        }
                        </div>
                        <div className='col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end'>
                            <a className='not-underlined p-0 figma-p1-semibold primary-600' href={ECOMMERCE_PAYMENT_METHOD}>{userPaymentMethod ? 'Change' : 'Enter'} payment method</a>
                        </div>
                    </div>
                    <div className='horizontal-line mb-4'></div>
                    {userPaymentMethod &&
                        <>
                        <div className='figma-p3-semibold primary-600 mb-3'>Billing cycle</div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-start mb-4'>
                            <div className='col-12 col-md-6'>
                                <div className='figma-p1 neutral-600 d-flex'>{capitalizeFirstLetter(selectedBillingInterval)}</div>
                            </div>
                            <div className='col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end'>
                                <a className='not-underlined p-0 figma-p1-semibold primary-600' href={ECOMMERCE_BILLING_CYCLE}>Change billing cycle</a>
                            </div>
                        </div>
                        <div className='horizontal-line mb-4'></div>
                        <div className='figma-p3-semibold primary-600 mb-3'>Billing details</div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-start mb-4'>
                            <div className='col-12 col-md-6 figma-p1-semibold'>
                            {emptyBillingInfo()
                                ?
                                <div className='figma-p1-semibold neutral-400'><FiAlertTriangle size='24px' className='me-2' /> No billing details entered</div>
                                :
                                <div className='figma-p1 neutral-600 d-flex row'>
                                    <div className='mb-1 col-12'>{billingInfo.invoice_holder},</div>
                                    <div className='mb-1 col-12'>{billingInfo.billing_type === 'private' ? billingInfo.national_id : billingInfo.vat},</div>
                                    {(billingInfo.billing_type === 'company' && billingInfo.country === 'IT') &&
                                        <div className='mb-1 col-12'>{billingInfo.sdi_code},</div>
                                    }
                                    <div className='mb-1 col-12 col d-flex'>{billingInfo.country_label}, {billingInfo.city}, {billingInfo.zipcode},</div>
                                    <div className='mb-1 col-12 col d-flex'>{billingInfo.address}.</div>
                                </div>
                            }
                            </div>
                            <div className='col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end'>
                            <a className='not-underlined p-0 figma-p1-semibold primary-600' href={ECOMMERCE_BILLING_DETAILS}>{emptyBillingInfo() ? 'Enter' : 'Change'} billing details</a>
                            </div>
                        </div>
                        </>
                    }
                    </>
                }
            </>
        }
        <DowngradeModal showDowngradeModal={showDowngradeModal} setShowDowngradeModal={setShowDowngradeModal} downgradeToFree={downgradeToFree} />
        </div>
    );
}
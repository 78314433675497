import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Image, Modal, Spinner } from "react-bootstrap";
import { FiTrash2, FiUserPlus } from "react-icons/fi";
import config from "../../config";
import add_contact_avatar from '../../static/images/add_contact_avatar.svg';
import { objectListsEqual, validateEmail } from "../../utils/Common";
import { CASE_URL, CONTACTS_URL, SHARED_BY_ME_URL } from "../../utils/Endpoints";

export default function ShareModal({ setShowShareModal, sharedWith, updateShares, loadingShares, setLoadingShares, caseId, caseObj, isEnterprise }) {
    const [tmpSharedWith, setTmpSharedWith] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchText, setSearchText] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);
    const [lastTimeout, setLastTimeout] = useState(null);

    useEffect(() => {
        setTmpSharedWith(sharedWith)
    }, [sharedWith])

    async function getContactsAPI(text, page) {
        const url = isEnterprise ? `${CONTACTS_URL}enterprise/?search=${text}&page=${page}` : `${CONTACTS_URL}?search=${text}&page=${page}`
        const requestConfig = {
            method: 'get',
            url: url,
        }
        return await axios(requestConfig)
    }

    async function listCaseShareAPI(page) {
        const requestConfig = {
            method: 'get',
            url: `${SHARED_BY_ME_URL}?shared_case=${caseId}&page=${page}`,
        }
        return await axios(requestConfig)
    }

    async function updateContacts(inputText) {
        let text = inputText || ''
        let page = 0
        const finalArray = []
        const actualMails = tmpSharedWith.map((customer) => customer.email)

        while(finalArray.length < 10) {
            try {
                page++
                const response = await getContactsAPI(text, page)
                let result = response.data.results
                result = result.filter((customer) => !actualMails.includes(customer.email))
                finalArray.push(...result)
                if (!response.data.next) {break}
            }
            catch(error) {
                //TODO: give feedback to the user
                break
            }
        }

        if (finalArray.length === 0 && validateEmail(text)) {
            const actualMails = tmpSharedWith.map((customer) => customer.email)
            if (!actualMails.includes(text)) {
                finalArray.push({
                    "url": '',
                    "id": null,
                    "username": '',
                    "first_name": '',
                    "last_name": '',
                    "email": text,
                    "profile_img_small": '',
                    "profile_img_medium": '',
                    "profile_img_large": '',
                    "profile_img_xlarge": '',
                    "profession": '',
                    "license": ''
                })
            }
        }

        setSearchResults(finalArray)
        setSearchLoading(false)
    }

    useEffect(() => {
        updateContacts()
    }, [tmpSharedWith])

    async function addCustomer(targetCustomer) {
        setSearchFocus(false)
        setSearchText('')
        const updatedTmpSharedWith = [...tmpSharedWith]
        updatedTmpSharedWith.unshift(targetCustomer)
        setTmpSharedWith(updatedTmpSharedWith)
    }

    function removeCustomer(targetCustomer) {
        let updatedTmpSharedWith = [...tmpSharedWith]
        updatedTmpSharedWith = updatedTmpSharedWith.filter((customer) => customer.email !== targetCustomer.email)
        setTmpSharedWith(updatedTmpSharedWith)
    }

    async function handleSave() {
        setShowShareModal(false)
        setLoadingShares(true)
        const oldMails = sharedWith.map((customer) => customer.email)
        const newMails = tmpSharedWith.map((customer) => customer.email)
        const removed = sharedWith.filter((customer) => !newMails.includes(customer.email))
        const added = tmpSharedWith.filter((customer) => !oldMails.includes(customer.email))
        
        let page = 0
        let caseShares = []
        let stop = false
        
        while(!stop) {
            page++
            const response = await listCaseShareAPI(page)
            caseShares.push(...response.data.results)
            if (!response.data.next) {
                stop = true
            }
        }

        const removalRequests = removed.reduce((requests, customer) => {
            const caseShare = caseShares.find(obj => obj.share_target_id === customer.id);
            if (caseShare) {
                requests.push(axios({
                    method: 'delete',
                    url: caseShare.url
                }))
            }
            return requests
        }, [])
        const creationRequests = added.map((customer) => {
            let formData = new FormData();
            formData.append('email', customer.email);
            formData.append('shared_case', caseId);
            return axios({
                method: 'post',
                url: SHARED_BY_ME_URL,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        })

        const requests = [...removalRequests, ...creationRequests]
        try {
            await Promise.all(requests)
            const requestConfig = {
                method: 'get',
                url: `${CASE_URL}${caseObj.id}/`,
            }
            const response = await axios(requestConfig)
            const caseData = response.data
            await updateShares(caseData)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('responseList error >>> ', error); }
        }
        setLoadingShares(false)
    }

    return (
        <Modal centered backdrop="static" keyboard={false} /* scrollable */ size="lg" show={true} onHide={() => setShowShareModal(false)}>
            <Modal.Header>
                <Modal.Title className="primary-600">Case sharing</Modal.Title>
            </Modal.Header>
            <Modal.Body id="share-modal">
                {
                    loadingShares
                        ? (
                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                <Spinner animation="border" variant="secondary" />
                            </div>
                        )
                        : (
                            <>
                                <Form.Group>
                                    <Form.Label>Share with a contact</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter username or email"
                                        name="search_text"
                                        id="search_text"
                                        value={searchText}
                                        onChange={(e) => { setSearchText(e.target.value); clearTimeout(lastTimeout); setSearchLoading(true); setSearchResults([]); setLastTimeout(setTimeout(() => {updateContacts(e.target.value)}, 1000))}}
                                        onFocus={() => setSearchFocus(true)}
                                        onBlur={() => setTimeout(() => { setSearchFocus(false) }, 200)}
                                        autoComplete="off"
                                        className={"d-inline" + (searchFocus ? " focused" : "")}
                                    />
                                    {!searchFocus &&
                                        <div className="fi-user-plus-container">
                                            <div className="d-flex h-100 align-items-center">
                                                <FiUserPlus className="neutral-400 d-inline" size="24px" />
                                            </div>
                                        </div>
                                    }
                                </Form.Group>
                                {searchFocus &&
                                    <div className="search-box-container w-100">
                                        <Card className="search-box w-100 shadow">
                                            {searchLoading
                                                ? <Spinner animation="border" variant="secondary" className="m-3" />
                                                : searchResults.length > 0
                                                    ?
                                                    searchResults.map((customer, idx) => {
                                                        const customerName = (!customer.username || customer.username.startsWith('__')) ? customer.email : `${customer.first_name} ${customer.last_name}`
                                                        return (
                                                            <div key={idx} className="result-row d-flex justify-content-between align-items-center py-3 px-3 w-100" onMouseDown={() => addCustomer(customer)} onClick={() => addCustomer(customer)}>
                                                                <div className="d-flex align-items-center overflow-auto">
                                                                    <div>
                                                                        <Image className="profile-picture" fluid src={customer.profile_img_large} roundedCircle onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = add_contact_avatar }} />
                                                                    </div>
                                                                    <div className="mx-3 overflow-auto">
                                                                        <div className="figma-h6 neutral-600 mb-1">{customerName}</div>
                                                                        <div className="figma-caption neutral-500">{customer.email}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="figma-p1-semibold neutral-600">
                                                                    <FiUserPlus size="24px" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : <div className="mx-3 figma-p1 neutral-400 line-height-66" >No results available</div>
                                            }
                                        </Card>
                                    </div>
                                }
                                <p className="figma-p1 neutral-600 mt-4 mb-0">Shared with</p>
                                <div className="contact-list d-flex flex-column">
                                    {
                                    tmpSharedWith.length > 0
                                    ? tmpSharedWith.map((customer, idx) => {
                                        const customerName = (!customer.username || customer.username.startsWith('__')) ? customer.email : `${customer.first_name} ${customer.last_name}`
                                        return (
                                            <div key={idx} className="d-flex justify-content-between align-items-center py-3 w-100">
                                                <div className="d-flex align-items-center overflow-auto">
                                                    <div>
                                                        <Image className="profile-picture" fluid roundedCircle src={customer.profile_img_large} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = add_contact_avatar }} />
                                                    </div>
                                                    <div className="mx-3 overflow-auto hide-scrollbar">
                                                        <div className="figma-h6 neutral-600 mb-1">{customerName}</div>
                                                        <div className="figma-caption neutral-500">{customer.email}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button variant="outline-primary" className="px-2 me-1" onClick={(e) => { removeCustomer(customer); e.target.blur() }}>
                                                        <FiTrash2 size="20px" />
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <div className="my-3 figma-p1 neutral-400">Case not shared</div>
                                }
                                </div>
                            </>
                        )
                }
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="outline-primary" className="px-5" onClick={(e) => {setShowShareModal(false); e.target.blur()}}>Cancel</Button>
                <Button variant="primary" className="px-5" disabled={objectListsEqual(tmpSharedWith, sharedWith)} onClick={() => {handleSave()}}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}


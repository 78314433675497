import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate, useOutletContext } from 'react-router-dom';
import config from '../../../config';
import logo from '../../../static/images/logo_animato.gif';
import witapp_logo from '../../../static/images/powered_by_witapp.svg';
import { addToast, hideEmail } from "../../../utils/Common";
import { CREDENTIAL_RESET_STEP_ONE_URL, CREDENTIAL_RESET_STEP_TWO_URL } from '../../../utils/Endpoints';
import Loader from "../../../utils/widgets/Loader/Loader";
import SideTextureTemplate from "../../../utils/widgets/SideTextureTemplate/SideTextureTemplate";

export default function CredentialResetStepTwo() {
  let navigate = useNavigate();
  const outletContext = useOutletContext()

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(null);
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const [verErrors, setVerErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const missingEmail = !outletContext.email || outletContext.email.length === 0
    const missingToken1 = !outletContext.token1 || outletContext.token1.length === 0
    if (missingEmail || missingToken1) {
      navigate('/credential-reset/step-one')
    }
  }, [])

  function cleanErrors() {
    setCodeError(null);
    setNonFieldErrors([]);
    setVerErrors([]);
  }

  async function sendNewCode() {
    setLoading(true)
    setCode('')
    cleanErrors()
    try {
      var formData = new FormData();
      formData.append("email", outletContext.email);
      const response = await axios({
          method: 'post',
          url: CREDENTIAL_RESET_STEP_ONE_URL,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
      })
      outletContext.setToken1(response.data.token)
      addToast('success', 'We are sending a new code.')
      navigate("/credential-reset/step-two");
      if (config.SHOULD_LOG) {console.log('response >>> ', JSON.stringify(response));}
    }
    catch (error) {
      addToast('error', 'Something went wrong, please try again later.')
      if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
    }
    finally {
      setLoading(false)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true)
    cleanErrors();
    try {
      var formData = new FormData();
      formData.append("code", code);
      formData.append("token", outletContext.token1);
      const response = await axios({
          method: 'post',
          url: CREDENTIAL_RESET_STEP_TWO_URL,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
      })
      outletContext.setToken2(response.data.token)
      outletContext.setUsername(response.data.username)
      navigate("/credential-reset/step-three");
      if (config.SHOULD_LOG) {console.log('response >>> ', JSON.stringify(response));}
    }
    catch (error) {
      if (error.response?.data) {
        setCodeError(error.response.data.code);
        setNonFieldErrors(error.response.data.non_field_errors || []);
        setVerErrors(error.response.data.ver_error || []);
      }
      if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
      setLoading(false)
    }
  }

  function buttonEnabled() {
    return code.length > 0 && nonFieldErrors.length === 0 && verErrors.length === 0 && !codeError;
  }

  return (
    <SideTextureTemplate>
      {loading
        ? <Loader />
        : <>
          <Form onSubmit={handleSubmit} className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
          <div className="d-flex justify-content-center mb-5">
            <Image key={Date.now()} fluid alt="Verima Logo" src={logo} className="verima-logo m-auto" />
          </div>
          <div className="d-block figma-h4-semibold text-center primary-600 mb-4">
            Credentials reset
          </div>
          <div className="d-block figma-p1 text-center neutral-700 mb-3">
            We have sent a code to your email address <span className="figma-p1-semibold">{hideEmail(outletContext.email)}</span> to verify your identity. Please enter below the code you received.
          </div>
          <div className="d-block figma-p1 text-center neutral-700 mb-4">
            This code will be valid for <span className="figma-p1-semibold">10 minutes</span> from now.
          </div>
          <Form.Group className="mb-3" controlId="code">
            <Form.Control
              autoFocus
              size='lg'
              type="text"
              placeholder="Code"
              value={code}
              onChange={(e) => { setCode(e.target.value); cleanErrors() }}
              isInvalid={(codeError || nonFieldErrors.length > 0) ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {codeError}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {nonFieldErrors.map((error) =>
                <p key={error}>{error}</p>
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="nonfield-errors mt-1 w-100">
            {verErrors.map((error) =>
              <p key={error.code}>{error.msg}</p>
            )}
          </div>
          <div className="d-grid my-4">
            <Button variant="primary" size="lg" type="submit" disabled={!buttonEnabled()}>
                <span className="figma-p2-semibold">Continue</span>
            </Button>
          </div>
          <div className="d-block figma-p1 text-center neutral-700 mb-2">
            Have you not received the code or has it expired?
          </div>
          <div className="d-grid text-center">
            <Button variant='link' className='p-0 figma-p2-semibold primary-600' onClick={() => sendNewCode()}>Send a new code</Button>
          </div>
          <div className="d-block figma-p1 text-center neutral-700 mt-5 pt-4">
            To restart the credentials reset process, <Button variant='link' className='p-0 figma-p1 primary-600' onClick={() => navigate('/credential-reset/step-one')}>click here</Button>.
          </div>
        </Form>
        <div className="d-none d-lg-block col-12 mt-4 mt-lg-5 mb-3 mb-lg-5 text-center align-self-end">
          <a href="https://witapp.it" target="_blank" rel="noreferrer"><Image fluid alt="Powered by Witapp" src={witapp_logo} className="witapp-logo m-auto" /></a>
        </div>
        </>
      }
    </SideTextureTemplate>
  );
}
import React, { useState } from "react";
import { Col, Row, Modal, Button, Form} from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";
import './DowngradeModal.scss';


export default function DowngradeModal({showDowngradeModal, setShowDowngradeModal, downgradeToFree}) {
    const [understand, setUnderstand] = useState(false)

    return (
        <Modal centered size={'md'} show={showDowngradeModal} onHide={() => setShowDowngradeModal(false)}>
            <Modal.Body id="downgrade-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="alert-icon mb-3 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiAlertCircle className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12} className='mb-2'>
                        <div className="figma-h6-semibold neutral-900">Do you really want to switch to a Free license?</div>
                    </Col>
                    <Col md={12} className='mb-3'>
                        <div className="figma-p1 neutral-500">By converting your license to Free, you will lose your personal cases.<br />All the cases shared with you will still be available.</div>
                    </Col>
                    <Col md={12} className='mb-2'>
                        <Form>
                            <Form.Check
                                id='understand-check'
                                checked={understand}
                                label={<span>I understand</span>}
                                type='checkbox'
                                className='form-check-container d-flex align-items-center justify-content-center figma-p1 neutral-600'
                                onChange={(e) => {setUnderstand(e.target.checked)}}
                                onClick={(e) => { e.target.blur() }}
                            />
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="outline-primary" className="px-4 py-2 figma-p1-semibold" onClick={(e) => {setShowDowngradeModal(false); e.target.blur()}}>Cancel</Button>
                <Button variant="primary" disabled={!understand} className="px-2 py-2 figma-p1-semibold" onClick={() => {downgradeToFree()}}>Switch to free</Button>
            </Modal.Footer>
        </Modal>
    );
}

import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate, useOutletContext } from 'react-router-dom';
import config from '../../../config';
import logo from '../../../static/images/logo_animato.gif';
import witapp_logo from '../../../static/images/powered_by_witapp.svg';
import { getQuerystring } from "../../../utils/Common";
import { REGISTRATION_STEP_ONE_URL } from '../../../utils/Endpoints';
import Loader from "../../../utils/widgets/Loader/Loader";
import SideTextureTemplate from "../../../utils/widgets/SideTextureTemplate/SideTextureTemplate";
import { FiAlertTriangle } from "react-icons/fi";

export default function RegistrationStepOne() {
  let navigate = useNavigate();
  const outletContext = useOutletContext()
  const preset_email = getQuerystring('email')

  const [emailError, setEmailError] = useState(null);
  const [nonFieldErrors, setNonFieldErrors] = useState([]);
  const [verErrors, setVerErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePresetEmail = async () => {
      if (preset_email) {
        outletContext.setEmail(preset_email)
        outletContext.setSkipStepOne(true)
        // await handleSubmit(null, preset_email)
      }
    }
    handlePresetEmail()
  }, [])

  function cleanErrors() {
    setEmailError(null);
    setNonFieldErrors([]);
    setVerErrors([]);
  }

  async function handleSubmit(event, preset_email = null) {
    if (event) {
      event.preventDefault();
    }
    setLoading(true)
    cleanErrors();
    try {
      var formData = new FormData();
      formData.append("email", preset_email || outletContext.email);
      const response = await axios({
        method: 'post',
        url: REGISTRATION_STEP_ONE_URL,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      outletContext.setToken1(response.data.token)
      navigate("/registration/step-two");
      if (config.SHOULD_LOG) { console.log('response >>> ', JSON.stringify(response)); }
    }
    catch (error) {
      if (error.response?.data) {
        setEmailError(error.response.data.email);
        setNonFieldErrors(error.response.data.non_field_errors || []);
        setVerErrors(error.response.data.ver_error || []);
      }
      if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
      setLoading(false)
    }
  }

  function buttonEnabled() {
    return outletContext.email.length > 0 && nonFieldErrors.length === 0 && verErrors.length === 0 && !emailError;
  }

  return (
    <SideTextureTemplate>
      {loading
        ? <Loader />
        : <>
          <Form onSubmit={handleSubmit} className="main-container col-12 mt-0 mt-md-0 mt-lg-4">
            <div className="d-flex justify-content-center mb-5">
              <Image key={Date.now()} fluid alt="Verima Logo" src={logo} className="verima-logo m-auto" />
            </div>
            <div className="d-block figma-h4-semibold text-center primary-600 mb-4">
              Create new account
            </div>
            <div className="d-block figma-p1 text-center neutral-700 mb-3">
              Please enter your email to create a new account.
            </div>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                autoFocus={preset_email === false ? true : false}
                size='lg'
                type="email"
                placeholder="Enter your email here"
                value={outletContext.email}
                onChange={(e) => { outletContext.setEmail(e.target.value); cleanErrors() }}
                isInvalid={(emailError) ? true : false}
              />
              <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            </Form.Group>
            {preset_email !== false && preset_email !== outletContext.email && (
              <div className="d-flex neutral-700 mt-0 figma-caption">
                <div className="me-2">
                  <FiAlertTriangle />
                </div>
                <div>
                  You won't be able to access shared cases using an email
                  address different than the one through which you received the
                  invitation.
                </div>
              </div>
            )}
            <div className="nonfield-errors mt-1 w-100">
              {nonFieldErrors.map((error) =>
                <p key={error}>{error}</p>
              )}
            </div>
            <div className="nonfield-errors mt-1 w-100">
              {verErrors.map((error) =>
                <p key={error.code}>{error.msg}</p>
              )}
            </div>
            <div className="d-grid my-4">
              <Button variant="primary" size="lg" type="submit" disabled={!buttonEnabled()}>
                <span className="figma-p2-semibold">Continue</span>
              </Button>
            </div>
            <div className="d-grid text-center">
              <Button variant='link' className='p-0 figma-p2-semibold primary-600' onClick={() => navigate('/login')}>Back to login</Button>
            </div>
          </Form>
          <div className="d-none d-lg-block col-12 mt-4 mt-lg-5 mb-3 mb-lg-5 text-center align-self-end">
            <a href="https://witapp.it" target="_blank" rel="noreferrer"><Image fluid alt="Powered by Witapp" src={witapp_logo} className="witapp-logo m-auto" /></a>
          </div>
        </>
      }
    </SideTextureTemplate>
  );
}
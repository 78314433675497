import { useEffect, useMemo } from "react";
import { Spinner, Table as ReactTable } from "react-bootstrap"
import { useTable, usePagination, useSortBy } from "react-table";
import './TransferTeamMembersTable.scss'
import { FiArrowDown, FiArrowUp } from "react-icons/fi";


export default function TransferTeamMembersTable({ columns, data, fetchData, loading, pageCount: controlledPageCount, controlledPageIndex: pageIndex, disableSortBy, manualSortBy, initialSortBy, onChangeSort, disableSortRemove, pageSize }) {
    const initialState = initialSortBy ? { sortBy: initialSortBy } : {}

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { sortBy },
    } = useTable({ columns, data, manualPagination: true, pageCount: controlledPageCount, useControlledState: state => useMemo(() => ({ ...state, pageIndex: pageIndex }), [state]), disableSortBy: disableSortBy, manualSortBy: manualSortBy, disableSortRemove: disableSortRemove, initialState: initialState, }, useSortBy, usePagination)


    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    useEffect(() => {
        onChangeSort && onChangeSort(sortBy);
    }, [onChangeSort, sortBy]);

    return (
        <>
            <div className="TransferTeamMembersTable">
                <div className="table-container mh-100">
                    <ReactTable {...getTableProps()} className="mb-0 table-fixed">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <div className="d-flex align-items-center figma-p2-semibold neutral-600">
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <FiArrowDown size='14px' className="ms-3 primary-600" />
                                                            : <FiArrowUp size='14px' className="ms-3 primary-600" />
                                                        : ''}
                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => <td {...cell.getCellProps()}><span className="figma-p1-semibold">{cell.render('Cell')}</span></td>)}
                                    </tr>
                                )
                            })}
                            <tr className="results-row">
                                {loading ? (
                                    <td colSpan="10000" className="py-4"><Spinner animation="border" variant="secondary" size="sm" className="me-2 figma-p1-semibold neutral-600" />Loading...</td>
                                ) : 
                                    page.length === 0 &&
                                        <td colSpan="10000">No records available</td>
                                }
                            </tr>
                        </tbody>
                    </ReactTable>
                </div>
            </div>
        </>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import TagManager from 'react-gtm-module'
import config from './config'
import {init as SentryInit, BrowserTracing, reactRouterV6Instrumentation} from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

if (config.GTM_TAG) {  
  TagManager.initialize({
    gtmId: config.GTM_TAG
  })
}

/* CLOUD */
const DSN_URL = "https://573ee6d1977a7c80acc8cd1c11c5951a@o4506104861294592.ingest.sentry.io/4506104863719424"

/* SELF-HOSTED */
// const DSN_URL = "https://256a01b6c010410ea1b6bfcafd105bf7@sentry.verima.it/19"

if(config.ENV_NAME !== 'local'){
  SentryInit({
    environment: config.ENV_NAME,
    release: `${config.REACT_APP_NAME}@${config.REACT_APP_VERSION}`,
    dsn: DSN_URL,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
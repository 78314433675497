import config from '../config';


// BACKEND URLS
export const LOGIN_URL = config.apiGateway.BASE_URL + "/api/auth/login/"
export const LOGOUT_URL = config.apiGateway.BASE_URL + "/api/auth/logout/"
export const LOGOUT_ALL_DEVICES_URL = config.apiGateway.BASE_URL + "/api/auth/logoutall/"
export const CHANGE_PASSWORD_URL = config.apiGateway.BASE_URL + "/api/auth/change_password/"
export const REGISTRATION_STEP_ONE_URL = config.apiGateway.BASE_URL + "/api/registration/step_one/"
export const REGISTRATION_STEP_TWO_URL = config.apiGateway.BASE_URL + "/api/registration/step_two/"
export const REGISTRATION_STEP_THREE_URL = config.apiGateway.BASE_URL + "/api/registration/step_three/"
export const CREDENTIAL_RESET_STEP_ONE_URL = config.apiGateway.BASE_URL + "/api/credential_reset/step_one/"
export const CREDENTIAL_RESET_STEP_TWO_URL = config.apiGateway.BASE_URL + "/api/credential_reset/step_two/"
export const CREDENTIAL_RESET_STEP_THREE_URL = config.apiGateway.BASE_URL + "/api/credential_reset/step_three/"

export const CASE_URL = config.apiGateway.BASE_URL + "/api/v2/case/"
export const SHARED_BY_ME_URL = config.apiGateway.BASE_URL + "/api/v2/shared_by_me/"
export const SHARED_WITH_ME_URL = config.apiGateway.BASE_URL + "/api/v2/shared_with_me/"
export const CUSTOMER_URL = config.apiGateway.BASE_URL + "/api/v2/customer/"
export const LICENSE_URL = config.apiGateway.BASE_URL + "/api/v2/license/"
export const CONTACTS_URL = config.apiGateway.BASE_URL + "/api/v2/my_contacts/"
export const CUSTOM_SEGMENTATIONS_TRACKING_URL = config.apiGateway.BASE_URL + "/api/v2/manual_case_tracking/"
export const NOTIFICATION_URL = config.apiGateway.BASE_URL + "/api/v2/notification/"
export const DEMO_CASE_URL = config.apiGateway.BASE_URL + "/api/v2/demo_case/"

export const ENTERPRISE_WORKSPACE_URL = config.apiGateway.BASE_URL + "/api/v2/enterprise_workspace/"
export const ENTERPRISE_CUSTOM_SEGMENTATIONS_LIST_URL = config.apiGateway.BASE_URL + "/api/v2/enterprise_team_segmentations/"
export const ENTERPRISE_TEAM_MEMBERS_URL = config.apiGateway.BASE_URL + "/api/v2/enterprise_team_members/"
export const ENTERPRISE_ADD_MEMBER_TO_TEAM = config.apiGateway.BASE_URL + "/api/v2/enterprise_workspace/add_member_to_team/"
export const ENTERPRISE_REMOVE_MEMBER_FROM_TEAM = config.apiGateway.BASE_URL + "/api/v2/enterprise_workspace/remove_member_from_team/"
export const ENTERPRISE_SET_TOOL = config.apiGateway.BASE_URL + "/api/v2/enterprise_workspace/set_tool/"



export const SHOP_BASE_URL = config.apiGateway.BASE_URL + "/api/v2/shop"
export const PAYMENT_METHOD_URL = SHOP_BASE_URL + "/pay_method/"
export const BILLING_URL = SHOP_BASE_URL + "/billing/"
export const SUBSCRIPTION_URL = SHOP_BASE_URL + "/subscription/"




// ECOMMERCE URLS
export const ECOMMERCE_BASIC_LICENSE = config.ECOMMERCE_URL + "/shop/products/basic-license"
export const ECOMMERCE_ADVANCED_LICENSE = config.ECOMMERCE_URL + "/shop/products/advanced-license"
export const ECOMMERCE_CUSTOM_SEGMENTATIONS_URL = config.ECOMMERCE_URL + "/shop/products/custom-segmentations"
export const ECOMMERCE_PAYMENT_METHOD = config.ECOMMERCE_URL + "/shop/payment-method?origin=desk"
export const ECOMMERCE_BILLING_CYCLE = config.ECOMMERCE_URL + "/shop/billing-cycle?origin=desk"
export const ECOMMERCE_BILLING_DETAILS = config.ECOMMERCE_URL + "/shop/billing-details?origin=desk"


// APP STORE
export const STORE_ANDROID_URL = "https://play.google.com/store/apps/details?id=it.witapp.verimaviewer"
export const STORE_IOS_URL = "https://apps.apple.com/it/app/verima-viewer/id1518775790"


// VERIMA WEBSITE
export const WEBSITE_URL = "https://verima.it"
export const WEBSITE_PRICING_URL = WEBSITE_URL + "/en/pricing"
export const WEBSITE_CONTACT_US_URL = WEBSITE_URL + "/en/about/contatti/"


// LEGAL DOCUMENTS
export const USER_MANUAL_ENG = WEBSITE_URL + "/verima-desk-user-manual-eng/"
export const USER_MANUAL_ITA = WEBSITE_URL + "/manuale-d_uso-verima-desk-ita/"
export const DATA_SHEETS_ENG = WEBSITE_URL + "/technical-data-sheet-verima-desk-eng/"
export const DATA_SHEETS_ITA = WEBSITE_URL + "/scheda-tecnica-verima-desk-ita/"
export const TERMS_OF_SERVICE_URL = WEBSITE_URL + "/terms-and-conditions-verima-cloud-eng/"
export const PRIVACY_POLICY_URL = "https://www.iubenda.com/privacy-policy/39828403"
export const COOKIE_POLICY_URL = "https://www.iubenda.com/privacy-policy/39828403/cookie-policy"

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FiAward, FiBox, FiFileText, FiInfo, FiLifeBuoy, FiUser, FiX } from "react-icons/fi";
import { useOutletContext } from "react-router-dom";
import config from '../../config';
import { ReactComponent as CustomSegmentationsIcon } from '../../static/icons/custom_segmentations.svg';
import { DEFAULT_IMAGE_FALLBACK, getBrandImage, getQuerystring } from "../../utils/Common";
import { BILLING_URL, CUSTOMER_URL, DEMO_CASE_URL, ENTERPRISE_TEAM_MEMBERS_URL, ENTERPRISE_WORKSPACE_URL, PAYMENT_METHOD_URL, SHARED_WITH_ME_URL, SUBSCRIPTION_URL, WEBSITE_PRICING_URL } from "../../utils/Endpoints";
import Loader from "../../utils/widgets/Loader/Loader";
import './Profile.scss';
import AccountSummary from "./subsections/AccountSummary";
import CustomSegmentations from "./subsections/CustomSegmentations";
import DemoCases from "./subsections/DemoCases";
import HelpCenter from "./subsections/HelpCenter";
import LegalNotes from "./subsections/LegalNotes";
import License from "./subsections/License";
import PersonalInformations from "./subsections/PersonalInformations";

export default function Profile(props) {
    const outletContext = useOutletContext();

    const [loading, setLoading] = useState(true)

    const [showBanner, setShowBanner] = useState(true)

    const [customerObj, setCustomerObj] = useState({ id: null, profile_img_medium:null, profile_img_large: null })
    const [licenseObj, setLicenseObj] = useState(null)
    const [enterpriseObj, setEnterpriseObj] = useState(null)
    const [enterpriseMemberObj, setEnterpriseMemberObj] = useState(null)
    const [userPaymentMethod, setUserPaymentMethod] = useState(null);
    const [billingInfo, setBillingInfo] = useState({})
    const [selectedBillingInterval, setSelectedBillingInterval] = useState('monthly')
    const [demoCases, setDemoCases] = useState([])

    const activeSubsection = getQuerystring('subsection') || 'account-summary'

    const [pages, setPages] = useState([
        {
            active: activeSubsection === 'account-summary',
            tag: 'account-summary',
            label: 'Account summary',
            icon: <FiFileText />,
        },
        {
            active: activeSubsection === 'personal-informations',
            tag: 'personal-informations',
            label: 'Personal information',
            icon: <FiUser />,
        },
        {
            active: activeSubsection === 'custom-segmentations',
            tag: 'custom-segmentations',
            label: 'Custom Segmentations',
            icon: <CustomSegmentationsIcon />,
        },
        {
            active: activeSubsection === 'license',
            tag: 'license',
            label: 'License details',
            icon: <FiAward />,
        },
        {
            active: activeSubsection === 'demo-cases',
            tag: 'demo-cases',
            label: 'Demo cases',
            icon: <FiBox />,
        },
        {
            active: activeSubsection === 'help-center',
            tag: 'help-center',
            label: 'Help center',
            icon: <FiLifeBuoy />,
        },
        {
            active: activeSubsection === 'legal-notes',
            tag: 'legal-notes',
            label: 'Legal notes',
            icon: <FiInfo />,
        },
    ])

    async function fetchData() {
        setLoading(true)
        setCustomerObj(null)
        try {
            const response = await axios({
                method: 'get',
                url: `${CUSTOMER_URL}${outletContext.user.customer.id}/`,
            })
            let customerData = response.data
            setCustomerObj(customerData)
            const license_response = await axios({
                method: 'get',
                url: customerData.license,
            })
            setLicenseObj(license_response.data)
            outletContext.updateUser({
                ...outletContext.user,
                customer: customerData,
                plan: license_response.data.plan,
                plan_label: license_response.data.plan_label,
                license_start_date: license_response.data.start_date,
                license_end_date: license_response.data.end_date,
            })
            let alteredPages = [...pages]
            if (license_response.data.plan === '0') {
                alteredPages = alteredPages.filter(page => page.tag !== 'license')
            }
            if (response.data.user_type !== 'hc_professional') {
                alteredPages = alteredPages.filter(page => page.tag !== 'demo-cases')
            }

            if (license_response.data.plan === '3') {
                try {
                    const enterprise_response = await axios({
                        method: 'get',
                        url: `${ENTERPRISE_WORKSPACE_URL}`,
                    })
                    setEnterpriseObj(enterprise_response.data)
                    try {
                        const enterprise_member_response = await axios({
                            method: 'get',
                            url: `${ENTERPRISE_TEAM_MEMBERS_URL}${customerData.id}/`,
                        })
                        setEnterpriseMemberObj(enterprise_member_response.data)
                    }
                    catch (error) {
                        if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                    }
                }
                catch (error) {
                    if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                }
            }
            setPages(alteredPages)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        try {
            const payment_response = await axios({
                method: 'get',
                url: `${PAYMENT_METHOD_URL}`,
            })
            const card = payment_response.data.payment_method.card
            setUserPaymentMethod({
                'label': '**** **** **** ' + card.last4,
                'image': getBrandImage(card.brand),
                'last_payment_error': payment_response.data.last_payment_error
            })
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        try {
            const billing_response = await axios({
                method: 'get',
                url: `${BILLING_URL}`,
            })
            setBillingInfo(billing_response.data)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        try {
            const subscription_response = await axios({
                method: 'get',
                url: `${SUBSCRIPTION_URL}`,
            })
            setSelectedBillingInterval(subscription_response.data.billing_interval)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        try {
            const demo_cases_response = await axios({
                method: 'get',
                url: `${DEMO_CASE_URL}`,
            })
            const requested_demo_cases_response = await axios({
                method: 'get',
                url: `${SHARED_WITH_ME_URL}?is_demo=true`,
            })
            // const requested_demo_cases_ids = requested_demo_cases_response.data.results.map(requested_demo_case => requested_demo_case.shared_case_id)
            const demo_cases = demo_cases_response.data.results.map((demo_case) => {
                const caseshare = requested_demo_cases_response.data.results.find(caseshare => caseshare.shared_case_id === demo_case.case)
                return {
                    ...demo_case,
                    'caseshare_id': caseshare ? caseshare.id : undefined
                }
            })
            setDemoCases(demo_cases)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const SideMenu = ({ tag, icon, label, active }) => {
        return (
            <div className={"submenu d-flex justify-content-start align-items-center" + (active ? " active" : "")} onClick={() => { setActivePage(tag) }}>
                {icon}
                <span className={(active ? "figma-p2-semibold" : "figma-p2")}>{label}</span>
            </div>
        )
    }

    const ProfileBadge = ({ customer }) => {
        return (
            <div className="profile-container px-2 py-5 overflow-hidden">
                <div className="d-flex flex-column justify-content-center align-items-center overflow-hidden">
                    <div className="mb-3">
                        <Image className="profile-picture" fluid roundedCircle src={customer.profile_img_large} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = DEFAULT_IMAGE_FALLBACK }} />
                    </div>
                    <div className="figma-p2-semibold primary-600 text-center mb-2">
                        <div className="text-truncate">{customer.first_name}</div>
                        <div className="text-truncate">{customer.last_name}</div>
                    </div>
                    <div className="figma-p1 neutral-500 text-truncate">{customer.email}</div>
                </div>
            </div>
        )
    }

    const setActivePage = (tag) => {
        const updatedPages = pages.map(page => (page.tag === tag) ? {...page, active: true} : {...page, active: false})
        setPages(updatedPages)
    }

    const getActivePage = () => {
        let activePage = pages.find(page => page.active === true)
        if (!activePage) {
            const firstPage = pages[0].tag
            setActivePage(firstPage)
            activePage = firstPage
        }
        return activePage
    }

    const getInnerSubsection = () => {
        switch (getActivePage().tag) {
            case 'account-summary':
                return <AccountSummary license={licenseObj} enterprise_workspace={enterpriseObj} enterprise_member_detail={enterpriseMemberObj} />
            case 'personal-informations':
                return <PersonalInformations customer={customerObj} fetchData={fetchData} license={licenseObj} enterprise_member_detail={enterpriseMemberObj}/>
            case 'license':
                return <License setLoading={setLoading} fetchData={fetchData} licenseObj={licenseObj} userPaymentMethod={userPaymentMethod} billingInfo={billingInfo} selectedBillingInterval={selectedBillingInterval} enterprise_workspace={enterpriseObj} enterprise_member_detail={enterpriseMemberObj} />
            case 'custom-segmentations':
                return <CustomSegmentations license={licenseObj} enterprise_workspace={enterpriseObj} enterprise_member_detail={enterpriseMemberObj} />
            case 'demo-cases':
                return <DemoCases demoCases={demoCases} customer={customerObj} setLoading={setLoading} />
            case 'help-center':
                return <HelpCenter />
            case 'legal-notes':
                return <LegalNotes />
            default:
                return <></>
        }
    }

    return (
        <div className="Profile" >
            <Container fluid className="h-100 w-100">
                {(loading || !customerObj?.id)
                    ? (
                        <Loader />
                    )
                    : (
                        <Row className="m-0 gx-0 h-100 main-row flex-nowrap">
                            <Col className="leftColumn flex-grow-0">
                                <ProfileBadge customer={customerObj} />
                                {pages.map((page) =>
                                    <SideMenu key={page.tag} tag={page.tag} icon={page.icon} label={page.label} active={page.active} />
                                )}
                            </Col>
                            <Col className="rightColumn pb-5">
                                <div className="subsection-header w-100 figma-h4-small-semibold shades-0 px-5 py-3">{getActivePage().label}</div>
                                {licenseObj && licenseObj.plan === '0' && ['account-summary'].includes(getActivePage().tag) &&
                                    <Banner showBanner={showBanner} setShowBanner={setShowBanner}/>
                                }
                                <div className="subsection-content p-5">
                                    {getInnerSubsection()}
                                </div>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        </div>
    );
}

function Banner({showBanner, setShowBanner}) {
    return (
        <div className={'w-100 px-5 figma-caption-semibold primary-600 bg-secondary-100 banner' + (showBanner ? '' : ' hidden')}>
            <div className="py-3 d-flex align-items-center">
                <span className='me-3'>Get a license to unlock all the additional features of Verima.</span>
                <a href={WEBSITE_PRICING_URL} target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-sm px-3 py-2 figma-caption-semibold">Upgrade license</a>
                <FiX className="close-banner-icon ms-auto" size={'22px'}  onClick={(e) => { setShowBanner(false); e.target.blur() }}/>
            </div>
        </div>
    )
}
import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import './CreateCase.scss';
import { useNavigate } from 'react-router-dom'


export default function CancelCreationModal({showCancelCreationModal, setShowCancelCreationModal}) {
    const navigate = useNavigate()
    return (
        <Modal centered show={showCancelCreationModal} onHide={() => setShowCancelCreationModal(false)}>
            <Modal.Body id="delete-case-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiTrash2 className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">Cancel case creation</p>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1 neutral-500">Are you sure you want to interrupt the case creation?</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                <Button variant="outline-primary" className="px-5" onClick={(e) => {setShowCancelCreationModal(false); e.target.blur()}}>No</Button>
                <Button variant="primary" className="px-5" onClick={() => {navigate('/my-cases')}}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
}


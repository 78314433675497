import React, { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

export default function CredentialReset(props) {
  const [email, setEmail] = useState('');
  const [token1, setToken1] = useState('');
  const [token2, setToken2] = useState('');
  const [username, setUsername] = useState('');
  const outletContext = {
    ...useOutletContext(),
    email: email,
    setEmail: setEmail,
    token1: token1,
    setToken1: setToken1,
    token2: token2,
    setToken2: setToken2,
    username: username,
    setUsername: setUsername,
  };
  return <Outlet context={outletContext} />;
}

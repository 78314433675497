import axios from "axios";
import { zipSync } from 'fflate';
import React, { useState } from "react";
import { Button, Col, Container, Form, Image, ProgressBar, Row, Spinner } from "react-bootstrap";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import config from '../../config';
import create_case_placeholder from '../../static/images/create_case_placeholder.svg';
import { addToast, get8DigitHex, getAsByteArray, getRandomColor } from "../../utils/Common";
import { CASE_URL } from "../../utils/Endpoints";
import CancelCreationModal from "./CancelCreationModal";
import './CreateCase.scss';


export default function CreateCase(props) {
    
    let navigate = useNavigate();

    const [showCancelCreationModal, setShowCancelCreationModal] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [loadingCurrentStep, setLoadingCurrentStep] = useState(0);
    const [loadingTotalSteps, setLoadingTotalSteps] = useState(0);

    const [caseState, setCaseState] = useState({
        case_name: '',
        case_notes: '',
        manual_category: 'abdominal',
        case_file: '',
        case_realfile: null,
    });
    const handleCaseChange = (e) => {
        setNonFieldErrors([])
        switch (e.target.name) {
            case 'case_name':
                setCaseNameError(null)
                break
            case 'case_notes':
                setCaseNotesError(null)
                break
            case 'manual_category':
                setManualCategoryError(null)
                break
            case 'case_file':
                setCaseFileError(null)
                break
            default:
                //pass
        }
        if (props.case_type === 'custom' && e.target.name === 'case_file') {
            const uploadedFile = e.target.files[0]
            if (uploadedFile) {
                const extension = uploadedFile.name.split('.').pop();
                if (extension.toUpperCase() !== 'ZIP') {
                    setCaseFileError('Must upload a zip file.')
                    setCaseState({
                        ...caseState,
                        case_file: '',
                        case_realfile: null
                    })
                }
                else if (uploadedFile.size > 2 * 1000 * 1000 * 1000) {
                    setCaseFileError('Max file size (2GB) exceeded.')
                }
                else {
                    let case_name = caseState.case_name
                    if (!case_name) {
                        case_name = uploadedFile.name.replace(/\.[^/.]+$/, "")
                        checkCaseName(case_name)
                    }
                    setCaseState({
                        ...caseState,
                        case_name: case_name,
                        case_file: e.target.value,
                        case_realfile: uploadedFile
                    })
                }
            }
            else {
                setCaseState({
                    ...caseState,
                    case_file: '',
                    case_realfile: null
                })
            }
        }
        else {
            if (e.target.name === 'case_name') {
                checkCaseName(e.target.value)
            }
            setCaseState({
                ...caseState,
                [e.target.name]: e.target.value,
            })
        }
    };

    const checkCaseName = (name) => {
        const regex = /^[a-zA-Z\u00C0-\u00FF0-9-_\x20]+$/
        if (name && !name.match(regex)) {
            setCaseNameError('Case name contains unacceptable characters.')
        }
    }

    const [caseNameError, setCaseNameError] = useState(null);
    const [caseNotesError, setCaseNotesError] = useState(null);
    const [manualCategoryError, setManualCategoryError] = useState(null);
    const [caseFileError, setCaseFileError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);
    const [creatingCaseId, setCreatingCaseId] = useState(null);
    const [shouldRetryUpload, setShouldRetryUpload] = useState(false);

    const getMaxIndex = (objects) => {
        if (!objects) { return 0 }
        return Math.max.apply(Math, objects.map(function(layer) { return layer.idx; }))
    }
    const getBlankLayer = (objects) => {
        const maxIndex = getMaxIndex(objects)
        return { idx: maxIndex + 1, file: '', real_file: null, layername: '', color: getRandomColor(), opacity: '100', errors: {} }
    }
    const [layers, setLayers] = useState([
        {...getBlankLayer()}
    ]);
    function isMaxTotalLayerSizeReached() {
        let totalSize = 0;
        for (let layer of layers) {
            if (layer.file && layer.real_file) {
                const layerSize = layer.real_file.size
                totalSize += (layerSize < 50 * 1000 * 1000) ? layerSize : (50 * 1000 * 1000)
            }
        }
        return totalSize > 250 * 1000 * 1000 ? Math.round(totalSize/(1000*1000)) : false;
    }
    const maxTotalLayerSizeReached = isMaxTotalLayerSizeReached()

    const cleanLayername = (targetLayer) => {
        if (targetLayer['layername'] === '') {
            delete targetLayer.errors['layername']
        }
        else {
            const regex = /^[a-zA-Z\u00C0-\u00FF0-9-_\x20]+$/
            let error;
            if (!targetLayer['layername'].match(regex)) {
                error = 'Layer name contains unacceptable characters.'
            }
            else if (layers.filter(obj => obj.idx !== targetLayer.idx).map(obj => obj.layername).includes(targetLayer['layername'])) {
                error = 'Layer name already in use.'
            }
            if (error) {
                targetLayer.errors['layername'] = error
            }
            else {
                delete targetLayer.errors['layername']
            }
        }
        return targetLayer
    }

    const handleLayerChange = (e) => {
        setNonFieldErrors([])
        const fieldtype = e.target.attributes.fieldtype.value
        let targetLayer = layers.find(obj => obj.idx.toString() === e.target.dataset.idx)
        targetLayer[fieldtype] = e.target.value;
        if (props.case_type === 'STL' && fieldtype === "file") {
            delete targetLayer.errors['file']
            const uploadedFile = e.target.files[0]
            if (uploadedFile) {
                const extension = uploadedFile.name.split('.').pop();
                if (extension.toUpperCase() !== 'STL') {
                    targetLayer.errors['file'] = 'Must upload an STL file.'
                }
                else if (uploadedFile.size > 200 * 1000 * 1000) {
                    targetLayer.errors['file'] = 'Max file size (200MB) exceeded.'
                }
                else {
                    targetLayer['real_file'] = uploadedFile;
                    if (!targetLayer['layername']) {
                        targetLayer['layername'] = uploadedFile.name.replace(/\.[^/.]+$/, "")
                    }
                }
            }
        }
        let updatedLayers = layers.map(layer => (layer.idx === targetLayer.idx) ? cleanLayername(targetLayer) : cleanLayername(layer))
        setLayers(updatedLayers)
    }
    const addLayer = () => {
        setLayers([...layers, {...getBlankLayer(layers)}]);
    };
    const removeLayer = (idx) => {
        const updatedLayers = layers.filter(layer => layer.idx !== idx)
        setLayers(updatedLayers);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        setLoadingCurrentStep(0)
        if (props.case_type === 'STL') {setLoadingTotalSteps(5)}
        if (props.case_type === 'custom') {setLoadingTotalSteps(4)}
        setLoadingStatus('Loading')
        setLoading(true);
        try {
            let create_case_id = creatingCaseId
            if (!shouldRetryUpload) {
                // FIRST STEP (case creation)
                setLoadingCurrentStep(1)
                setLoadingStatus('Creating case')
                let firstFormData = new FormData();
                for (const [idx, layer] of layers.entries()) {
                    firstFormData.append(`verinfo.layerinfo_set[${idx}]layer_name`, layer.layername);
                    firstFormData.append(`verinfo.layerinfo_set[${idx}]material_color`, get8DigitHex(layer.color, layer.opacity));
                    firstFormData.append(`verinfo.layerinfo_set[${idx}]material_type`, layer.opacity === '100' ? 'standard' : 'transparent');
                }
                if (props.case_type === 'custom') {
                    firstFormData.append("manual_category", caseState.manual_category);
                }
                firstFormData.append("verinfo.case_name", caseState.case_name);
                firstFormData.append("case_notes", caseState.case_notes);
                firstFormData.append("verinfo.series_number", 0);
                firstFormData.append("verinfo.deidentified_patient_id", 0);
                firstFormData.append("case_type", (props.case_type === 'custom') ? 'manual' : props.case_type);
                const firstResponse = await axios({
                    method: 'post',
                    url:  `${CASE_URL}create_without_input_file/`,
                    data: firstFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                create_case_id = firstResponse.data.id
                setCreatingCaseId(firstResponse.data.id)
                if (config.SHOULD_LOG) {console.log('firstResponse >>> ', firstResponse);}
            }

            try {
                // SECOND STEP (start direct upload)
                setLoadingCurrentStep(2)
                setLoadingStatus('Preparing file upload')
                let secondFormData = new FormData();
                secondFormData.append("for_field", "input_file");
                const secondResponse = await axios({
                    method: 'post',
                    url: `${CASE_URL}${create_case_id}/start_direct_upload/`,
                    data: secondFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                if (config.SHOULD_LOG) {console.log('secondResponse >>> ', secondResponse);}
    
                // THIRD STEP (do direct upload)
                let thirdFormData = new FormData()
                let uploadFileSize = 0
                thirdFormData.append("key", secondResponse.data.fields.key);
                thirdFormData.append("AWSAccessKeyId", secondResponse.data.fields.AWSAccessKeyId);
                thirdFormData.append("policy", secondResponse.data.fields.policy);
                thirdFormData.append("signature", secondResponse.data.fields.signature);
                if (props.case_type === 'STL') {
                    setLoadingCurrentStep(3)
                    setLoadingStatus('Compressing files')
                    const filesMap = {}
                    for (let layer of layers) {
                        filesMap[layer.layername + '.stl'] = await getAsByteArray(layer.real_file)
                    }
                    console.time('zip')
                    const zipped_0 = zipSync(filesMap, {level: 0});
                    let zippedblob_0 = new Blob([zipped_0])
                    console.timeEnd('zip')
                    thirdFormData.append("file", zippedblob_0, "input_file.zip");
                    uploadFileSize = zippedblob_0.size
                    setLoadingCurrentStep(4)
                }
                else if (props.case_type === 'custom') {
                    thirdFormData.append("file", caseState.case_realfile, "input_file.zip");
                    uploadFileSize = caseState.case_realfile.size
                    setLoadingCurrentStep(3)
                }
                setLoadingStatus('Uploading files')
                setUploading(true);
                var uploadStartTime = new Date();
                const thirdResponse = await axios({
                    method: 'post',
                    url: secondResponse.data.url,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: progressEvent => {setUploadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))},
                    transformRequest: [(data, headers) => {
                        delete headers.common.Authorization
                        return thirdFormData
                    }]
                })
                var uploadElapsedTime = new Date() - uploadStartTime;
                if (config.SHOULD_LOG) {console.log('thirdResponse >>> ', thirdResponse);}
    
                // FOURTH STEP (complete direct upload)
                if (props.case_type === 'STL') {setLoadingCurrentStep(5)}
                if (props.case_type === 'custom') {setLoadingCurrentStep(4)}
                setLoadingStatus('Completing case creation')
                let fourthFormData = new FormData()
                fourthFormData.append("key", secondResponse.data.fields.key);
                fourthFormData.append("input_file_size_from_client", uploadFileSize);
                fourthFormData.append("input_file_upload_time_from_client", uploadElapsedTime);
                fourthFormData.append("for_field", "input_file");
                const fourthResponse = await axios({
                    method: 'post',
                    url:  `${CASE_URL}${create_case_id}/complete_direct_upload/`,
                    data: fourthFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                if (config.SHOULD_LOG) {console.log('fourthResponse >>> ', fourthResponse);}
    
                addToast('success', "Your case has been created successfully!");
                navigate('/my-cases');
            }
            catch (error) {
                if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
                setShouldRetryUpload(true)
                setLoading(false);
                setUploading(false);
                setUploadingProgress(0)
                setLoadingStatus('')
                setLoadingCurrentStep(0)
                setLoadingTotalSteps(0)
            }
        }
        catch (error) {
            if (error.response?.data) {
                setCaseNameError(error.response.data.verinfo.case_name);
                setCaseNotesError(error.response.data.case_notes);
                setManualCategoryError(error.response.data.manual_category);
                setCaseFileError(error.response.data.input_file);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            if (config.SHOULD_LOG) {console.log('error >>> ', JSON.stringify(error));}
            setLoading(false);
            setUploading(false);
            setUploadingProgress(0)
            setLoadingStatus('')
            setLoadingCurrentStep(0)
            setLoadingTotalSteps(0)
        }
    }

    const compiledCaseName = () => caseState.case_name.length > 0
    const compiledCaseNotes = () => caseState.case_notes.length > 0
    const compiledCaseFile = () => caseState.case_file
    const compiledCaseFields = () => {
        if (props.case_type === 'STL') {
            return compiledCaseName()
        }
        else if (props.case_type === 'custom') {
            return compiledCaseName() && compiledCaseNotes() && compiledCaseFile()
        }
    }

    const emptyLayers = () => {
        return layers.some(layer => (!layer.layername || (props.case_type === 'STL' && !layer.file)))
    }

    const noErrors = () => {
        let layer_errors = false
        for (let layer of layers) {
            if (layer.errors.file || layer.errors.layername) {
                layer_errors = true
                break
            }
        }
        return !layer_errors && nonFieldErrors.length === 0 && !caseNameError && !caseFileError && !caseNotesError && !manualCategoryError;
    }

    const uploadButtonEnabled = () => {
        return !isLoading && compiledCaseFields() && !emptyLayers() && noErrors() && !maxTotalLayerSizeReached;
    }

    const getHelpingText = () => {
        if (props.case_type === 'custom' && !compiledCaseFile()) {
            return "To proceed to the realization of the case it is necessary to upload a case file."
        }
        else if  (props.case_type === 'custom' && !compiledCaseName()) {
            return "To proceed to the realization of the case it is necessary to give it a name."
        }
        else if  (props.case_type === 'custom' && !compiledCaseNotes()) {
            return "To proceed to the realization of the case it is necessary to fill its notes."
        }
        else if (props.case_type === 'custom' && emptyLayers()) {
            return "Fill in all the names of the layers of this case to proceed with the case creation."
        }
        else if (props.case_type === 'STL' && emptyLayers()) {
            return "Fill in all the fields in the layers of this case to proceed with the case creation."
        }
        else if (props.case_type === 'STL' && !compiledCaseName()) {
            return "To proceed to the realization of the case it is necessary to give it a name."
        }
        else if (!noErrors() || maxTotalLayerSizeReached) {
            return "One or more fields do not meet the requirements."
        }
        else if (isLoading) {
            return "We are in the process of creating the case, please wait..."
        }
        else if (shouldRetryUpload) {
            return "An error occurred during the case creation. Please click the button below to retry."
        }
        else {
            return `We're ready to generate your new ${props.case_type} case.`
        }
    }

    function deleteLayerButtonEnabled() {
        return layers.length > 1;
    }

    return (
        <div className="CreateCase" >
            <Container fluid className="h-100 w-100 p-5">
                <Form onSubmit={handleSubmit} className="h-100 row flex-nowrap">
                    <Col className="caseColumn pe-5" md={8}>
                        <p className="figma-h4-semibold primary-600 mb-2">Create new {props.case_type} case</p>
                        {props.case_type === 'STL' && <p className="figma-p1 neutral-600 mb-4">Create a new case from one or more STL files.<br />Set the number of layers you want and upload the files directly from your computer.</p>}
                        {props.case_type === 'custom' && <p className="figma-p1 neutral-600 mb-4">Upload the ZIP file containing the DICOM of the case <br />that requires customized segmentation.</p>}
                        <div className="nonfield-errors mt-1 w-100">
                            {nonFieldErrors.map((error) =>
                            <p key={error}>{error}</p>
                            )}
                        </div>
                        <div className="nonfield-errors mt-1 w-100">
                            {verErrors.map((error) =>
                            <p key={error.code}>{error.msg}</p>
                            )}
                        </div>
                        <Row className="mb-4">
                            {(props.case_type === 'custom') &&
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Case file</Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept=".zip"
                                            placeholder="Insert your case name here"
                                            name="case_file"
                                            id="case_file"
                                            value={caseState.case_file}
                                            onChange={handleCaseChange}
                                            isInvalid={(caseFileError) ? true : false}
                                            disabled={isLoading || shouldRetryUpload}
                                        />
                                        <Form.Control.Feedback type="invalid">{caseFileError}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            }
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Case name</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="text"
                                        placeholder="Insert your case name here"
                                        name="case_name"
                                        id="case_name"
                                        value={caseState.case_name}
                                        onChange={handleCaseChange}
                                        isInvalid={(caseNameError) ? true : false}
                                        disabled={isLoading || shouldRetryUpload}
                                    />
                                    <Form.Control.Feedback type="invalid">{caseNameError}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {(props.case_type === 'custom') &&
                                <>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                name="manual_category"
                                                id="manual_category"
                                                value={caseState.manual_category}
                                                onChange={handleCaseChange}
                                                isInvalid={(manualCategoryError) ? true : false}
                                                disabled={isLoading || shouldRetryUpload}
                                            >
                                                <option value="abdominal">Abdominal surgery</option>
                                                <option value="thoracic">Thoracic surgery</option>
                                                <option value="maxillofacial">Maxillofacial surgery</option>
                                                <option value="vascular">Vascular surgery</option>
                                                <option value="oncological">Oncological surgery</option>
                                                <option value="cardiac">Cardiac surgery</option>
                                                <option value="odontology">Odontology</option>
                                                <option value="orthopedics">Orthopedics</option>
                                                <option value="ophthalmology">Ophthalmology</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{manualCategoryError}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-5">
                                            <Form.Label>Case notes</Form.Label>
                                            <Form.Control
                                                placeholder="Add notes here"
                                                name="case_notes"
                                                id="case_notes"
                                                value={caseState.case_notes}
                                                onChange={handleCaseChange}
                                                isInvalid={(caseNotesError) ? true : false}
                                                as="textarea"
                                                aria-describedby="caseNotesHelpBlock"
                                                disabled={isLoading || shouldRetryUpload}
                                            />
                                            <Form.Control.Feedback type="invalid">{caseNotesError}</Form.Control.Feedback>
                                            <Form.Text id="caseNotesHelpBlock" muted>
                                                Add directions here for the operatives who will perform the segmentation. Remember to provide some information about the case.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </>
                            }
                        </Row>
                        <span className="figma-h5 primary-600">Set case layers</span>
                        {props.case_type === 'custom' && <p className="figma-p1 neutral-600 mb-4">Choose the number of layers you want to display for this case and indicate for each of them a name, a color and an opacity.</p>}
                        {
                            layers.map((layer) => {
                                const nameId = `layername-${layer.idx}`
                                const fileId = `file-${layer.idx}`
                                const colorId = `color-${layer.idx}`
                                const opacityId = `opacity-${layer.idx}`
                                return (
                                    <div key={`layer-${layer.idx}`} className="my-3">
                                        <Row>
                                            {   (props.case_type === 'STL') &&
                                                <Col className="col-4 flex-grow-1">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Layer file</Form.Label>
                                                        <Form.Control
                                                            size="sm"
                                                            type="file"
                                                            accept=".stl"
                                                            name={fileId}
                                                            data-idx={layer.idx}
                                                            id={fileId}
                                                            fieldtype="file"
                                                            value={layer.file}
                                                            onChange={handleLayerChange}
                                                            isInvalid={layer.errors.file}
                                                            disabled={isLoading || shouldRetryUpload}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{layer.errors.file}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            }
                                            <Col className={(props.case_type === 'STL') ? "col-4 flex-grow-1" : "col-6 flex-grow-1"}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Layer name</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        placeholder="Insert layer name"
                                                        name={nameId}
                                                        data-idx={layer.idx}
                                                        id={nameId}
                                                        fieldtype="layername"
                                                        value={layer.layername}
                                                        autoComplete="layername"
                                                        onChange={handleLayerChange}
                                                        isInvalid={layer.errors.layername}
                                                        disabled={isLoading || shouldRetryUpload}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{layer.errors.layername}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="flex-grow-0">
                                                <Row className="flex-nowrap">
                                                    <Col className="flex-grow-0">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Color</Form.Label>
                                                            <Form.Control
                                                                size="sm"
                                                                type="color"
                                                                name={colorId}
                                                                data-idx={layer.idx}
                                                                id={colorId}
                                                                fieldtype="color"
                                                                value={layer.color}
                                                                onChange={handleLayerChange}
                                                                disabled={isLoading || shouldRetryUpload}
                                                            />
                                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Opacity %</Form.Label>
                                                            <Form.Control
                                                                size="sm"
                                                                type="number"
                                                                min="0"
                                                                max="100"
                                                                name={opacityId}
                                                                data-idx={layer.idx}
                                                                id={opacityId}
                                                                fieldtype="opacity"
                                                                value={layer.opacity}
                                                                onChange={handleLayerChange}
                                                                disabled={isLoading || shouldRetryUpload}
                                                            />
                                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="d-flex align-items-center">
                                                        {deleteLayerButtonEnabled() &&
                                                        <Button type="button" variant="outline-primary" className="mt-3 remove-layer-button p-0" onClick={(e) => {removeLayer(layer.idx); e.target.blur()}} disabled={isLoading}>
                                                            <div className="d-block p-0 m-auto">
                                                                <FiTrash2 size="24px" />
                                                            </div>
                                                        </Button>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                        <div className="d-flex justify-content-center text-center my-4 w-100">
                            <Button type="button" variant="outline-primary" disabled={maxTotalLayerSizeReached || isLoading || shouldRetryUpload} className="d-flex justify-content-center align-items-center new-layer-button figma-p1-semibold" onClick={(e) => {addLayer(); e.target.blur()}}>
                                <FiPlusCircle /> Add new layer
                            </Button>
                        </div>
                    </Col>
                    <Col md={4} className="d-flex align-items-center justify-content-center text-primary ps-5">
                        <Row>
                            <Col md="12" className="text-center mb-5">
                                <Image fluid alt="No preview" className="p-auto" src={create_case_placeholder} />
                            </Col>
                            <Col md="12" className="text-center mb-5 neutral-500 px-5">{getHelpingText()}</Col>
                            <Col md="12" className="text-center px-5">
                                {isLoading
                                    ? <>
                                        {isUploading ? <div className="progressbar-container d-flex align-items-center"><ProgressBar variant="secondary" now={uploadingProgress} label={(uploadingProgress < 10) ? '' : `${uploadingProgress}%`} className="w-100" /></div> : <Spinner variant="secondary" animation="border" />}
                                        <div className="figma-p1-semibold neutral-600 mt-4 w-100">{loadingStatus}</div>
                                        {loadingTotalSteps > 1 && <div className="neutral-600 mt-1 w-100">{loadingCurrentStep}/{loadingTotalSteps}</div>}
                                    </>
                                    : <>
                                        {shouldRetryUpload
                                        ?
                                        <div className="w-100 mt-4">
                                            <Button size="lg" type="submit" className="py-3 px-5" disabled={!uploadButtonEnabled()}>Retry case creation</Button>
                                        </div>
                                        :
                                        <>
                                        <div className="w-100 mt-4">
                                            <Button size="lg" type="submit" className="py-3 px-5" disabled={!uploadButtonEnabled()}>Create case</Button>
                                        </div>
                                        <div className="w-100 mt-4">
                                            <Button variant="link" className="text-primary" onClick={() => setShowCancelCreationModal(true)}>Cancel</Button>
                                        </div>
                                        </>
                                        }
                                    </>
                                }
                                {maxTotalLayerSizeReached && 
                                    <div className="nonfield-errors mt-4 w-100">
                                        Total max file size exceeded. {config.SHOULD_LOG && <>({maxTotalLayerSizeReached}MB/250MB)</>}
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Container>
            <CancelCreationModal showCancelCreationModal={showCancelCreationModal} setShowCancelCreationModal={setShowCancelCreationModal} />
        </div>
    );
}
import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import './Sidebar.scss';


export default function LogoutModal({showLogoutModal, setShowLogoutModal, handleLogout}) {
    return (
        <Modal size='sm' centered show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
            <Modal.Body id="logout-modal">
                <Row className="text-center">
                    <Col md={12} className="d-flex justify-content-center">
                        <div className="modal-icon mb-4 bg-secondary-300 d-flex align-items-center">
                            <div className="d-block m-auto p-0"><FiLogOut className="primary-600" size="20px"/></div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1-semibold neutral-900">Log out</p>
                    </Col>
                    <Col md={12}>
                        <p className="figma-p1 neutral-500">Do you want to logout from Verima?</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="bg-neutral-50">
                <Row className="w-100 justify-content-between ">
                    <Col><Button variant="outline-primary" className="figma-p1-semibold py-2 w-100" onClick={(e) => {setShowLogoutModal(false); e.target.blur()}}>Cancel</Button></Col>
                    <Col><Button variant="primary" className="figma-p1-semibold py-2 w-100" onClick={() => {handleLogout()}}>Logout</Button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


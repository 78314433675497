import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Image, Modal } from "react-bootstrap";
import { FiShield, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { ReactComponent as TransferToUser } from '../../static/icons/user_transfer.svg';
import { DEFAULT_IMAGE_FALLBACK, addToast } from "../../utils/Common";
import { CASE_URL, ENTERPRISE_TEAM_MEMBERS_URL } from "../../utils/Endpoints";
import Loader from "../../utils/widgets/Loader/Loader";

export default function TransferModal({ setShowTransferModal, caseOwner, caseObj }) {
    const navigate = useNavigate()
    const [transferTo, setTransferTo] = useState(null)
    const [searchResults, setSearchResults] = useState([])
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);
    const [lastTimeout, setLastTimeout] = useState(null);

    async function fetchSearchResults(inputText) {
        setSearchLoading(true)
        try {
            const response = await axios({
                method: 'get',
                url: `${ENTERPRISE_TEAM_MEMBERS_URL}?registration_pending=false` + (inputText ? `&search=${inputText}` : ''),
            })
            setSearchResults(response.data.results)
        }
        catch(error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }

        setSearchLoading(false)
    }

    useEffect(() => {
        fetchSearchResults()
    }, [])

    async function handleSave() {
        setLoading(true)
        try {
            var formData = new FormData();
            formData.append("dest_customer", transferTo.id);
            await axios({
                method: 'post',
                url: `${CASE_URL}${caseObj.id}/transfer_case_to/`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            addToast('success', "Case ownership was changed successfully!", true);
            navigate('/')
        }
        catch (error) {
            addToast('error', "Case ownership transfer failed, please try again.", true);
            setLoading(false)
            if (config.SHOULD_LOG) { console.error('responseList error >>> ', error); }
        }
    }

    function customerClicked(e, isDisabled, customer) {
        e.preventDefault()
        if (!isDisabled) {
            setTransferTo(customer)
            setSearchFocus(false)
        }
    }

    return (
        <Modal centered backdrop="static" keyboard={false} /* scrollable */ size={loading ? "sm" : "lg"} show={true} onHide={() => setShowTransferModal(false)}>
            <Modal.Body id="transfer-modal" className="px-4 py-3">
                {
                    loading
                        ? (
                            <div className="p-5"><Loader /></div>
                        )
                        : (
                            <>
                                <div className="d-flex align-items-center mb-2">
                                    <div className="d-flex align-items-center justify-content-center bg-primary circular-icon-container">
                                        <TransferToUser className="neutral-50" />
                                    </div>
                                    <div className="ms-2 figma-p2-semibold primary-600">Change case owner</div>
                                </div>
                                <div className="figma-p1 neutral-500 mb-2">
                                    You can transfer your case ownership rights to another workspace member, only if they have enough space in their storage.
                                </div>
                                <div className="figma-p1-semibold neutral-500 mb-3">
                                Proceeding in this way be aware you are losing access to the case.
                                </div>
                                {transferTo
                                ?
                                    <div>
                                        <div className="mb-3 figma-p1 neutral-600">Transfer ownership to:</div>
                                        <div className="d-flex justify-content-between align-items-center neutral-600">
                                            <div className="d-flex align-items-center neutral-600">
                                                <div>
                                                    <Image className="profile-picture" fluid src={transferTo.profile_img_large} roundedCircle onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = DEFAULT_IMAGE_FALLBACK }} />
                                                </div>
                                                <div className="mx-3">
                                                    <div className="title figma-h6 mb-1 d-flex align-items-center">{transferTo.first_name} {transferTo.last_name} {transferTo.is_enterprise_manager && <FiShield size="20px" className="ms-2 flex-shrink-0"/>}</div>
                                                    <div className="subtitle figma-caption">{transferTo.email}</div>
                                                </div>
                                            </div>
                                            
                                            <div className="remove-icon-container h-100 d-flex align-items-center justify-content-center"  onClick={(e) => { setTransferTo(null) }}>
                                                <FiX className="cursor-pointer" size={'24px'} />
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <>
                                        <Form.Group>
                                            <Form.Label>Choose new case owner</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter name or e-mail"
                                                name="search_text"
                                                id="search_text"
                                                value={searchText}
                                                onChange={(e) => { setSearchText(e.target.value); clearTimeout(lastTimeout); setSearchLoading(true); setSearchResults([]); setLastTimeout(setTimeout(() => {fetchSearchResults(e.target.value)}, 1000))}}
                                                onFocus={() => setSearchFocus(true)}
                                                onBlur={() => setTimeout(() => { setSearchFocus(false) }, 200)}
                                                autoComplete="off"
                                                className={"d-inline" + (searchFocus ? " focused" : "")}
                                            />
                                        </Form.Group>
                                        {searchFocus &&
                                            <div className="search-box-container w-100">
                                                <Card className="search-box w-100 shadow">
                                                    {searchLoading
                                                        ? <div className="p-3"><Loader /></div>
                                                        : searchResults.length > 0
                                                            ?
                                                            searchResults.map((customer, idx) => {
                                                                const isCurrentOwner = caseOwner.id === customer.id
                                                                const hasFullStorage = customer.storage_used >= customer.storage_total
                                                                const isDisabled = isCurrentOwner || hasFullStorage
                                                                return (
                                                                    <div key={idx} className={"result-row d-flex justify-content-between align-items-center py-3 px-3 w-100" + (isDisabled ? ' disabled' : '')} onMouseDown={(e) => customerClicked(e, isDisabled, customer)} onClick={(e) => customerClicked(e, isDisabled, customer)}>
                                                                        <div className="d-flex align-items-center overflow-auto">
                                                                            <div>
                                                                                <Image className="profile-picture" fluid src={customer.profile_img_large} roundedCircle onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = DEFAULT_IMAGE_FALLBACK }} />
                                                                            </div>
                                                                            <div className="mx-3 overflow-auto">
                                                                                <div className="title figma-h6 mb-1 d-flex align-items-center">{customer.first_name} {customer.last_name} {customer.is_enterprise_manager && <FiShield size="20px" className="ms-2 flex-shrink-0"/>}</div>
                                                                                <div className="subtitle figma-caption">{customer.email}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="figma-caption-semibold neutral-400">
                                                                            {isCurrentOwner
                                                                            ?
                                                                                'Case owner'
                                                                            :
                                                                                hasFullStorage ? 'Full storage' : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div className="mx-3 figma-p1 neutral-400 line-height-66" >No results available</div>
                                                    }
                                                </Card>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        )
                }
            </Modal.Body>
            {!loading &&
                <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                    <Button variant="outline-primary" className="px-5" onClick={(e) => {setShowTransferModal(false); e.target.blur()}}>Cancel</Button>
                    <Button variant="primary" className="px-3" disabled={!transferTo} onClick={() => {handleSave()}}>Change ownership</Button>
                </Modal.Footer>
            }
        </Modal>
    );
}


import React from "react";
import { Col, Row, Modal, Button} from "react-bootstrap";
import './CaseList.scss';
import {WEBSITE_URL} from '../../utils/Endpoints'


export default function MoreInfoModal({showMoreInfoModal, setShowMoreInfoModal}) {
    return (
        <Modal centered show={showMoreInfoModal} onHide={() => setShowMoreInfoModal(false)}>
            <Modal.Body id="more-info-modal">
                <Row>
                    <div className="figma-p2-semibold primary-600">What is Verima Desk?</div>
                    <div className="figma-p1 neutral-600 mt-2"><span className="figma-p1-semibold">Verima Desk</span> is the web platform for viewing, managing and sharing clinical cases associated with your Verima account.</div>
                    <div><div className="horizontal-line my-3"></div></div>
                    <div className="figma-p2-semibold primary-600">Why don't I have any case?</div>
                    <div className="figma-p1 neutral-600 mt-2">If you do not see any case, it is because you do not have any cases shared with you. <span className="figma-p1-semibold">Try asking your healthcare provider</span> for more information and request sharing via Verima.</div>
                    <div className="figma-p1 neutral-600 mt-3 mb-2">If you want more information about verima services visit our website.</div>
                </Row>
            </Modal.Body>
            <Modal.Footer className="bg-neutral-50">
                <Row className="w-100 justify-content-between ">
                    <Col><Button variant="outline-primary" className="figma-p1-semibold py-2 w-100" onClick={(e) => {setShowMoreInfoModal(false); e.target.blur()}}>Close</Button></Col>
                    <Col><a className="btn btn-primary figma-p1-semibold py-2 w-100" href={WEBSITE_URL} target="_blank" rel="noreferrer">Go to verima.it</a></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}



import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
import Macro from '@kitware/vtk.js/macros';

/* eslint-disable no-lonely-if */

// ----------------------------------------------------------------------------
// customInteractorStyle methods
// ----------------------------------------------------------------------------

function customInteractorStyle(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('customInteractorStyle');

  // Disabling keypress handler
  publicAPI.handleKeyPress = () => {};
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, initialValues);

  // Inheritance
  vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

  // Object specific methods
  customInteractorStyle(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = Macro.newInstance(
  extend,
  'customInteractorStyle'
);

// ----------------------------------------------------------------------------

const exportable = { newInstance, extend };

export default exportable
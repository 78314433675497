import { FiExternalLink } from 'react-icons/fi';
import { COOKIE_POLICY_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../../utils/Endpoints';
import '../Profile.scss';

export default function LegalNotes() {

    return (
        <div className="LegalNotes h-100 d-flex flex-column" >
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Privacy</span>
                <div className="d-flex justify-content-between align-items-start">
                    <span className="figma-p1 neutral-600">Please be aware of the information we collect, why we collect it, and how you can update, manage, export, and delete it.</span>
                    <a href={PRIVACY_POLICY_URL} target='_blank' rel='noreferrer' className="ms-5 figma-p1-semibold primary-600 d-flex justify-content-between align-items-center not-underlined flex-shrink-0">Read more<FiExternalLink size={'16px'} className='ms-2' /></a>
                </div>
            </div>
            <div className='horizontal-line my-4'></div>
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Terms of service</span>
                <div className="d-flex justify-content-between align-items-start">
                    <span className="figma-p1 neutral-600">By using our software, you agree to abide by our legal terms and conditions. It is a contract between you and us, please make sure you understand it in its entirety.</span>
                    <a href={TERMS_OF_SERVICE_URL} target='_blank' rel='noreferrer' className="ms-5 figma-p1-semibold primary-600 d-flex justify-content-between align-items-center not-underlined flex-shrink-0">Read more<FiExternalLink size={'16px'} className='ms-2' /></a>
                </div>
            </div>
            <div className='horizontal-line my-4'></div>
            <div className="d-flex flex-column">
                <span className="figma-p3-semibold primary-600 mb-3">Cookie policy</span>
                <div className="d-flex justify-content-between align-items-start">
                    <span className="figma-p1 neutral-600">We store small text files on your device/PC when you access our app or visit our website. Get to know why.</span>
                    <a href={COOKIE_POLICY_URL} target='_blank' rel='noreferrer' className="ms-5 figma-p1-semibold primary-600 d-flex justify-content-between align-items-center not-underlined flex-shrink-0">Read more<FiExternalLink size={'16px'} className='ms-2' /></a>
                </div>
            </div>
        </div>
    );
}
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Container, Form, Modal, OverlayTrigger, Pagination, Popover, PopoverBody, Row } from "react-bootstrap";
import { FiArchive, FiBox, FiBriefcase, FiCalendar, FiPlusCircle, FiShare2, FiShield, FiUser, FiUsers } from "react-icons/fi";
import { useNavigate, useOutletContext } from "react-router-dom";
import config from '../../config';
import { ReactComponent as CustomSegmentationsIcon } from '../../static/icons/custom_segmentations.svg';
import { ReactComponent as SharingRoomIcon } from '../../static/icons/sharing_room.svg';
import { ReactComponent as ToolIcon } from '../../static/icons/tool.svg';
import { ReactComponent as ToolAiModuleIcon } from '../../static/icons/tool_ai_module.svg';
import { addToast, getDateString } from "../../utils/Common";
import { ENTERPRISE_ADD_MEMBER_TO_TEAM, ENTERPRISE_CUSTOM_SEGMENTATIONS_LIST_URL, ENTERPRISE_REMOVE_MEMBER_FROM_TEAM, ENTERPRISE_SET_TOOL, ENTERPRISE_TEAM_MEMBERS_URL, ENTERPRISE_WORKSPACE_URL, LICENSE_URL, WEBSITE_CONTACT_US_URL } from "../../utils/Endpoints";
import Loader from "../../utils/widgets/Loader/Loader";
import CustomSegmentationsTable from './CustomSegmentationsTable/CustomSegmentationsTable';
import './EnterpriseWorkspace.scss';
import TeamMembersTable from "./TeamMembersTable/TeamMembersTable";
import TransferTeamMembersTable from "./TransferTeamMembersTable/TransferTeamMembersTable";


export default function EnterpriseWorkspace(props) {
    const outletContext = useOutletContext()
    const navigate = useNavigate()
    const pageSize = 15

    const [selectedTab, setSelectedTab] = useState(0);

    const [enterpriseObj, setEnterpriseObj] = useState(null)
    const [enterpriseManagerLicense, setEnterpriseManagerLicense] = useState(null)
    const [enterpriseManager, setEnterpriseManager] = useState(null)
    const [enterpriseMemberList, setEnterpriseMemberList] = useState([])
    const [isEditingWorkspaceName, setIsEditingWorkspaceName] = useState(false)
    
    const [loading, setLoading] = useState(true)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)

    const resetPaginatorDefaultValues = () => {
        setPageCount(0)
        setDataCount(0)
        setPageIndex(0)
    }

    const [customSegmentationsList, setCustomSegmentationsList] = useState([])

    const fetchTeamMembers = useCallback(async ({ pageSize, pageIndex }) => {
        setLoadingMembers(true)
        setEnterpriseMemberList([])
        const requestConfig = {
            method: 'get',
            url: ENTERPRISE_TEAM_MEMBERS_URL,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            let responseData = response.data.results
            setEnterpriseMemberList(responseData)
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setEnterpriseMemberList([])
        }
        setLoadingMembers(false)
    }, [])

    const [availableAIBundles, setAvailableAIBundles] = useState(enterpriseObj ? enterpriseObj.ai_bundles.filter((bundle)=> bundle.assigned_to === null) : [])
    const [availableTools, setAvailableTools] = useState(enterpriseObj ? (enterpriseObj.enterprise_tools - enterpriseObj.assigned_tools) : 0)


    const [editingTeamMemberInitialTool, setEditingTeamMemberInitialTool] = useState(false)
    const [editingTeamMemberInitialBundle, setEditingTeamMemberInitialBundle] = useState('')
    const [editingTeamMemberID, setEditingTeamMemberID] = useState(false)
    const [editingTeamMemberTool, setEditingTeamMemberTool] = useState(false)
    const [editingTeamMemberBundle, setEditingTeamMemberBundle] = useState('')
    const [editingTeamMemberToolError, setEditingTeamMemberToolError] = useState(null)
    const [editingTeamMemberBundleError, setEditingTeamMemberBundleError] = useState(null)
    const [editingTeamMemberNonFieldErrors, setEditingTeamMemberNonFieldErrors] = useState([]);

    const [deletingTeamMember, setDeletingTeamMember] = useState(false)
    
    const [loadingEditTeamMember, setLoadingEditTeamMember] = useState(false)
    const columns = useMemo(() => {
        function resetEditingTeamMember() {
            setEditingTeamMemberID(false)
            setEditingTeamMemberTool(false)
            setEditingTeamMemberInitialTool(false)
            setEditingTeamMemberBundle('')
            setEditingTeamMemberInitialBundle('')
            setAvailableAIBundles(enterpriseObj.ai_bundles.filter((bundle)=> bundle.assigned_to === null))
            setAvailableTools(enterpriseObj.enterprise_tools - enterpriseObj.assigned_tools)
        }

        const handleEditTeamMember = async () => {
            setLoadingEditTeamMember(true)
            var formData = new FormData();
            formData.append("customer", editingTeamMemberID);
            formData.append("tool", editingTeamMemberTool);
            formData.append("ai_modules_bundle", editingTeamMemberBundle);
            try {
                const response = await axios({
                    method: 'post',
                    url: ENTERPRISE_SET_TOOL,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                if (config.SHOULD_LOG) { console.log('response >>> ', response); }
                addToast('success', 'The team member was successfully updated.', true);
                resetEditingTeamMember()
                await fetchData()
            }
            catch (error) {
                if (error.response?.data) {
                    setEditingTeamMemberToolError(error.response.data.tool.ver_error ? error.response.data.tool.ver_error[0].msg : error.response.data.tool);
                    setEditingTeamMemberBundleError(error.response.data.ai_modules_bundle);
                    setEditingTeamMemberNonFieldErrors(error.response.data.non_field_errors || []);
                }
                if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
            }
            setLoadingEditTeamMember(false)
        }
    
        function startEditing ( original_row ) {
            setEditingTeamMemberID(original_row.id)
            setEditingTeamMemberTool(original_row.has_tool)
            setEditingTeamMemberInitialTool(original_row.has_tool)
            const bundle = enterpriseObj.ai_bundles.find((bundle) => bundle.assigned_to === `${LICENSE_URL}${original_row.license_id}/`)
            if (bundle) {
                let selectedBundle = availableAIBundles.find((availableAIbundle) => bundle.name === availableAIbundle.name)
                if (!selectedBundle) {
                    selectedBundle = bundle
                }
                setAvailableAIBundles([...availableAIBundles, bundle])
                setEditingTeamMemberBundle(selectedBundle.id)
                setEditingTeamMemberInitialBundle(selectedBundle.id)
            }
            if (original_row.has_tool) {
                setAvailableTools(availableTools + 1)
            }
        }
    
        const availableAIBundlesOptions = availableAIBundles.reduce((current, bundle) => {
            const bundle_value = bundle.id
            const bundle_name = bundle.name
            const sameBundleIndex = current.findIndex(current_bundle => current_bundle.label === bundle_name)
            if (sameBundleIndex === -1) {
                current.push({value: bundle_value, label: bundle_name, count: 1})
            }
            else {
                current[sameBundleIndex].count += 1
            }
            return current
        }, [{value: '', label: 'No module', count: false}])        


        return [
            {
                Header: 'TEAM MEMBERS',
                accessor: '',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    const assigned_bundle = enterpriseObj.ai_bundles.find((bundle) => bundle.assigned_to === `${LICENSE_URL}${original_row.license_id}/`)
                    return (
                        <div className={"d-flex w-100 justify-content-start align-items-center me-2 " + (original_row.registration_pending ? "neutral-400" : "neutral-600")}>
                            <div className="d-flex flex-column position-relative">
                                <div className="mb-1 d-flex align-items-center figma-p2-semibold">
                                    {original_row.is_enterprise_manager ? <FiShield size="20px" className='flex-shrink-0' /> : <FiUser size="20px" className='flex-shrink-0' />}
                                    <div className="ms-2">{original_row.registration_pending ? original_row.email : <>{original_row.first_name} {original_row.last_name}</>}</div>
                                    <div>{original_row.has_tool && <Badge pill className='mx-2 figma-caption-semibold px-3 py-1' bg='secondary' text='primary'>TOOL</Badge>}</div>
                                    <div className='me-3'>{assigned_bundle &&
                                        <OverlayTrigger
                                            placement={"bottom"}
                                            overlay={
                                                <Popover className="">
                                                    <PopoverBody className="py-1">
                                                        {assigned_bundle.name}
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <div><ToolAiModuleIcon size="20px" /></div>
                                        </OverlayTrigger>
                                    }
                                    </div>
                                </div>
                                <div className="figma-p1 neutral-400 subtext">{original_row.registration_pending ? 'Pending account' : original_row.email}</div>
                                {(editingTeamMemberID === original_row.id) &&
                                    <EditCustomerForm editingTeamMemberTool={editingTeamMemberTool} availableTools={availableTools} setEditingTeamMemberTool={setEditingTeamMemberTool} setEditingTeamMemberToolError={setEditingTeamMemberToolError} setEditingTeamMemberNonFieldErrors={setEditingTeamMemberNonFieldErrors} setEditingTeamMemberBundle={setEditingTeamMemberBundle} editingTeamMemberToolError={editingTeamMemberToolError} editingTeamMemberBundleError={editingTeamMemberBundleError} setEditingTeamMemberBundleError={setEditingTeamMemberBundleError} editingTeamMemberBundle={editingTeamMemberBundle} availableAIBundlesOptions={availableAIBundlesOptions} editingTeamMemberNonFieldErrors={editingTeamMemberNonFieldErrors} />
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'CUSTOM SEGMENTATIONS',
                accessor: 'custom_used',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    return (
                        <div className={"w-100 " + (original_row.registration_pending ? "neutral-400" : "neutral-600")}>
                            {original_row.custom_used}
                        </div>
                    )
                }
            },
            {
                Header: 'STORAGE',
                accessor: '',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    return (
                        <div className={"w-100 " + (original_row.registration_pending ? "neutral-400" : "neutral-600")}>
                            {original_row.storage_used}/{original_row.storage_total}
                        </div>
                    )
                }
            },
            {
                Header: 'SHARING',
                accessor: '',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    return (
                        <div className={"w-100 " + (original_row.registration_pending ? "neutral-400" : "neutral-600")}>
                            {original_row.sharing_used}/{original_row.sharing_total}
                        </div>
                    )
                }
            },
            {
                Header: '',
                accessor: 'is_account_manager',
                Cell: cell => {
                    const original_row = cell.row.original
                    if (editingTeamMemberID === original_row.id) {
                        return (
                            <>
                                {loadingEditTeamMember
                                ?
                                    <Loader />
                                :
                                    <div>
                                        <div className="d-flex h-100 justify-content-end align-items-center">
                                            <Button size="sm" type="button" variant="outline-primary" className="me-2 px-4" onClick={() => { resetEditingTeamMember() }}>
                                                <div className="d-block p-0 m-auto">
                                                    Cancel
                                                </div>
                                            </Button >
                                            <Button size="sm" type="button" variant="primary" className="px-4"  onClick={() => { handleEditTeamMember() }} disabled={((editingTeamMemberTool === editingTeamMemberInitialTool) && (editingTeamMemberBundle === editingTeamMemberInitialBundle)) || editingTeamMemberToolError || editingTeamMemberBundleError || editingTeamMemberNonFieldErrors.length > 0}>
                                                <div className="d-block p-0 m-auto">
                                                    Save
                                                </div>
                                            </Button >
                                        </div>
                                    </div>
                                }
                            </>
                        ) 
                    }
                    else {
                        return (
                            <div>
                                {!editingTeamMemberID &&
                                    <div className="d-flex h-100 justify-content-end align-items-center">
                                        {!original_row.is_enterprise_manager &&
                                            <Button size="sm" type="button" variant="outline-primary" className="me-2 px-4" onClick={() => { setDeletingTeamMember(original_row) }} disabled={isEditingWorkspaceName}>
                                                <div className="d-block p-0 m-auto">
                                                    Remove
                                                </div>
                                            </Button >
                                        }
                                        <Button size="sm" type="button" variant="outline-primary" className="px-4" disabled={isEditingWorkspaceName} onClick={() => {startEditing(original_row)}}>
                                            <div className="d-block p-0 m-auto">
                                                Edit
                                            </div>
                                        </Button >
                                    </div>
                                }
                            </div>
                        )
                    }
                }
            },
        ]
    }, [editingTeamMemberID, editingTeamMemberTool, availableTools, editingTeamMemberToolError, editingTeamMemberBundleError, editingTeamMemberBundle, editingTeamMemberNonFieldErrors, editingTeamMemberInitialTool, editingTeamMemberInitialBundle, isEditingWorkspaceName, enterpriseObj, availableAIBundles, loadingEditTeamMember])

    async function fetchData() {
        setLoading(true)
        try {
            if (!outletContext.user.plan === '3') {
                navigate("/")
            }
            else {
                try {
                    const license_response = await axios({
                        method: 'get',
                        url: outletContext.user.customer.license,
                    })
                    setEnterpriseManagerLicense(license_response.data)
                    try {
                        const enterprise_response = await axios({
                            method: 'get',
                            url: `${ENTERPRISE_WORKSPACE_URL}`,
                        })
                        setEnterpriseObj(enterprise_response.data)
                        setAvailableAIBundles(enterprise_response.data.ai_bundles.filter((bundle)=> bundle.assigned_to === null))
                        setAvailableTools(enterprise_response.data.enterprise_tools - enterprise_response.data.assigned_tools)
                        try {
                            const enterprise_manager_response = await axios({
                                method: 'get',
                                url: enterprise_response.data.enterprise_manager,
                            })
                            setEnterpriseManager(enterprise_manager_response.data)
                            try {
                                const enterprise_member_response = await axios({
                                    method: 'get',
                                    url: `${ENTERPRISE_TEAM_MEMBERS_URL}${outletContext.user.customer.id}/`,
                                })
                                if (!enterprise_member_response.data.is_enterprise_manager) {
                                    navigate("/")
                                }
                                else {
                                    setLoading(false)
                                }
                            }
                            catch (error) {
                                if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                            }
                        }
                        catch (error) {
                            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                        }
                    }
                    catch (error) {
                        if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                    }
                }
                catch (error) {
                    if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
                }
            }
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
        <div className="EnterpriseWorkspace">
            {deletingTeamMember
            ?
                <DeleteTeamMemberPage fetchData={fetchData} deletingTeamMember={deletingTeamMember} setDeletingTeamMember={setDeletingTeamMember} enterpriseObj={enterpriseObj} />
            :
                <>
                    <Container fluid className="main-container w-100 p-4 pb-0">
                        <div className="d-flex flex-column h-100">
                            <p className="figma-h4-semibold primary-600 mb-5">Enterprise Workspace</p>
                            {(enterpriseObj?.enterprise_name) &&
                                <div className="tabs-box d-flex primary-600">
                                    <div className={"tab" + (selectedTab === 0 ? ' selected': '')} onClick={() => {setSelectedTab(0); resetPaginatorDefaultValues()}}><span>Workspace details</span></div>
                                    <div className={"tab" + (selectedTab === 1 ? ' selected': '')} onClick={() => {setSelectedTab(1); resetPaginatorDefaultValues()}}><span>Workspace segmentation history</span></div>
                                    <div className={"highlight-line selected-tab-" + selectedTab}></div>
                                </div>
                            }
                            <div className="inner-container w-100 h-100 ms-auto me-auto d-flex flex-column">
                                {loading
                                    ?
                                        <Loader />
                                    :
                                        <>
                                            {!enterpriseObj.enterprise_name
                                            ?
                                                <FirstConfigurationForm enterpriseObj={enterpriseObj} setEnterpriseObj={setEnterpriseObj} />
                                            :
                                            <>
                                                <WorkspaceDetailsTabPanel license={enterpriseManagerLicense} selectedTab={selectedTab} columns={columns} enterpriseObj={enterpriseObj} enterpriseManager={enterpriseManager} enterpriseMemberList={enterpriseMemberList} fetchTeamMembers={fetchTeamMembers} loadingMembers={loadingMembers} pageCount={pageCount} pageIndex={pageIndex} pageSize={pageSize} dataCount={dataCount} fetchData={fetchData} isEditingWorkspaceName={isEditingWorkspaceName} setIsEditingWorkspaceName={setIsEditingWorkspaceName} editingTeamMemberID={editingTeamMemberID} setEditingTeamMemberID={setEditingTeamMemberID} availableTools={availableTools} availableAIBundles={availableAIBundles} />
                                                <WorkspaceSegmentationHistoryTabPanel  selectedTab={selectedTab} customSegmentationsList={customSegmentationsList} setCustomSegmentationsList={setCustomSegmentationsList} setPageCount={setPageCount} setDataCount={setDataCount} pageCount={pageCount} pageIndex={pageIndex} enterpriseObj={enterpriseObj} />
                                            </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </Container>
                    {!loading && !loadingMembers &&
                        <Paginator pageCount={pageCount} pageIndex={pageIndex} setPageIndex={setPageIndex} dataCount={dataCount} pageLength={(selectedTab === 0) ? enterpriseMemberList.length : customSegmentationsList.length} pageSize={pageSize}/>
                    }
                </>
            }
        </div>
        </>
    );
}

function DeleteTeamMemberPage({fetchData, deletingTeamMember, setDeletingTeamMember, enterpriseObj}) {
    const [loading, setLoading] = useState(false)
    const [stopSharing, setStopSharing] = useState(false)
    const [transferTo, setTransferTo] = useState('')
    const [deletePreference, setDeletePreference] = useState(null)

    /* TRANSFER INTERNALLY BLOCK VARIABLES */
    const pageSize = 15
    const [pageCount, setPageCount] = useState(0)
    const [dataCount, setDataCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [enterpriseMemberList, setEnterpriseMemberList] = useState([])
    
    /* TRANSFER EXTERNALLY BLOCK VARIABLES */
    const [transferExternallyEmailError, setTransferExternallyEmailError] = useState(null)
    const [transferExternallyNonFieldErrors, setTransferExternallyNonFieldErrors] = useState([])
    
    const someCases = deletingTeamMember.storage_used > 0

    const handleDeletePreferenceChange = (e) => {
        if (e.target.value === 'on') {
            setDeletePreference(e.target.attributes.preference.value)
            resetDefaultValues()
        }
    }

    const resetDefaultValues = () => {
        setTransferTo('')
        setPageCount(0)
        setDataCount(0)
        setPageIndex(0)
        setEnterpriseMemberList([])
        setTransferExternallyEmailError(null)
        setTransferExternallyNonFieldErrors([])
    }

    const removeTeamMember = async () => {
        setLoading(true)
        var formData = new FormData();
        formData.append("customer", deletingTeamMember.id);
        formData.append("stop_sharing", stopSharing);
        formData.append("transfer_to", transferTo);
        formData.append("transfer_externally", (deletePreference === 'transfer_to_new'));
        try {
            const response = await axios({
                method: 'post',
                url: ENTERPRISE_REMOVE_MEMBER_FROM_TEAM,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            if (deletePreference === 'delete_permanently' || deletePreference === null) {
                addToast('success', 'The team member has been successfully removed.', true);
            }
            else {
                addToast('success', 'The user has been successfully removed, and the cases have been transferred.', true);
            }
            await fetchData()
            setDeletingTeamMember(false)
        }
        catch (error) {
            if (deletePreference === 'transfer_to_new') {
                if (error.response?.data) {
                    setTransferExternallyEmailError(error.response.data.transfer_to);
                    setTransferExternallyNonFieldErrors(error.response.data.non_field_errors || []);
                }
                setLoading(false)
            }
            else {
                setDeletingTeamMember(false)
                addToast('error', 'Something went wrong. Please try again.', true);
            }
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    const removeButtonEnabled = () => {
        let requirements_satisfied = (deletePreference === 'delete_permanently') || transferTo !== ''
        const errors = transferExternallyEmailError || transferExternallyNonFieldErrors.length > 0
        return (!someCases || (deletePreference && requirements_satisfied)) && !errors
    }

    return (
        <>
            <Container fluid className="main-container w-100 p-4 pb-0">
                <div className="d-flex flex-column h-100">
                    <div className="d-flex align-items-start mb-5">
                        <div className="figma-h4-semibold primary-600 me-auto">Remove account</div>
                        {!loading &&
                            <>
                                <Button size="sm" type="button" variant="outline-primary" className="me-2 px-4" onClick={() => { setDeletingTeamMember(false) }}>
                                    <div className="d-block p-0 m-auto">
                                        Cancel
                                    </div>
                                </Button >
                                <Button size="sm" type="button" variant="primary" className="px-4"  onClick={() => { removeTeamMember() }} disabled={!removeButtonEnabled()}>
                                    <div className="d-block p-0 m-auto">
                                        Remove
                                    </div>
                                </Button >
                            </>
                        }
                    </div>
                    <div className="inner-container w-100 h-100 ms-auto me-auto d-flex flex-column">
                        {loading
                        ?
                            <Loader />
                        :
                            <div className='tight-column neutral-600'>
                                <div className="figma-p3-semibold mb-2">Team member removal</div>
                                <div className='figma-p1'>You are removing <span className='figma-p1-semibold'>{deletingTeamMember.email}</span> from the  <span className='figma-p1-semibold'>{enterpriseObj.enterprise_name}</span> workspace, this will result in switching their license to <span className='figma-p1-semibold'>Free</span> and losing <span className='figma-p1-semibold'>Verima Tool</span> use permissions, if they had any.</div>
                                <div className="horizontal-line my-4"></div>
                                <div className="figma-p3-semibold mb-2">Manage their shared cases within the workspace</div>
                                <div className='figma-p1'>By stopping the case sharing with them, they won't be able to access them anymore.</div>
                                <div className="d-flex align-items-center mt-4" >
                                    <label htmlFor='stop_sharing' className="figma-p2-semibold primary-600">Stop sharing cases</label>
                                    <Form.Check
                                        type="switch"
                                        id="stop_sharing"
                                        name="stop_sharing"
                                        className="d-inline ms-2 fs-5"
                                        checked={stopSharing}
                                        onChange={(e) => { setStopSharing(e.target.checked)}}
                                    />
                                </div>
                                {someCases &&
                                    <>
                                        <div className="horizontal-line my-4"></div>
                                        <div className="figma-p3-semibold mb-2">How do you prefer to manage their cases?</div>
                                        <div className='figma-p1 mb-4'>The user you are removing has currently <span className='figma-p1-semibold'>{deletingTeamMember.storage_used} cases</span> in their Storage. The cases can be deleted or transfered when switching their license to Free.</div>
                                        <div className='figma-p2-semibold mb-3'>
                                            <Form.Check
                                                id='delete_permanently'
                                                preference="delete_permanently"
                                                checked={deletePreference === 'delete_permanently'}
                                                label={<span>Delete their cases permanently</span>}
                                                type='radio'
                                                name="deletePreference"
                                                className='form-check-container d-flex align-items-center mb-3'
                                                onChange={handleDeletePreferenceChange}
                                            />
                                            <Form.Check
                                                id="transfer_internally"
                                                preference="transfer_internally"
                                                checked={deletePreference === 'transfer_internally'}
                                                label={<span>Transfer their cases to another workspace member</span>}
                                                type='radio'
                                                name="deletePreference"
                                                className='form-check-container d-flex align-items-center mb-3'
                                                onChange={handleDeletePreferenceChange}
                                            />
                                            <Form.Check
                                                id="transfer_to_new"
                                                preference="transfer_to_new"
                                                checked={deletePreference === 'transfer_to_new'}
                                                label={<span>Add a new account within the workspace and transfer their cases to them</span>}
                                                type='radio'
                                                name="deletePreference"
                                                className='form-check-container d-flex align-items-center mb-4'
                                                onChange={handleDeletePreferenceChange}
                                            />
                                        </div>
                                        {deletePreference &&
                                            <>
                                                {(deletePreference === 'delete_permanently') &&
                                                    <span className='figma-p1'>Cases will be <span className='figma-p1-semibold'>permanently deleted</span>, also from the <span className='figma-p1-semibold'>Shared with me</span> section of other accounts that belong to the workspace.</span>
                                                }
                                                {(deletePreference === 'transfer_internally') &&
                                                    <TransferInternallyBlock transferTo={transferTo} setTransferTo={setTransferTo} enterpriseObj={enterpriseObj} deletingTeamMember={deletingTeamMember} enterpriseMemberList={enterpriseMemberList} setEnterpriseMemberList={setEnterpriseMemberList} setPageCount={setPageCount} setDataCount={setDataCount} pageCount={pageCount} pageIndex={pageIndex} setPageIndex={setPageIndex} pageSize={pageSize} />
                                                }
                                                {(deletePreference === 'transfer_to_new') &&
                                                    <TransferExternallyBlock transferTo={transferTo} setTransferTo={setTransferTo} enterpriseObj={enterpriseObj} transferExternallyEmailError={transferExternallyEmailError} setTransferExternallyEmailError={setTransferExternallyEmailError} transferExternallyNonFieldErrors={transferExternallyNonFieldErrors} setTransferExternallyNonFieldErrors={setTransferExternallyNonFieldErrors} />
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Container>
            {(deletePreference === 'transfer_internally') && !loading &&
                <Paginator pageCount={pageCount} pageIndex={pageIndex} setPageIndex={setPageIndex} dataCount={dataCount} pageLength={enterpriseMemberList.length} pageSize={pageSize} />
            }
        </>
    )
}

function TransferInternallyBlock({transferTo, setTransferTo, enterpriseObj, deletingTeamMember, enterpriseMemberList, setEnterpriseMemberList, setPageCount, setDataCount, pageCount, pageIndex, setPageIndex, pageSize}) {
    const [loadingMembers, setLoadingMembers] = useState(false)

    const fetchTeamMembers = useCallback(async ({ pageSize, pageIndex }) => {
        setLoadingMembers(true)
        setEnterpriseMemberList([])
        const requestConfig = {
            method: 'get',
            url: ENTERPRISE_TEAM_MEMBERS_URL,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            let responseData = response.data.results
            setEnterpriseMemberList(responseData)
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setEnterpriseMemberList([])
        }
        setLoadingMembers(false)
    }, [])

    const columns = useMemo(() => {
        return [
            {
                Header: 'TEAM MEMBERS',
                accessor: '',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    const isDeletionTarget = original_row.id === deletingTeamMember.id
                    const assigned_bundle = enterpriseObj.ai_bundles.find((bundle) => bundle.assigned_to === `${LICENSE_URL}${original_row.license_id}/`)
                    const notEnoughStorage = (original_row.storage_used + deletingTeamMember.storage_used) > original_row.storage_total
                    let subtext = original_row.email
                    if (original_row.registration_pending) {
                        subtext = 'Pending account'
                    }
                    if (isDeletionTarget) {
                        subtext = 'Account being removed'
                    }
                    return (
                        <div className={"d-flex w-100 justify-content-start align-items-center me-2 " + ((original_row.registration_pending || notEnoughStorage || isDeletionTarget) ? "neutral-400" : "neutral-600")}>
                            <div className="d-flex flex-column position-relative">
                                <div className="mb-1 d-flex align-items-center figma-p2-semibold">
                                    {original_row.is_enterprise_manager ? <FiShield size="20px" className='flex-shrink-0' /> : <FiUser size="20px" className='flex-shrink-0' />}
                                    <div className="ms-2">{original_row.registration_pending ? original_row.email : <>{original_row.first_name} {original_row.last_name}</>}</div>
                                    <div>{original_row.has_tool && <Badge pill className='mx-2 figma-caption-semibold px-3 py-1' bg='secondary' text='primary'>TOOL</Badge>}</div>
                                    <div className='me-3'>{assigned_bundle &&
                                        <OverlayTrigger
                                            placement={"bottom"}
                                            overlay={
                                                <Popover className="">
                                                    <PopoverBody className="py-1">
                                                        {assigned_bundle.name}
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <div><ToolAiModuleIcon size="20px" /></div>
                                        </OverlayTrigger>
                                    }
                                    </div>
                                </div>
                                <div className="figma-p1 neutral-400 subtext">{subtext}</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'STORAGE',
                accessor: '',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    const isDeletionTarget = original_row.id === deletingTeamMember.id
                    const notEnoughStorage = (original_row.storage_used + deletingTeamMember.storage_used) > original_row.storage_total
                    return (
                        <div className={"w-100 " + ((original_row.registration_pending || notEnoughStorage || isDeletionTarget) ? "neutral-400" : "neutral-600")}>
                            {original_row.storage_used}/{original_row.storage_total}
                        </div>
                    )
                }
            },
            {
                Header: '',
                accessor: 'is_account_manager',
                Cell: cell => {
                    const original_row = cell.row.original
                    const isDeletionTarget = original_row.id === deletingTeamMember.id
                    const notEnoughStorage = (original_row.storage_used + deletingTeamMember.storage_used) > original_row.storage_total
                    return <Form.Check
                        id='transfer-target-check'
                        checked={transferTo === original_row.email}
                        type='checkbox'
                        disabled={original_row.registration_pending || notEnoughStorage || isDeletionTarget}
                        className='form-check-container d-flex align-items-center figma-p1 neutral-600 ms-5'
                        onChange={(e) => { setTransferTo(original_row.email) }}
                        onClick={(e) => { e.target.blur() }}
                    />
                }
            },
        ]
    }, [enterpriseObj, transferTo, deletingTeamMember])

    return (
        <>
            <span className='figma-p1'>You can transfer these cases to another workspace’s member, but only if they have enough space in their Storage.</span>
            <TransferTeamMembersTable
                columns={columns}
                data={enterpriseMemberList}
                fetchData={fetchTeamMembers}
                loading={loadingMembers}
                pageCount={pageCount}
                controlledPageIndex={pageIndex}
                disableSortBy={true}
                pageSize={pageSize}
                table
            />
        </>
    )
}


function TransferExternallyBlock({transferTo, setTransferTo, enterpriseObj, transferExternallyEmailError, setTransferExternallyEmailError, transferExternallyNonFieldErrors, setTransferExternallyNonFieldErrors}) {

    return (
        <>
            <div className='figma-p1'>By transferring these cases to a new account, they will automatically receive a notification by e-mail to join the <span className='figma-p1-semibold'>{enterpriseObj.enterprise_name}</span> workspace.</div>
            <div className='figma-p2-semibold mt-4'>Transfer to a new account</div>
            <Form onSubmit={(e) => e.preventDefault()} className="transfer-externally-form mt-3">
                <div>
                    <Form.Group className="new-member-email-group">
                        <Form.Label>Enter the new account e-mail</Form.Label>
                        <Form.Control
                            type="email"
                            name="transfer_externally_email"
                            id="transfer_externally_email"
                            placeholder="Enter the e-mail"
                            value={transferTo}
                            onChange={(e) => { setTransferTo(e.target.value); setTransferExternallyEmailError(null); setTransferExternallyNonFieldErrors([]); }}
                            isInvalid={(transferExternallyEmailError) ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">{transferExternallyEmailError}</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                    {transferExternallyNonFieldErrors.map((error) =>
                        <p key={error}>{error}</p>
                    )}
                </div>
            </Form>
        </>
    )
}


function EditCustomerForm({editingTeamMemberTool, availableTools, setEditingTeamMemberTool, setEditingTeamMemberToolError, setEditingTeamMemberNonFieldErrors, setEditingTeamMemberBundle, editingTeamMemberToolError, editingTeamMemberBundleError, setEditingTeamMemberBundleError, editingTeamMemberBundle, availableAIBundlesOptions, editingTeamMemberNonFieldErrors}) {
    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()} className="edit-member-form custom-mt-20">
                <div className="d-flex align-items-start" >
                    <Form.Group className='custom-mt-6'>
                        <div className="d-flex align-items-center" >
                            <span className="figma-p1 neutral-600 text-nowrap custom-mt-6">Verima Tool</span>
                            <Form.Check
                                type="switch"
                                id="tool_enabled"
                                name="tool_enabled"
                                className="d-inline ms-2 fs-5"
                                checked={editingTeamMemberTool}
                                disabled={availableTools <= 0}
                                onChange={(e) => { setEditingTeamMemberTool(e.target.checked); setEditingTeamMemberToolError(null); setEditingTeamMemberNonFieldErrors([]); setEditingTeamMemberBundle('') }}
                                isInvalid={(editingTeamMemberToolError) ? true : false}
                            />
                        </div>
                        {editingTeamMemberToolError &&
                            <span className="error-600 figma-p1">{editingTeamMemberToolError}</span>
                        }
                    </Form.Group>
                    <div className="d-flex align-items-center">
                        <span className="figma-p1 neutral-600 ms-4 me-2 text-nowrap">AI module</span>
                        <Form.Select
                            size="sm"
                            className="ai-module-select"
                            placeholder='Ai module'
                            aria-invalid={(editingTeamMemberBundleError) ? true : false}
                            aria-errormessage='ai-module-error'
                            disabled={!editingTeamMemberTool}
                            onChange={e => {setEditingTeamMemberBundle(e.target.value); setEditingTeamMemberBundleError(null); setEditingTeamMemberNonFieldErrors([]);}}
                            value={editingTeamMemberBundle}
                        >
                            {availableAIBundlesOptions.map((option) => {
                                if (option.count) {
                                    return <option value={option.value}>{option.label} ({option.count})</option>
                                }
                                else {
                                    return <option value={option.value}>{option.label}</option>
                                }
                            })}
                        </Form.Select>
                        {editingTeamMemberBundleError &&
                            <span className="figma-caption error-500 mt-1" id='ai-module-error'>
                                {editingTeamMemberBundleError}
                            </span>
                        }
                    </div>
                </div>
                {(availableTools <= 0) && <div className='figma-p1 error-500 mt-2'>There are no Tools available, review your license plan.</div>}
                <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                    {editingTeamMemberNonFieldErrors.map((error) =>
                        <p key={error}>{error}</p>
                    )}
                </div>
            </Form>
        </>
    )
}


function FirstConfigurationForm({enterpriseObj, setEnterpriseObj}) {
    const [enterpriseName, setEnterpriseName] = useState('')
    const [enterpriseNameError, setEnterpriseNameError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        var formData = new FormData();
        formData.append("enterprise_name", enterpriseName);
        try {
            const response = await axios({
                method: 'patch',
                url: enterpriseObj.url,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            setEnterpriseObj(response.data)
        }
        catch (error) {
            if (error.response?.data) {
                setEnterpriseNameError(error.response.data.enterprise_name);
                setNonFieldErrors(error.response.data.non_field_errors || []);
            }
            setLoading(false)
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    const submitButtonEnabled = () => {
        const errors = enterpriseNameError || nonFieldErrors.length > 0
        return enterpriseName && !errors
    }

    return (
        <>
            <p className="figma-p3-semibold neutral-600 mb-2">Workspace information</p>
            <p className="figma-p1 neutral-600 mb-3">Name your workspace to configure the Enterprise License and add members to your team.</p>
            {loading
            ?
                <Loader />
            :
                <Form onSubmit={handleSubmit} className="col-12">
                    <div className="d-flex">
                        <Form.Group className="workspace-name-form mb-3">
                            <Form.Label>Workspace name</Form.Label>
                            <Form.Control
                                type="text"
                                name="enterprise_name"
                                id="enterprise_name"
                                placeholder="Insert workspace name"
                                value={enterpriseName}
                                onChange={(e) => { setEnterpriseName(e.target.value); setEnterpriseNameError(null) }}
                                isInvalid={(enterpriseNameError) ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">{enterpriseNameError}</Form.Control.Feedback>
                        </Form.Group>
                        <div className="custom-mt-30">
                            <Button variant="primary" size="md" type="submit" className="ms-3 figma-caption-semibold px-3" disabled={!submitButtonEnabled()}>
                                Continue
                            </Button>
                        </div>
                    </div>
                    <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                        {nonFieldErrors.map((error) =>
                            <p key={error}>{error}</p>
                        )}
                    </div>
                </Form>
            }
        </>
    )
}

function WorkspaceDetailsTabPanel({license, selectedTab, columns, enterpriseObj, enterpriseManager, enterpriseMemberList, fetchTeamMembers, loadingMembers, pageCount, pageIndex, pageSize, dataCount, fetchData, isEditingWorkspaceName, setIsEditingWorkspaceName, editingTeamMemberID, setEditingTeamMemberID, availableTools, availableAIBundles}) {
    const [modalType, setModalType] = useState(null)
    const [showWorkspaceModal, setShowWorkspaceModal] = useState(false)

    const availableAIBundlesOptions = availableAIBundles.reduce((current, bundle) => {
        const bundle_value = bundle.id
        const bundle_name = bundle.name
        const sameBundleIndex = current.findIndex(current_bundle => current_bundle.label === bundle_name)
        if (sameBundleIndex === -1) {
            current.push({value: bundle_value, label: bundle_name, count: 1})
        }
        else {
            current[sameBundleIndex].count += 1
        }
        return current
    }, [{value: '', label: 'No module', count: false}])

    return(
        <TabPanel value={selectedTab} index={0} className="neutral-600">
            <div className="figma-p2">
                <div className="figma-p3-semibold mt-5 mb-3">Workspace information</div>
                {isEditingWorkspaceName
                ?
                    <EditWorkspaceNameForm setIsEditingWorkspaceName={setIsEditingWorkspaceName} enterpriseObj={enterpriseObj} fetchData={fetchData} />
                :
                    <div className="d-flex align-items-center mb-3">
                        <div><span className="figma-p2-semibold">Name:</span> {enterpriseObj.enterprise_name}</div>
                        <Button variant="outline-primary" size="sm" className="ms-3" disabled={editingTeamMemberID} onClick={() => setIsEditingWorkspaceName(true)}>Edit name</Button>
                    </div>
                }
                <div className="d-flex align-items-center mb-3">
                    <div><span className="figma-p2-semibold">Created:</span> {enterpriseObj.first_set_name_date ? getDateString(new Date(enterpriseObj.first_set_name_date)) : ' -'}</div>
                    <div className="ms-5"><span className="figma-p2-semibold">Manager:</span> {enterpriseManager.first_name} {enterpriseManager.last_name}</div>
                    <div className="ms-5"><span className="figma-p2-semibold">Current members:</span> {dataCount}</div>
                </div>
            </div>
            <div className="horizontal-line my-4"></div>
            <div className="figma-p3-semibold my-4">General features included</div>
            <div className="big-card d-flex">
                <div className="col px-4 py-3 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-1">
                        <div className="figma-p1-semibold">Accounts included</div>
                        <FiUsers/>
                    </div>
                    <div className="figma-h2-semibold primary-600">{enterpriseObj.enterprise_accounts}</div>
                    <div className="mt-2 mb-auto"><Badge pill className='figma-p1 px-3 py-1'><span className="pe-2 border-right-line">Available</span><b className="ms-2 figma-p1-semibold">{enterpriseObj.enterprise_accounts - dataCount}</b></Badge></div>
                    <div className="figma-p1-semibold primary-600 cursor-pointer mt-4" onClick={() => {setShowWorkspaceModal(true); setModalType('accounts')}}>Add more to license</div>
                </div>
                <div className="col px-4 py-3 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-1">
                        <div className="figma-p1-semibold">Tools included</div>
                        <ToolIcon />
                    </div>
                    <div className="figma-h2-semibold primary-600">{enterpriseObj.enterprise_tools}</div>
                    <div className="mt-2 mb-auto"><Badge pill className='figma-p1 px-3 py-1'><span className="pe-2 border-right-line">Available</span><b className="ms-2 figma-p1-semibold">{availableTools}</b></Badge></div>
                    <div className="figma-p1-semibold primary-600 cursor-pointer mt-4" onClick={() => {setShowWorkspaceModal(true); setModalType('tools')}}>Add more to license</div>
                </div>
                <div className="col px-4 py-3 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-1">
                        <div className="figma-p1-semibold">Modules included</div>
                        <ToolAiModuleIcon />
                    </div>
                    <div className="figma-h2-semibold primary-600">{enterpriseObj.ai_bundles.length}</div>
                    <div className="mt-2 mb-auto"><Badge pill className='figma-p1 px-3 py-1'><span className="pe-2 border-right-line">Available</span><b className="ms-2 figma-p1-semibold">{availableAIBundles.length}</b></Badge></div>
                    <div className="figma-p1-semibold primary-600 cursor-pointer mt-4" onClick={() => {setShowWorkspaceModal(true); setModalType('modules')}}>Add more to license</div>
                </div>
                <div className="col px-4 py-3 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-1">
                        <div className="figma-p1-semibold">Custom Segmentations</div>
                        <CustomSegmentationsIcon/>
                    </div>
                    <div className="figma-h2-semibold primary-600 mb-auto">{enterpriseObj.enterprise_custom_segmentations}</div>
                    <div className="figma-p1-semibold primary-600 cursor-pointer mt-auto" onClick={() => {setShowWorkspaceModal(true); setModalType('custom_segmentations')}}>Add more to license</div>
                </div>
            </div>
            <div className="figma-p1-semibold mt-4 mb-3">Account features included</div>
            <div className="big-card d-flex py-3">
                <div className="col d-flex">
                    <FiArchive className="ms-4 small"/>
                    <div className="ms-2 figma-p1"><span className="figma-p1-semibold">Storage: </span>{license.license_thresholds.archive_size} cases per account</div>
                </div>
                <div className="col d-flex">
                    <FiShare2 className="ms-4 small"/>
                    <div className="ms-2 figma-p1"><span className="figma-p1-semibold">Sharing: </span>{license.license_thresholds.share} cases</div>
                </div>
                <div className="col d-flex">
                    <FiBox className="ms-4 small"/>
                    <div className="ms-2 figma-p1"><span className="figma-p1-semibold">STL cases: </span>{license.license_thresholds.create_from_stl} cases/month</div>
                </div>
                <div className="col d-flex">
                    <SharingRoomIcon className="ms-4 small"/>
                    <div className="ms-2 figma-p1"><span className="figma-p1-semibold">Sharing Room: </span>Enabled</div>
                </div>
            </div>
            <div className="horizontal-line my-4"></div>
            <div className="figma-p3-semibold mb-3">Manage team</div>
            <div className="figma-p1-semibold mb-1">Add members to your workspace</div>
            <div className="figma-p1 mb-4">With your license you can add <span className="figma-p1-semibold">{enterpriseObj.enterprise_accounts - dataCount} more accounts</span> to the workspace and assign <span className="figma-p1-semibold">{availableTools} Verima Tool</span>.</div>
            <AddMemberForm availableAIBundlesOptions={availableAIBundlesOptions} addMemberEnabled={!editingTeamMemberID && !isEditingWorkspaceName} fetchData={fetchData} availableTools={availableTools} enterprise_accounts_available={enterpriseObj.enterprise_accounts - dataCount} />
            <div className="horizontal-line mt-4"></div>
            <TeamMembersTable
                columns={columns}
                data={enterpriseMemberList}
                fetchData={fetchTeamMembers}
                loading={loadingMembers}
                pageCount={pageCount}
                controlledPageIndex={pageIndex}
                disableSortBy={true}
                pageSize={pageSize}
                table
            />
        {showWorkspaceModal && <WorkspaceModal modalType={modalType} setShowWorkspaceModal={setShowWorkspaceModal} />}
        </TabPanel>
    )
}

function EditWorkspaceNameForm({setIsEditingWorkspaceName, enterpriseObj, fetchData}) {
    const [enterpriseName, setEnterpriseName] = useState(enterpriseObj.enterprise_name)
    const [enterpriseNameError, setEnterpriseNameError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        var formData = new FormData();
        formData.append("enterprise_name", enterpriseName);
        try {
            await axios({
                method: 'patch',
                url: enterpriseObj.url,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            fetchData()
            setIsEditingWorkspaceName(false)
        }
        catch (error) {
            if (error.response?.data) {
                setEnterpriseNameError(error.response.data.enterprise_name);
                setNonFieldErrors(error.response.data.non_field_errors || []);
            }
            setLoading(false)
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    const submitButtonEnabled = () => {
        const changes = enterpriseName !== enterpriseObj.enterprise_name
        const errors = enterpriseNameError || nonFieldErrors.length > 0
        return changes && enterpriseName && !errors
    }

    return (
        <>
            {loading
            ?
                <div className="py-4" id="change-workspace-name-loader">
                    <Loader />
                </div>
            : 
                <Form onSubmit={handleSubmit} className="col-12">
                    <div className="d-flex">
                        <Form.Group className="workspace-name-form mb-3">
                            <Form.Label>Workspace name</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="enterprise_name"
                                id="enterprise_name"
                                placeholder="Insert workspace name"
                                value={enterpriseName}
                                onChange={(e) => { setEnterpriseName(e.target.value); setEnterpriseNameError(null) }}
                                isInvalid={(enterpriseNameError) ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">{enterpriseNameError}</Form.Control.Feedback>
                        </Form.Group>
                        <div className='custom-mt-30'>
                            <Button size="sm" variant="outline-primary" type="button" className="ms-3 figma-caption-semibold px-3" onClick={() => {setIsEditingWorkspaceName(false)}}>
                                Cancel
                            </Button>
                            <Button size="sm" variant="primary" type="submit" className="ms-3 figma-caption-semibold px-3" disabled={!submitButtonEnabled()}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                        {nonFieldErrors.map((error) =>
                            <p key={error}>{error}</p>
                        )}
                    </div>
                </Form>
            }
        </>
    )
}

function AddMemberForm({availableAIBundlesOptions, addMemberEnabled, fetchData, enterprise_accounts_available, availableTools}) {
    const [email, setEmail] = useState('')
    const [toolEnabled, setToolEnabled] = useState(false)
    const [AIModule, setAIModule] = useState('')
    const [emailError, setEmailError] = useState(null);
    const [toolError, setToolError] = useState(null);
    const [AIModuleError, setAIModuleError] = useState(null);
    const [nonFieldErrors, setNonFieldErrors] = useState([]);
    const [verErrors, setVerErrors] = useState([]);
    const [loading, setLoading] = useState(false)

    const submitButtonEnabled = () => {
        const emptyEmail = !email
        const errors = emailError || toolError || AIModuleError || nonFieldErrors.length > 0
        return !emptyEmail && !errors
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        var formData = new FormData();
        formData.append("email", email);
        formData.append("tool", toolEnabled);
        formData.append("ai_modules_bundle", AIModule);
        try {
            const response = await axios({
                method: 'post',
                url: ENTERPRISE_ADD_MEMBER_TO_TEAM,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (config.SHOULD_LOG) { console.log('response >>> ', response); }
            addToast('success', 'The new member was successfully added to the workspace.', true);
            await fetchData()
        }
        catch (error) {
            if (error.response?.data) {
                setEmailError(error.response.data.email);
                setToolError(error.response.data.tool?.ver_error ? error.response.data.tool.ver_error[0].msg : error.response.data.tool);
                setAIModuleError(error.response.data.ai_modules_bundle);
                setNonFieldErrors(error.response.data.non_field_errors || []);
                setVerErrors(error.response.data.ver_error || []);
            }
            setLoading(false)
            if (config.SHOULD_LOG) { console.log('error >>> ', JSON.stringify(error)); }
        }
    }

    return (
        <Form onSubmit={handleSubmit} className="add-member-form">
            {
                loading
                ?
                    <div className="py-4 custom-w-100">
                        <Loader />
                    </div>
                :
                <>
                    <div className="d-flex align-items-start">
                        <Form.Group className="me-4 new-member-email-group">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                type="email"
                                size="sm"
                                name="new_member_email"
                                id="new_member_email"
                                placeholder="Enter an e-mail"
                                value={email}
                                disabled={enterprise_accounts_available <= 0 || !addMemberEnabled}
                                onChange={(e) => { setEmail(e.target.value); setEmailError(null); setNonFieldErrors([]); }}
                                isInvalid={(emailError) ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="custom-mt-33">
                            <div className="d-flex align-items-center" >
                                <span className="figma-p1 neutral-600">Verima Tool</span>
                                <Form.Check
                                    type="switch"
                                    id="tool_enabled"
                                    name="tool_enabled"
                                    className="d-inline ms-2 fs-5"
                                    checked={toolEnabled}
                                    disabled={availableTools <= 0 || !addMemberEnabled}
                                    onChange={(e) => { setToolEnabled(e.target.checked); setToolError(null); setNonFieldErrors([]); setAIModule('') }}
                                    isInvalid={(toolError) ? true : false}
                                />
                            </div>
                            {toolError &&
                                <span className="error-600 figma-p1">{toolError}</span>
                            }
                        </Form.Group>
                        <div className="d-flex align-items-center custom-mt-29">
                            <span className="figma-p1 neutral-600 ms-4 me-2 text-nowrap">AI module</span>
                            <Form.Select
                                size="sm"
                                className="ai-module-select"
                                placeholder='Ai module'
                                aria-invalid={(AIModuleError) ? true : false}
                                aria-errormessage='ai-module-error'
                                disabled={!toolEnabled || !addMemberEnabled}
                                onChange={(e) => {setAIModule(e.target.value); setAIModuleError(null); setNonFieldErrors([]);}}
                                value={AIModule}
                            >
                                {availableAIBundlesOptions.map((option) => {
                                    if (option.count) {
                                        return <option value={option.value}>{option.label} ({option.count})</option>
                                    }
                                    else {
                                        return <option value={option.value}>{option.label}</option>
                                    }
                                })}
                            </Form.Select>
                            {AIModuleError &&
                                <span className="figma-caption error-500 mt-1" id='ai-module-error'>
                                    {AIModuleError}
                                </span>
                            }
                        </div>
                        <Button variant="primary" size="sm" type="submit" className="figma-caption-semibold px-3 ms-auto custom-mt-30" disabled={!submitButtonEnabled() || !addMemberEnabled}>
                            Add member
                        </Button>
                    </div>
                    <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                        {nonFieldErrors.map((error) =>
                            <p key={error}>{error}</p>
                        )}
                    </div>
                    <div className="nonfield-errors mt-1 w-100 figma-p1 error-500">
                        {verErrors.map((error) =>
                            <p key={error.code}>{error.msg}</p>
                        )}
                    </div>
                </>
            }
        </Form>
    )
}

function Paginator({pageCount, pageIndex, setPageIndex, dataCount, pageLength, pageSize}) {
    const canPreviousPage = (pageCount > 0) && (pageIndex > 0)
    const canNextPage = (pageCount > 0) && (pageIndex < (pageCount - 1))

    const gotoPage = (targetIndex) => {
        if ((targetIndex >= 0) && (targetIndex < pageCount)) { setPageIndex(targetIndex) }
    }
    const nextPage = () => {
        if (canNextPage) { setPageIndex(pageIndex + 1) }
    }
    const previousPage = () => {
        if (canPreviousPage) { setPageIndex(pageIndex - 1) }
    }

    return (
        <>
        {pageLength > 0 &&
            <div className="paginator d-flex justify-content-between align-items-center mt-auto paginator">
                <p className="figma-p1 neutral-900 mb-0">Showing {pageLength === 0 ? 0 : ((pageIndex * pageSize) + 1)}-{(pageIndex * pageSize) + pageLength} of {dataCount} results</p>
                <Pagination className="mb-0">
                    <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                    {(pageIndex > 2) &&
                        <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
                    }
                    {(pageIndex > 3) &&
                        <Pagination.Ellipsis disabled />
                    }
                    {(pageIndex > 1) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item>
                    }
                    {(pageIndex > 0) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item>
                    }
                    <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
                    {(pageIndex < pageCount - 1) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item>
                    }
                    {(pageIndex < pageCount - 2) &&
                        <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item>
                    }
                    {(pageIndex < pageCount - 4) &&
                        <Pagination.Ellipsis disabled />
                    }
                    {(pageIndex < pageCount - 3) &&
                        <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageCount}</Pagination.Item>
                    }
                    <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                </Pagination>
            </div>
        }
        </>
    )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

function WorkspaceModal({ modalType, setShowWorkspaceModal }) {
    let title;
    let size;
    switch (modalType) {
        case 'accounts':
            title = <>Add more accounts to license</>
            size = 'sm'
            break;
        case 'tools':
            title = <>Add more Tools to license</>
            size = 'sm'
            break;
        case 'modules':
            title = <>Add more modules to license</>
            size = 'sm'
            break;
        case 'custom_segmentations':
            title = <>Add more Custom Segmentations<br />to license</>
            size = 'sm'
            break;
        default:
            //pass
    }
    return (
        <>
            <Modal centered size={size} show={true} onHide={() => {setShowWorkspaceModal(false)}}>
                <Modal.Body id="delete-case-modal">
                    <Row className="text-center">
                        <Col md={12} className="d-flex justify-content-center">
                            <div className="trash-circle mb-4 bg-secondary-300 d-flex align-items-center">
                                <div className="d-block m-auto p-0"><FiBriefcase className="primary-600" size="20px"/></div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <p className="figma-p1-semibold neutral-900">{title}</p>
                        </Col>
                        <Col md={12}>
                            <p className="figma-p1 neutral-500">To increase the feature values of your license contact our <span className='figma-p1-semibold'>business support</span>.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between bg-neutral-50">
                    <Button variant="outline-primary" className="px-4" onClick={() => {setShowWorkspaceModal(false)}}>Cancel</Button>
                    <a href={WEBSITE_CONTACT_US_URL} target='_blank' rel='noreferrer' className="btn btn-primary px-4">Contact</a>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function WorkspaceSegmentationHistoryTabPanel({selectedTab, customSegmentationsList, setCustomSegmentationsList, pageCount, setPageCount, pageIndex, setDataCount, enterpriseObj}) {
    const [loadingCustomSegmentations, setLoadingCustomSegmentations] = useState(false)
    const [sortBy, setSortBy] = useState([{ desc: true, id: 'request_date' }])
    const available_custom_segmentations = enterpriseObj.enterprise_custom_segmentations

    const fetchCustomSegmentations = useCallback(async ({ pageSize, pageIndex }) => {
        setLoadingCustomSegmentations(true)
        setCustomSegmentationsList([])
        let targetUrl = ENTERPRISE_CUSTOM_SEGMENTATIONS_LIST_URL
        if (sortBy.length > 0) {
            const strSortBy = (sortBy[0].desc ? '-' : '') + sortBy[0].id
            targetUrl += `?ordering=${strSortBy}`
        }
        const requestConfig = {
            method: 'get',
            url: targetUrl,
            params: {
                page: pageIndex + 1
            }
        }
        try {
            const response = await axios(requestConfig)
            const responseData = response.data.results.map((obj) => {
                return {
                    ...obj,
                    case_id: obj.case_id,
                    request_date: getDateString(new Date(obj.request_date)),
                    category: obj.manual_case_category_label,
                }
            })
            setCustomSegmentationsList(responseData)
            setPageCount(Math.ceil(response.data.count / pageSize))
            setDataCount(response.data.count)
        }
        catch (error) {
            if (config.SHOULD_LOG) { console.error('response error >>> ', error); }
            setCustomSegmentationsList([])
        }
        setLoadingCustomSegmentations(false)
    }, [sortBy])

    
    const columns = useMemo(() => {
        let tmp_columns = [
            {
                Header: 'REQUESTED BY',
                accessor: 'requested_by',
                Cell: ({ cell }) => {
                    const original_row = cell.row.original
                    return (
                        <div className='neutral-600 py-2'>
                            <div className='figma-p2-semibold'>{original_row.full_name}</div>
                            <div className='figma-p2 neutral-400'>{original_row.email}</div>
                        </div>
                    )
                },
                disableSortBy: true,
            },
            {
                Header: 'CASE ID',
                accessor: 'case_id',
                Cell: cell => <span className="figma-p1-semibold">{cell.row.original.case_id} </span>,
                disableSortBy: true,
            },
            {
                Header: <><FiCalendar size='14px' className='me-2' />DATE</>,
                accessor: 'request_date',
            },
            {
                Header: <><FiPlusCircle size='14px' className='me-2' />CLINICAL AREA</>,
                accessor: 'category',
                disableSortBy: true,
            },
        ]
        return tmp_columns
    }, [])


    return(
        <TabPanel value={selectedTab} index={1}>
            <div className='d-flex justify-content-between align-items-center my-5 neutral-600'>
                <div className='me-4'>
                    <div className='figma-p3-semibold mb-2'>Team Segmentation History</div>
                    <div className='figma-p1'>Summary of segmentations used by workspace members.</div>
                </div>
                {available_custom_segmentations > 0
                    ?
                    <div>
                        <div className='mb-1 d-flex align-items-center'>
                            <CustomSegmentationsIcon size="24px" className="me-2" />
                            <div className='figma-p2-semibold me-3'>Available Custom Segmentations</div>
                            <div className='figma-h5-bold primary-600'>{available_custom_segmentations}</div>
                        </div>
                        <div className='figma-p1'>Custom Segmentations can be used by all team members.</div>
                    </div>
                    :
                    <div className='gray-card px-3 py-2'>
                        <div className='mb-1 d-flex align-items-center'>
                            <CustomSegmentationsIcon size="24px" className="me-2" />
                            <div className='figma-p2-semibold me-3'>No Custom Segmentations available</div>
                        </div>
                        <div className='figma-p1 mb-1'>Custom Segmentations can be used by all team members.</div>
                        <div className='figma-caption error-500'>To add additional Custom Segmentations to your license, please contact our <span className='figma-caption-semibold'>business support</span>.</div>
                    </div>
                }
            </div>
            <CustomSegmentationsTable
                columns={columns}
                data={customSegmentationsList}
                fetchData={fetchCustomSegmentations}
                loading={loadingCustomSegmentations}
                pageCount={pageCount}
                controlledPageIndex={pageIndex}
                manualSortBy={true}
                initialSortBy={sortBy}
                onChangeSort={setSortBy}
                disableSortRemove={true}
            />
        </TabPanel>
    )
}